import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import { HomeOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { SecurityContext } from "context/SecurityContextProvider";
import LegajoService from "services/LegajoService";


const BreadCrumbDocumentos = ({props, onChange}) => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);


  const onClickInicio = async () => {
    const _documentos = await LegajoService.listarDocumentosColaborador(
      props.numeroDocumento,
      getUsuarioAutenticado().empresaSeleccionada.id,
      getUsuarioAutenticado().login
    );
    onChange( { valor: { documentos: _documentos, breadcrumbItems:[] } } )

  };

  const onClickItem = async (item) => {

    onChange({ valor: {loading: true}})
      const documentosResponse = await LegajoService.listarDocumentosCarpeta(
        props.numeroDocumento,
        getUsuarioAutenticado().empresaSeleccionada.id,
        item.carpetaId,
        getUsuarioAutenticado().login
      );
      const mensaje = documentosResponse.find((d) => d.tipo === "MENSAJE");
      const _documentos = documentosResponse.filter((d) => d.tipo !== "MENSAJE");
      const _bread = props.breadcrumbItems;

      onChange({ valor: {documentos: _documentos, 
        breadcrumbItems: _bread.slice(0, _bread.findIndex(i => i.carpetaId === item.carpetaId) + 1),
        loading: false, mensajeLegajo: mensaje ? mensaje.mensaje : undefined
     }})

  };
  
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <a onClick={onClickInicio}>
          <HomeOutlined style={{ fontSize: "20px" }} />
          <span> Inicio</span>
        </a>
      </Breadcrumb.Item>
      {props.breadcrumbItems &&
        props.breadcrumbItems.map((item) => (
          <Breadcrumb.Item key={item.carpetaId}>
            <a onClick={() => onClickItem(item)}>
              <FolderOpenOutlined style={{ fontSize: "20px" }} />
              <span> {item.nombre}</span>
            </a>
          </Breadcrumb.Item>
        ))}
    </Breadcrumb>
  );
};
export default BreadCrumbDocumentos;
