import { atom } from "jotai";

export const visibleModalRestablecerClaveAtom = atom(false);
export const visibleModalHistorialCodigosAtom = atom(false);
export const historialCodigosAtom = atom([]);
export const visibleModalReingresoColaboradorAtom = atom(false);
export const visibleModalExportarFormularioAtom = atom(false);

export const eventoDescargaAtom = atom({ estado: undefined, mensaje: '' });

export const visibleModalConfigurarOpcionPortalColaboradorAtom = atom(false);
export const visibleModalFiltrarAtom = atom(false);
export const empresaSeleccionadaAtom = atom(undefined);
export const categoriasAtom = atom([]);
export const opcionesAtom = atom([]);
export const opcionesSeleccionadasAtom = atom([]);
