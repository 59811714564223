import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Input, Table, message, notification, Spin, Space, Select } from "antd";
import { visibleModalDocumentoAtom } from "../EditarPlantillaEnvioDocumentosStore";
import { useAtom } from "jotai";
import { PlantillaEnvioDocumentoService } from "../../../../../services/PlantillaEnvioDocumentoService";
import { plantillaEnvioDocumentosAtom } from "../EditarPlantillaEnvioDocumentosStore";
import { documentosSeleccionadosAtom } from "../EditarPlantillaEnvioDocumentosStore";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FolderTwoTone,
  FolderFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useModalVisorPdf } from "store/global";
import { SecurityContext } from "context/SecurityContextProvider";
import UsuarioService from "services/UsuarioService";
import CarpetaService from "services/CarpetaService";

const PlantillaEnvioDocumentoEditar = () => {
  let { idPlantilla } = useParams();
  const [, setVisibleModalDocumento] = useAtom(visibleModalDocumentoAtom);
  const [formPlantillaEnvioDocumento] = Form.useForm();
  const [plantillaEnvioDocumentos, setPlantillaEnvioDocumentos] = useAtom(plantillaEnvioDocumentosAtom);
  const [documentosSeleccionados, setDocumentosSeleccionados] = useAtom(documentosSeleccionadosAtom);
  const [estado, setEstado] = useState("SIN_ESTADO");
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
  const [carpetas, setCarpetas] = useState([]);
  const [listaCarpetasFiltro, setListaCarpetasFiltro] = useState([]);
  const { Option } = Select;

  useEffect(() => {
    const cargarDatos = async () => {
      const usuario = await UsuarioService.buscarPorOrganizacionLogin(
        getUsuarioAutenticado().organizacionId,
        getUsuarioAutenticado().login
      );

      const empresaSeleccionada = usuario?.empresas.find(
        (empresa) => empresa.id === getUsuarioAutenticado().empresaSeleccionada.id
      );

      const _carpetas = await getListaCarpetasFiltro(empresaSeleccionada);
      setListaCarpetasFiltro(_carpetas);

      const plantilla = await PlantillaEnvioDocumentoService.buscarPorId(idPlantilla);
      setPlantillaEnvioDocumentos(plantilla);
      setDocumentosSeleccionados(plantilla.documentos);
      formPlantillaEnvioDocumento.setFieldsValue({
        id: plantilla.id,
        nombre: plantilla.nombre,
        carpeta:
          plantilla.carpeta !== null ? { id: plantilla.carpeta.id, descripcion: plantilla.carpeta.descripcion } : null,
      });
    };
    cargarDatos();
  }, []);

  const columnas = [
    {
      title: "Documentos",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (text, fila) => (
        <div>
          <Button type={"link"} onClick={() => onClickQuitar(fila)}>
            Quitar
          </Button>
        </div>
      ), // <Button type={"link"} onClick={()=>onClickVer(fila)}>ver</Button></div>)
    },
  ];

  const onClikSeleccionarDocumento = () => {
    setVisibleModalDocumento(true);
  };
  const onClickQuitar = (documento) => {
    setDocumentosSeleccionados(documentosSeleccionados.filter((item) => item.id !== documento.id));
  };

  const onClickVer = (documento) => {
    try {
      //setLoading(true);

      // const respuesta = await DetalleEnvioService.obtenerUrlDocumento(
      //   getUsuarioAutenticado().empresaSeleccionada.id,
      //   documento.detalleEnvioId
      // );
      mostrarModalVisorPdf(null, documento.nombre, true, true);
    } catch (error) {
      console.error(error);
      //setLoading(false);
    } finally {
      //setLoading(false);
    }
  };

  const onChangeNombre = async (e) => {
    // console.log(e.target.value)
    if (e.target.value != null) {
      setEstado("CARGANDO");
      if (plantillaEnvioDocumentos.nombre === e.target.value) setEstado(true);
      else {
        const valido = await PlantillaEnvioDocumentoService.buscarPorNombre(
          getUsuarioAutenticado().empresaSeleccionada.id,
          e.target.value
        );
        setEstado(valido);
      }
    } else setEstado(false);
  };

  const getListaCarpetasFiltro = async (empresaSeleccionada) => {
    const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
    setCarpetas(_carpetas);
    if (!empresaSeleccionada?.filtroCarpeta?.length > 0) {
      return _carpetas;
    }

    var temp = [];
    const _temporal = empresaSeleccionada?.filtroCarpeta?.map((e) => {
      temp.push(_carpetas.find((c) => c.id == e.id));
    });

    return temp;
  };

  const onFinishEditarPlantilla = async (plantilla) => {
    try {
      if (documentosSeleccionados.length > 0) {
        const documentosEnviados = [];

        for (let i = 0; i < documentosSeleccionados.length; i++) {
          const documento = {
            id: documentosSeleccionados[i].id,
            nombre: documentosSeleccionados[i].nombre,
            categoriaDocumento: documentosSeleccionados[i].categoriaDocumento,
          };
          documentosEnviados.push(documento);
        }
        plantilla.documentos = documentosEnviados;
        await PlantillaEnvioDocumentoService.actualizar(plantilla);
        notification.success({
          message: "La Plantilla fue actualizada correctamente",
        });
        history.push("/configuracion/plantilla-envio-documentos/listar");
      } else {
        message.error("Debe seleccionar los documentos");
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.responde.data.mensaje,
      });
    }
  };

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} />} spinning={loading} tip="Cargando...">
      <Form
        labelCol={{ span: 10, offset: 8 }}
        wrapperCol={{ span: 10, offset: 8 }}
        layout="vertical"
        onFinish={onFinishEditarPlantilla}
        form={formPlantillaEnvioDocumento}
        //onLoad=
      >
        <Form.Item label="id" name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Nombre"
          name="nombre"
          rules={[
            {
              required: true,
              message: "Debe ingresar el nombre.",
            },
          ]}
        >
          <Input
            onBlur={(e) => onChangeNombre(e)}
            suffix={
              estado === "CARGANDO" ? (
                <LoadingOutlined />
              ) : estado === true ? (
                <CheckCircleOutlined style={{ color: "green" }} />
              ) : estado === false ? (
                <CloseCircleOutlined style={{ color: "red" }} />
              ) : (
                ""
              )
            }
          />
        </Form.Item>

        <Form.Item label="Carpeta" name={["carpeta", "id"]}>
          <Select
            onChange={(_, value2) =>
              formPlantillaEnvioDocumento.setFieldsValue({ carpeta: { descripcion: value2.descripcion } })
            }
            optionLabelProp="label"
            placeholder="Seleccionar la carpeta"
            style={{ width: "100%" }}
            allowClear
          >
            {carpetas.length > 0 &&
              carpetas.map((carpeta) => {
                return (
                  <Option
                    key={carpeta.id}
                    disabled={listaCarpetasFiltro.filter((item) => item.id == carpeta.id).length == 0}
                    value={carpeta.id}
                    label={
                      <div>
                        <FolderTwoTone style={{ fontSize: "20px", color: "#F7D358" }} twoToneColor="#F7D358" />{" "}
                        {carpeta.ruta}
                      </div>
                    }
                    descripcion={carpeta.descripcion}
                  >
                    <Space size={carpeta.nivel * 10}>
                      <span></span>
                      <span>
                        {carpeta.nivel === 0 ? (
                          <FolderFilled style={{ fontSize: "20px", color: "#F7D358" }} />
                        ) : (
                          <FolderTwoTone style={{ fontSize: "18px", color: "#F7D358" }} twoToneColor="#F7D358" />
                        )}{" "}
                        {carpeta.descripcion}
                      </span>
                    </Space>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name={["carpeta", "descripcion"]} noStyle>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item>
          <Button onClick={onClikSeleccionarDocumento}>Seleccionar Documentos</Button>
        </Form.Item>
        <Form.Item>
          <Table
            rowKey={(documento) => documento.id}
            columns={columnas}
            dataSource={documentosSeleccionados}
            scroll={{ y: 240 }}
            pagination={false}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" block htmlType="submit" disabled={!estado}>
            Actualizar Plantilla
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
export default PlantillaEnvioDocumentoEditar;
