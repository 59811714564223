import React, { useContext, useEffect } from "react";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import { OpcionService } from "services";
import { SecurityContext } from "context/SecurityContextProvider";
import { BreadCrumbDocumentos, TablaDocumentos } from "./components";
import { useState } from "react";

const TablaLegajoColaborador = ({numeroDocumento, listaDocumentos}) => {
  // const [loading, setLoading] = useState(false);
  //const [loadingColaboradores] = useAtom(atoms.loadingColaboradores);
  // const [loadingDocumentos] = useAtom(atoms.loadingDocumentos);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [props, setProps] = useState({
    numeroDocumento:numeroDocumento,
    documentos: listaDocumentos,
    breadcrumbItems: [],
    loading: false,
    mensaje: undefined,
    codigosOpciones:undefined
  })

  useEffect(() => {
    const cargarOpciones = async () => {
      const codigosOpciones = await OpcionService.listar(getUsuarioAutenticado().id);
      setProps((prevVal) =>{ return {...prevVal, codigosOpciones: codigosOpciones}})
    };
    cargarOpciones();
  }, []);

function onChangeMultiFunctional({ valor }) {
    setProps((prevVal) =>{ return {...prevVal, ...valor}})
    
}

  return (
        <React.Fragment>
            <BreadCrumbDocumentos props={props} onChange={onChangeMultiFunctional} />
            <TablaDocumentos props={props} onChange={onChangeMultiFunctional} />
            <ModalVisorPdf />
        </React.Fragment>
  );
};
export default TablaLegajoColaborador;
