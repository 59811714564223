import React from "react";
import { Card, Avatar, Divider, Image,Upload,Button,message } from "antd";
import { UserOutlined,ArrowLeftOutlined } from "@ant-design/icons";
import { useBuscarColaborador } from "../../hooks";
import useCargarColaborador from "../../hooks/useCargarColaborador";
import { useHistory, } from "react-router-dom";
import { LoginService } from "services/LoginService";
import { Headers } from "enums";
import ImgCrop from "antd-img-crop";

const CardInformacionPersonalColaborador = () => {
  const { colaborador } = useBuscarColaborador();
  const {cargarColaborador} = useCargarColaborador();
  let history = useHistory();

  const propsFoto = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "colaborador/actualizar-url-foto-personal",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,			
		},
    data: {
      colaborador: new Blob(
        [
          JSON.stringify({
            numeroDocumentoIdentidad: colaborador?.numeroDocumentoIdentidad,
          }),
        ],
        { type: "application/json" }
      ),
    },
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				cargarColaborador();
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};
  
  return (
    <div className="container-colaboradores">
      <Card actions={[
          <ArrowLeftOutlined style={{fontSize:"30px"}} key="back" onClick={()=>history.push("/personal/trabajador")} />,
        ]}>
        <div style={{ textAlign: "center" }}>{colaborador?.urlFotoPersonalFirmado!=null?
          <Image src={colaborador?.urlFotoPersonalFirmado} width={100} style={{borderRadius: "50%"}}/> : <Avatar size={100} icon={<UserOutlined />} />}
        </div>
        <div style={{ textAlign: "center" }}>		
          <ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
                <Upload {...propsFoto} maxCount={1} accept={["image/*"]}>
                  <Button block  type="link">
                    Cambiar Foto
                  </Button>
                </Upload>
          </ImgCrop>									
				</div>
        <div style={{ textAlign: "center", color: "#0a63a4" }}>
          {colaborador?.apellidoPaterno} {colaborador?.apellidoMaterno} {colaborador?.nombres}
        </div>
        <div style={{ textAlign: "center", color: "#999999" }}>{colaborador?.cargo?.nombre}</div>
        <Divider />
        <div>
          <p style={{ color: "#999999" }}> Estado </p>
          <p style={{ color: "#0a63a4" }}> {colaborador?.estadoRegistro} </p>
        </div>
        <div>
          <p style={{ color: "#999999" }}> Documento Identidad </p>
          <p style={{ color: "#0a63a4" }}> {colaborador?.numeroDocumentoIdentidad} </p>
        </div>
        <div>
          <p style={{ color: "#999999" }}> Correo Electrónico </p>
          <p style={{ color: "#0a63a4" }}> {colaborador?.correoElectronico} </p>
        </div>

        <div>
          <p style={{ color: "#999999" }}> Celular </p>
          <p style={{ color: "#0a63a4" }}> {colaborador?.celular} </p>
        </div>
      </Card>
    </div>
  );
};
export default CardInformacionPersonalColaborador;
