import React from "react";
import { Tag } from "antd";
import { TagNotificado } from "./TagNotificado";
import { EstadoNotificacion } from "enums/EstadoNotificacion";

const EtiquetaEstadoNotificacion = ({ documento }) => {

    switch (documento.estadoNotificacion) {
        case EstadoNotificacion.PENDIENTE:
            return <Tag color="gold">{documento.estadoNotificacion}</Tag>;

        case EstadoNotificacion.ENVIADO:
            return (
                <TagNotificado documento={documento} color="green" className="milegajo-ant-tag pointer">
                    {documento.estadoNotificacion}
                </TagNotificado>
            )

        case EstadoNotificacion.ERROR:
            return <Tag color="red">{documento.estadoNotificacion}</Tag>;

        case EstadoNotificacion.NO_APLICA:
            return <Tag color="default">No Aplica</Tag>;

        default:
            return <Tag color="default">-</Tag>;
    }


}
export default EtiquetaEstadoNotificacion;