import { useAtom } from "jotai";
import { colaboradorAtom, envioSeleccionadoAtom, onboardingsIngresoAtom } from "../../../LegajoColaboradorStore";
import { useContext, useEffect } from "react";
import { OnboardingIngresoColaboradorService } from "services/OnboardingIngresoColaboradorService";
import { SecurityContext } from "context/SecurityContextProvider";

const useListarOnboardingIngreso = () => {
	const [colaborador] = useAtom(colaboradorAtom);
	const [onboardings, setOnboardings] = useAtom(onboardingsIngresoAtom);
	const [envioSeleccionado] = useAtom(envioSeleccionadoAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);

	useEffect(() => {
		const cargarDatos = async () => {
			if (colaborador.onboarding) {
				const respuesta = await OnboardingIngresoColaboradorService.listarDetalleOnboardingIngreso(
					getUsuarioAutenticado().login,
					colaborador.onboarding.onboardingId,
					colaborador.numeroDocumentoIdentidad
				);

				setOnboardings(respuesta);
			}
		};
		cargarDatos();
	}, []);

	return { onboardings };
};

export default useListarOnboardingIngreso;
