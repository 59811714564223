import { BaseService } from "./BaseService";

const DescargaMasivaService = {
  async insertar(descarga) {
    const respuesta = await BaseService.post("/exportadores/descarga-masiva", descarga);
    return respuesta.data;
  },

  async listar() {
    const respuesta = await BaseService.get("/exportadores/descarga-masiva");
    return respuesta.data;
  },

  async getUrlDescarga(descargaMasivaId) {
    const respuesta = await BaseService.get(`/exportadores/descarga-masiva/${descargaMasivaId}/url-descarga`);
    return respuesta.data;
  },
};

export default DescargaMasivaService;
