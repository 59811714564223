import React, { useContext, useEffect } from "react";
import { Dropdown, Menu, Button, Modal, notification } from "antd";

import {
  SearchOutlined,
  MailOutlined,
  StopOutlined,
  EditOutlined,
  DownOutlined,
  AuditOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
  UnorderedListOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router";
import { useAtom } from "jotai";
import {
  loadingAtom,
  visibleModalHistorialNotifiacaionAtom,
  detalleEnvioSeleccionadoAtom,
  detalleEnvioSeguimientoAtom,
  visibleModalActualizarCorreoAtom,
  codigosOpcionesAtom,
} from "../../ListarDetalleSeguimientoStore";
import DetalleEnvioService from "services/DetalleEnvioService";
import { ContratoService } from "services/ContratoService";
import { SeguimientoService } from "services/SeguimientoService";
import { SecurityContext } from "context/SecurityContextProvider";
import { useModalVisorPdf } from "store/global";
import { categoriaSeguimientoAtom } from "store/local/seguimiento/SeguimientoStore";
import { OPCIONES } from "enums";
import FormularioSeguimientoService from "services/FormularioSeguimientoService";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import { EstadoRegistro } from "enums/EstadoRegistro";

const MenuAcciones = ({ envio }) => {
  const [codigosOpciones] = useAtom(codigosOpcionesAtom);
  const [detalleEnvio, setDetalleEnvio] = useAtom(detalleEnvioSeguimientoAtom);
  const [, setDetalleEnvioSeleccionado] = useAtom(detalleEnvioSeleccionadoAtom);
  const [categoriaDocumento] = useAtom(categoriaSeguimientoAtom);
  const [, setLoading] = useAtom(loadingAtom);
  const [, setVisibleModalActualizarCorreo] = useAtom(visibleModalActualizarCorreoAtom);
  const [, setVisibleModalHistorialNotificacion] = useAtom(visibleModalHistorialNotifiacaionAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
  let history = useHistory();
  const onClickMenu = (opcion) => {
    switch (opcion.key) {
      case "ver-documento":
        onClickBtnVerDocumento(envio);
        break;

      case "reenviar-documento":
        onClickBtnReenviarNotificacion();
        break;

      case "inhabilitar":
        onClickBtnInhabilitarContrato();
        break;

      case "habilitar":
        onClickBtnHabilitarContrato();
        break;

      case "actualizar-correo":
        setVisibleModalActualizarCorreo(true);
        setDetalleEnvioSeleccionado(envio);
        break;
      case "historial-notificacion":
        setVisibleModalHistorialNotificacion(true);
        setDetalleEnvioSeleccionado(envio);
        break;
      default:
        console.log("informacion de envio: ", envio);
        console.error("No se encuentra esa opción");
    }
  };

  const onClickBtnVerDocumento = async () => {
    try {
      setLoading(true);
      let respuesta = null;
      if (envio.categoriaDocumentoId === CategoriaDocumento.FORMULARIOS.id) {
        respuesta = await FormularioSeguimientoService.getUrlDocumento(
          getUsuarioAutenticado().empresaSeleccionada.id,
          envio.id
        );
      } else {
        respuesta = await ContratoService.getUrlDocumento(getUsuarioAutenticado().empresaSeleccionada.id, envio.id);
      }

      mostrarModalVisorPdf(respuesta.urlDocumento, envio.envio.descripcion, true, true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onClickBtnReenviarNotificacion = async () => {
    Modal.confirm({
      bodyStyle: { display: "flex", flexDirection: "column" },
      icon: <QuestionCircleOutlined style={{ fontSize: "32px" }} />,
      content: (
        <span style={{ textAlign: "center", display: "block", fontSize: "16px" }}>
          ¿Está seguro de <b>reenvíar la notificación</b> del documento seleccionado?
        </span>
      ),
      autoFocusButton: "cancel",
      centered: true,
      maskClosable: true,
      cancelText: "No, cerrar ventana",
      okText: "Si, envíar notificación",
      onOk: async () => {
        try {
          setLoading(true);

          if (categoriaDocumento == "Documento_Laboral") {
            await DetalleEnvioService.reenviarNotificacionPorId(
              envio.id,
              getUsuarioAutenticado().organizacionId,
              getUsuarioAutenticado().empresaSeleccionada.id
            );
          } else {
            await ContratoService.reenviarNotificacionMasivamente(
              getUsuarioAutenticado().organizacionId,
              getUsuarioAutenticado().empresaSeleccionada.id,
              envio.envio.id,
              [envio.id]
            );
          }

          //setVisibleMenu(false);
          notification.success({
            message: `Notificación`,
            description: "Se reenvió la notificación exitosamente.",
            placement: "topRight",
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
    });
    //setVisibleMenu(false);
  };

  const onClickBtnInhabilitarContrato = () => {
    Modal.confirm({
      bodyStyle: { display: "flex", flexDirection: "column" },
      icon: <QuestionCircleOutlined style={{ fontSize: "32px" }} />,
      content: (
        <span style={{ textAlign: "center", display: "block", fontSize: "16px" }}>
          ¿Está seguro de <b>inhabilitar</b> el documento seleccionado?
        </span>
      ),
      autoFocusButton: "cancel",
      centered: true,
      maskClosable: true,
      cancelText: "No, cerrar ventana",
      okText: "Si, inhabilitar documento",
      onOk: async () => {
        try {
          const documentoActualizado = await SeguimientoService.inhabilitar(
            getUsuarioAutenticado().empresaSeleccionada.id,
            envio.id
          );
          console.log("resultado nuevo detalleEnvio: ", documentoActualizado);

          setDetalleEnvio(
            detalleEnvio.map((envio) => {
              if (envio.id === documentoActualizado.id) {
                return documentoActualizado;
              }
              return envio;
            })
          );

          notification.success({
            message: `Notificación`,
            description: "Se inhabilitó el documento exitosamente.",
            placement: "topRight",
          });
        } catch (error) {
          console.error(error);
        }
      },
    });
    //setVisibleMenu(false);
  };

  const onClickBtnHabilitarContrato = () => {
    Modal.confirm({
      bodyStyle: { display: "flex", flexDirection: "column" },
      icon: <QuestionCircleOutlined style={{ fontSize: "32px" }} />,
      content: (
        <span style={{ textAlign: "center", display: "block", fontSize: "16px" }}>
          ¿Está seguro de <b>habilitar</b> el documento seleccionado?
        </span>
      ),
      autoFocusButton: "cancel",
      centered: true,
      maskClosable: true,
      cancelText: "No, cerrar ventana",
      okText: "Si, habilitar documento",
      onOk: async () => {
        try {
          const documentoActualizado = await SeguimientoService.habilitar(
            getUsuarioAutenticado().empresaSeleccionada.id,
            envio.id
          );

          setDetalleEnvio(
            detalleEnvio.map((envio) => {
              if (envio.id === documentoActualizado.id) {
                return documentoActualizado;
              }
              return envio;
            })
          );

          notification.success({
            message: `Notificación`,
            description: "Se habilitó el contrato exitosamente.",
            placement: "topRight",
          });
        } catch (error) {
          console.error(error);
        }
      },
    });
    //setVisibleMenu(false);
  };

  useEffect(() => {
    // console.log("codigos opciones", codigosOpciones);
  }, [codigosOpciones]);

  const menu = () => {
    if (envio.eliminado === true) {
      return (
        <Menu onClick={onClickMenu}>
          {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_DETALLE_NOTIFICACION.BOTON_VER_DOCUMENTO) && (
            <Menu.Item key="ver-documento">
              <SearchOutlined /> Ver Documento
            </Menu.Item>
          )}
          {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_DETALLE_NOTIFICACION.BOTON_HABILITAR_DOCUMENTO) && (
            <Menu.Item key="habilitar">
              <CheckCircleOutlined /> Habilitar documento
            </Menu.Item>
          )}
        </Menu>
      );
    }

    return (
      <Menu onClick={onClickMenu}>
        {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_DETALLE_NOTIFICACION.BOTON_VER_DOCUMENTO) && (
          <Menu.Item key="ver-documento">
            <SearchOutlined /> Ver Documento
          </Menu.Item>
        )}
        {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_DETALLE_NOTIFICACION.BOTON_REENVIAR_DOCUMENTO) && envio.estadoRegistro!==EstadoRegistro.PENDIENTE_APROBAR && (
          <Menu.Item key="reenviar-documento">
            <MailOutlined /> Reenviar Documento
          </Menu.Item>
        )}
        {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_DETALLE_NOTIFICACION.BOTON_INHABILITAR_DOCUMENTO) && (
          <Menu.Item key="inhabilitar">
            <StopOutlined /> Inhabilitar Documento
          </Menu.Item>
        )}
        {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_DETALLE_NOTIFICACION.BOTON_ACTUALIZAR_CORREO) && (
          <Menu.Item key="actualizar-correo">
            <EditOutlined /> Actualizar Correo
          </Menu.Item>
        )}
        {codigosOpciones &&
          codigosOpciones.includes(OPCIONES.PAGINA_DETALLE_NOTIFICACION.BOTON_HISTORIAL_NOTIFICACION) && (
            <Menu.Item key="historial-notificacion">
              <AuditOutlined />
              Historial Notificación
            </Menu.Item>
          )}
      </Menu>
    );
  };

  return (
    <React.Fragment>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomCenter">
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
          <CaretDownOutlined
            style={{
              paddingLeft: "2px",
              fontSize: "12px",
              fontWeight: "bold",
              color: "#1678c1",
            }}
          />
        </a>
      </Dropdown>
    </React.Fragment>
  );
};

export default MenuAcciones;
