import React, { useState } from "react";
import { Col, DatePicker, Divider, Form, Input, Modal, Row, Select } from "antd";
import { useAtom } from "jotai";
import {
  colaboradorAtom,
  visibleModalActivarColaboradorAtom,
  visibleModalExitoAtom,
} from "../../LegajoColaboradorStore";
import { WarningOutlined } from "@ant-design/icons";
import { OnboardingIngresoColaboradorService } from "services/OnboardingIngresoColaboradorService";
import moment from "moment/moment";

const ModalActivarColaborador = () => {
  const [visible, setVisible] = useAtom(visibleModalActivarColaboradorAtom);
  const [, setVisibleModalExito] = useAtom(visibleModalExitoAtom);
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useAtom(colaboradorAtom);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onClickBtnActivar = () => {
    form
      .validateFields()
      .then(async (formulario) => {
        try {
          setLoading(true);
          const response = await OnboardingIngresoColaboradorService.activarColaborador({
            ...formulario,
            numeroDocumentoIdentidad: colaboradorSeleccionado.numeroDocumentoIdentidad,
          });
          if (response) {
            setColaboradorSeleccionado({ ...colaboradorSeleccionado, estadoRegistro: "ACTIVO" });
            setVisible(false);
            setVisibleModalExito(true);
          }
        } catch (error) {
          console.error("No se pudo activar el postulante", error);
        } finally {
          setLoading(false);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onClickBtnCancelar = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Activar Usuario"
      destroyOnClose={true}
      maskClosable={false}
      open={visible}
      okText="Activar"
      onOk={onClickBtnActivar}
      onCancel={onClickBtnCancelar}
      confirmLoading={loading}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "0px 60px",
        }}
      >
        <WarningOutlined style={{ fontSize: "48px", color: "#faad14" }} />
        <span style={{ fontSize: "20px", color: "#4a4e69", textAlign: "center" }}>
          ¿Está seguro de activar al postulante?
        </span>
        <span style={{ textAlign: "center", color: "#6d6875" }}>Complete y confirme la siguiente información:</span>
        <Divider style={{ margin: "12px 0" }} />
        <div style={{ width: "100%", marginBottom: "10px" }}>
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="fechaIngreso"
                  label="Fecha Ingreso"
                  rules={[{ required: true }]}
                  initialValue={
                    colaboradorSeleccionado?.fechaIngreso
                      ? moment(colaboradorSeleccionado?.fechaIngreso, "DD/MM/YYYY")
                      : undefined
                  }
                >
                  <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ModalActivarColaborador;
