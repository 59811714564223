import React, {useState} from "react";
import { Button, Modal, message,Upload } from "antd";
import { UploadOutlined  } from "@ant-design/icons";
import { useAtom ,useAtomValue} from "jotai";
import {visibleModalCargarDocumentoAtom,documentoSeleccionadoAtom} from "pages/legajo/legajoV3/LegajoColaboradorStore";
import DetalleEnvioService from "services/DetalleEnvioService";
import { useCargarOnboardingIngreso } from "../hooks";

const ModalCargarDocumento = () =>{

    const [fileDocumento,setFileDocumento] = useState([]);
    const [visible,setVisible] = useAtom(visibleModalCargarDocumentoAtom);
    const documentoSeleccionado = useAtomValue(documentoSeleccionadoAtom);
    const [loading,setLoading] = useState(false);    
    const { cargarOnboarding } = useCargarOnboardingIngreso();

    const props = {
        onRemove: (file) => {
            setFileDocumento([]);    
            return {
                fileDocumento: [],
            };
        },
        beforeUpload: (file) => {           
                setFileDocumento(file);
                return false;          
        },       
        fileDocumento,
    };

    const onClicksubirDocumento = async()=>{
      
      if (fileDocumento.name){
        setLoading(true)
        const formData = new FormData();
        formData.append("file", fileDocumento);
        formData.append("detalleEnvioId",documentoSeleccionado.id)
        console.log("documento:",fileDocumento)
        await DetalleEnvioService.cargarDocumento(formData);
        setLoading(false);
        cargarOnboarding();
        setVisible(false);
      }
      else{
         message.error("No ha seleccionado el documento")
      }     
    
    }

    return(
        <Modal
         title="Seleccionar Documento"
         open={visible}
         footer={null}
         onCancel={() => setVisible(false)}
         destroyOnClose={true}
        >
            <div style={{textAlign:"center"}}>
                  <Upload {...props} accept="application/pdf" maxCount={1}>
                    <Button icon={<UploadOutlined/>}>
                      Subir Documento
                    </Button>
                  </Upload>
                  <br/>
                  <div style={{textAlign:"center"}}>
                    <Button onClick={ onClicksubirDocumento} type="primary" loading={loading}>Iniciar carga</Button>
                  </div>
           </div>
           
        </Modal>
    )
}
export default ModalCargarDocumento;