import { atom } from "jotai";

export const colaboradorAtom = atom(undefined);
export const visibleModalActivarColaboradorAtom = atom(false);
export const visibleModalExitoAtom = atom(false);
export const visibleModalVisorPdfAtom = atom(false);
export const urlPdfAtom = atom(undefined);
//export const nombreDocumentoAtom = atom(undefined);
export const documentoSeleccionadoAtom = atom(undefined);
export const onboardingsIngresoAtom = atom([]);

//tab: contratos
export const contratosAtom = atom([]);
export const visibleModalCargarDocumentoAtom = atom(false);
export const visibleModalTransferirProyectoAtom = atom(false);

//tab: Onboararding
export const envioSeleccionadoAtom = atom(undefined);
