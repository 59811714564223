import { BaseService } from "./BaseService";

 const UsuarioService = {
  async listar(empresaId) {
    const respuesta = await BaseService.get("/usuario",{params:{empresaId:empresaId}});
    return respuesta.data;
  },
  async listarOrganizacion(organizacionId) {
    const respuesta = await BaseService.get("/usuario/listarUsuariosOrganizacion",{params:{organizacionId:organizacionId}});
    return respuesta.data;
  },
  async listarUsuariosAProbadores(empresaId) {
    const respuesta = await BaseService.get(
      "/usuario/listarUsuariosAprobadores",
      { params: { empresaId: empresaId } }
    );
    return respuesta.data;
  },
  async insertar(usuario) {
    const respuesta = await BaseService.post("/usuario", usuario);
    return respuesta.data;
  },
  async actualizar(usuario) {
    const respuesta = await BaseService.put("/usuario", usuario);
    return respuesta.data;
  },
  async buscarPorId(id) {
    const respuesta = await BaseService.get(`/usuario/${id}`);
    return respuesta.data;
  },
  async eliminar(id) {
    const respuesta = await BaseService.delete(`/usuario/${id}`);
    return respuesta.data;
  },
  async buscarPorOrganizacionLogin(organizacionId,login){
    const respuesta=await BaseService.get("/usuario/buscarPorOrganizacionLogin",
    {
      params:{
        login:login,
        organizacionId:organizacionId,
      },
    }
    );
    return respuesta.data;
  },
  async generaClave(id,empresaId){
    const respuesta=await BaseService.get("/usuario/generarClave",
     {
       params:{
         id:id,
         empresaId:empresaId,

       }
     }
    );
    return respuesta.data;
  },
  async listarUsuariosFirmnates(empresaId) {
    const respuesta = await BaseService.get(
      "/usuario/listarUsuariosFirmantes",
      { params: { empresaId: empresaId } }
    );
    return respuesta.data;
  },
  async esUsuarioRolReclutador(login){
    const respuesta=await BaseService.get("/usuario/esUsuarioRolReclutador",
    {
      params:{
        login:login,      

      }
    }
   );
   return respuesta.data;
  }
};

export default UsuarioService;
