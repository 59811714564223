import React from "react";
import { Button, Dropdown, Modal, message } from "antd";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import FormularioSeguimientoService from "services/FormularioSeguimientoService";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import {
    UnorderedListOutlined,
    CaretDownOutlined,
    FilePdfOutlined,
    DeleteOutlined,
    CheckCircleOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {
    documentoSeleccionadoAtom,
    onboardingsIngresoAtom,
    urlPdfAtom,
    visibleModalVisorPdfAtom,
    visibleModalCargarDocumentoAtom,
} from "pages/legajo/legajoV3/LegajoColaboradorStore";
import { useAtom, useSetAtom } from "jotai";
import { useModalConfirmar } from "store/global";
import { EstadoRegistro } from "enums/EstadoRegistro";
import DetalleEnvioService from "services/DetalleEnvioService";

const MenuAcciones = ({ documento }) => {
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const setVisibleModalVisorPdf = useSetAtom(visibleModalVisorPdfAtom);
    const setUrlPdf = useSetAtom(urlPdfAtom);
    const setDocumentoSeleccionado = useSetAtom(documentoSeleccionadoAtom);
    const [, setOnboardings] = useAtom(onboardingsIngresoAtom);

    const mostrarModal = useModalConfirmar((state) => state.mostrarModal);
    const [, setVisibleModalCargarDocumento] = useAtom(visibleModalCargarDocumentoAtom);

    const onClick = ({ key }) => {
        switch (key) {
            case "ver-documento":
                onClickVerDocumento();
                break;
            case "anular-documento":
                setDocumentoSeleccionado(documento);
                mostrarModal();
                break;
            case "habilitar-documento":
                onHabilitar(documento.id);
                break;
            case "subir-documento":
                subirDocumento();
                break;
            default:
                break;
        }
    };

    const onHabilitar = async (id) => {
        try {
            await DetalleEnvioService.habilitar(documento.id);
            setOnboardings((onboardings) => {
                return onboardings.map((onboarding) => {
                    if (onboarding.id === id) {
                        return { ...onboarding, estadoRegistro: "ACTIVO" };
                    }
                    return onboarding;
                });
            });
            message.success("El documento se ha habilitado correctamente.");
        } catch (error) {
            message.error("Ocurrio un error al intentar actualizar el documento");
        }
    };

    const items = [
        {
            label: "Ver Documento",
            key: "ver-documento",
            icon: <FilePdfOutlined style={{ fontSize: "16px" }} />,
        },
        documento?.categoriaDocumento.id === CategoriaDocumento.CARGA_DOCUMENTO.id &&
            documento?.interno && {
                label: "Subir Documento",
                key: "subir-documento",
                icon: <UploadOutlined style={{ fontSize: "16px" }} />,
            },
        (documento?.estadoVistaColaborador === "PENDIENTE" || documento?.categoriaDocumentoNombre =="CARGA DOCUMENTO") &&
            documento?.estadoRegistro !== EstadoRegistro.INACTIVO&& {
                label: "Anular Documento",
                key: "anular-documento",
                icon: <DeleteOutlined style={{ fontSize: "16px" }} />,
                danger: true,
            },
        documento?.estadoRegistro === EstadoRegistro.INACTIVO && {
            label: "Habilitar Documento",
            key: "habilitar-documento",
            icon: <CheckCircleOutlined style={{ fontSize: "16px" }} />,
        },
    ];

    const onClickVerDocumento = async () => {
        setDocumentoSeleccionado(documento);
        if (documento?.estadoVistaColaborador !== "FIRMADO") {
            if (documento.categoriaDocumento.id === CategoriaDocumento.FORMULARIOS.id) {
                const respuesta = await FormularioSeguimientoService.getUrlPlantillaDocumento(
                    documento.envio.tipoDocumentoId
                );
                setUrlPdf(respuesta.urlPlantilla);
                setVisibleModalVisorPdf(true);
            } else if (documento.categoriaDocumento.id === CategoriaDocumento.CARGA_DOCUMENTO.id) {
                Modal.error({
                    title: "Falta completar",
                    content: "El documento no ha sido cargado.",
                });
            }
        } else {
            try {
                const respuesta = await FormularioSeguimientoService.getUrlDocumento(
                    getUsuarioAutenticado().empresaSeleccionada.id,
                    documento.id
                );
                setUrlPdf(respuesta.urlDocumento);
                setVisibleModalVisorPdf(true);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const subirDocumento = () => {
        setVisibleModalCargarDocumento(true);
        setDocumentoSeleccionado(documento);
    };

    return (
        <Dropdown menu={{ items, onClick }}>
            <Button type="link" className="ant-dropdown-link">
                <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
                <CaretDownOutlined
                    style={{
                        paddingLeft: "2px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#1678c1",
                    }}
                />
            </Button>
        </Dropdown>
    );
};

export default MenuAcciones;
