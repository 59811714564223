import { BaseService } from "./BaseService";

const TipoDocumentoService = {
  //buscar una constante con areglos para los tipos de documentos o crear metodo distintos
  async listar(empresaId, categoriaDocumentoId) {
    const respuesta = await BaseService.get("/tipoDocumento", {
      params: {
        empresaId: empresaId,
        categoriaDocumentoId: categoriaDocumentoId,
      },
    });
    return respuesta.data;
  },

  async listarPorEmpresaYCategoria(empresaId, categoriaDocumentoNombre) {
    const respuesta = await BaseService.get(
      "/tipoDocumento/listarPorEmpresaYCategoria",
      {
        params: {
          empresaId: empresaId,
          categoriaDocumentoNombre: categoriaDocumentoNombre,
        },
      }
    );
    return respuesta.data;
  },

  async listarPorEmpresa(empresaId) {
    const respuesta = await BaseService.get("/tipoDocumento/listarPorEmpresa", {
      params: {
        empresaId: empresaId,
      },
    });
    return respuesta.data;
  },

  async insertar(tipoDocumento) {
    const respuesta = await BaseService.post("/tipoDocumento", tipoDocumento);
    return respuesta.data;
  },
  async insertarNuevo(tipoDocumento) {
    const respuesta = await BaseService.post(
      "/tipoDocumento/Nuevo",
      tipoDocumento
    );
    return respuesta.data;
  },
  async insertarTipoDocumento(tipoDocumento) {
    const respuesta = await BaseService.post(
      "/tipoDocumento/configuracion",
      tipoDocumento
    );
    return respuesta.data;
  },
  async buscarPorId(id) {
    const respuesta = await BaseService.get(`/tipoDocumento/${id}`);
    return respuesta.data;
  },
  async actualizar(tipoDocumento) {
    const respuesta = await BaseService.post(
      "/tipoDocumento/configuracion/editar",
      tipoDocumento
    );
    return respuesta.data;
  },
  async deshabilitarPorId(id) {
    const respuesta = await BaseService.delete(
      `/tipoDocumento/configuracion/${id}`
    );
    return respuesta.data;
  },

  async listarPorUsuario(login) {
    const respuesta = await BaseService.get("/tipoDocumento/usuario", {
      params: {
        login: login,
      },
    });
    return respuesta.data;
  },
  async listarPorUsuarioYCategorizado(login) {
    const respuesta = await BaseService.get("/tipoDocumento/usuario/listarCategorizado", {
      params: {
        login: login,
      },
    });
    return respuesta.data;
  },
  async listarPorUsuarioYCategoria(empresaId,login, categoriaId) {
    const respuesta = await BaseService.get("/tipoDocumento/usuarioYCategoria", {
      params: { empresaId:empresaId,
                login: login,
                categoriaId: categoriaId },
    });
    return respuesta.data;
  },
  async listarPorUsuarioYFormularioYContrato(login) {
    const respuesta = await BaseService.get("/tipoDocumento/usuario/listarFormularioYContrato", {
      params: {
        login: login,
      },
    });
    return respuesta.data;
  },
  async buscarContratoPorId(id) {
    const respuesta = await BaseService.get(`/tipoDocumento/contrato-trama/${id}`);
    return respuesta.data;
  },
  async listarFormularioYCargaDocumentoConFiltro(filtro) {
    const respuesta = await BaseService.get("/tipoDocumento/listarFormularioYCargaDocumentoConFiltro", {
      params: {       
        filtro: filtro,
      },
    });
    return respuesta.data;
  },
  async listarFormularioYCargaDocumento() {
    const respuesta = await BaseService.get("/tipoDocumento/listarFormularioYCargaDocumento");
    return respuesta.data;
  },
};
export default TipoDocumentoService;
