import React, { useState, useEffect, useRef } from "react";
import { ContainerCrear } from "../../../components/ContainerCrear";
import {
  Form,
  Input,
  Row,
  Button,
  Select,
  Modal,
  notification,
  Tabs,
  Typography,
  DatePicker,
  Space,
  Alert,
} from "antd";
import { ExclamationCircleOutlined, FolderTwoTone, FolderFilled } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import TipoDocumentoService from "../../../services/TipoDocumentoService";
import { LoginService } from "services/LoginService";
import { EnvioService } from "services/EnvioService";
import UsuarioService from "../../../services/UsuarioService";
import { ModalPDF } from "../../../components/PDFViewer";
import { useHistory } from "react-router-dom";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import FormItem from "antd/lib/form/FormItem";
import CarpetaService from "services/CarpetaService";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import CertificadoService from "services/CertificadoService";
import { EstadoCertificadoDigital } from "enums/EstadoCertificadoDigital";
import ParametrosService from "services/ParametrosService";

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 18,
  },
};

const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Title } = Typography;
const { Text } = Typography;
const childrenOption = [];

export function DocumentoLaboralEnvioNuevoPage() {
  const [tipoDocumentos, setTipoDocumentos] = useState([]);
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const { setDatosTipoDocumento } = useContext(SecurityContext);
  const [frecuencia, setFrecuencia] = useState(false);
  //const [deshabilitarBtnCancelar, setDeshabilitarBtnCancelar] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setDatosEnvio } = useContext(SecurityContext);
  const [carpetas, setCarpetas] = useState([]);
  const [formularioCrearNotificacion] = Form.useForm();
  const [listaCarpetasFiltro, setListaCarpetasFiltro] = useState([]);
  const [respuestaCertificadoExpirado, setRespuestaCertificadoExpirado] = useState("");
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [estado, setEstado] = useState('VIGENTE');
  let history = useHistory();
  const [isSelectionMultiple, setIsSelectionMultiple] = useState(undefined);

  useEffect(() => {
    async function cargarDatos() {
      const usuario = await UsuarioService.buscarPorOrganizacionLogin(
        getUsuarioAutenticado().organizacionId,
        getUsuarioAutenticado().login
      );

      const empresaSeleccionada = usuario?.empresas.find(
        (empresa) => empresa.id === getUsuarioAutenticado().empresaSeleccionada.id
      );

      // const _tipoDocumento = await TipoDocumentoService.listarPorUsuario(getUsuarioAutenticado().login);
      // if (_tipoDocumento !== null) {
      //   const _tipoDocumentoFormulario = _tipoDocumento.filter(
      //     (f) => f.categoriaDocumento?.id === CategoriaDocumento.DOCUMENTO_LABORAL.id
      //   );
      //   setTipoDocumentos(_tipoDocumentoFormulario);
      // } else {
      //   const tipoDocumentos = await TipoDocumentoService.listar(
      //     getUsuarioAutenticado().empresaSeleccionada.id,
      //     CategoriaDocumento.DOCUMENTO_LABORAL.id
      //   );
      //   setTipoDocumentos(tipoDocumentos);
      // }
      const seleccionMultiple = await ParametrosService.isSeleccionMultiple();
      setIsSelectionMultiple(seleccionMultiple===true?"multiple":null)
      const _tipoDocumento = await TipoDocumentoService.listarPorUsuarioYCategoria(getUsuarioAutenticado().empresaSeleccionada.id,getUsuarioAutenticado().login, CategoriaDocumento.DOCUMENTO_LABORAL.id);

        setTipoDocumentos(_tipoDocumento);

      const _carpetas = await getListaCarpetasFiltro(empresaSeleccionada);
      //const _carpetas=await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);

      setListaCarpetasFiltro(_carpetas);
    }
    cargarDatos();
  }, []);

  const getListaCarpetasFiltro = async (empresaSeleccionada) => {
    const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
    setCarpetas(_carpetas);
    if (!empresaSeleccionada?.filtroCarpeta?.length > 0) {
      return _carpetas;
    }

    var temp = [];
    const _temporal = empresaSeleccionada?.filtroCarpeta?.map((e) => {
      temp.push(_carpetas.find((c) => c.id == e.id));
    });

    return temp;
  };

  function handleOnChangeSeleccionarTipoDocumento(value) {
    if(isSelectionMultiple !== "multiple" ){
      const temp = tipoDocumentos?.find((item) => item.id == value);

      setFrecuencia(temp.frecuencia == "SEMANAL" ? true : false)
      setMostrarAlerta(temp.estadoCertificado==='EXPIRADO'||temp.estadoCertificado==='SIN_CERTIFICADO')
      setEstado(temp.estadoCertificado);
      if (childrenOption.length == 0 || childrenOption == null) {
        for (let i = 1; i < 54; i++) {
          childrenOption.push(<Option key={i}>{i}</Option>);
        }
      }
    }
    
  }

  function handleOnFinish(formulario) {
    if(!Array.isArray(formulario.tipoDocumentoId) || formulario.tipoDocumentoId?.length===1){

      let tipoDocumento;
      if(Array.isArray(formulario.tipoDocumentoId))
        tipoDocumento = tipoDocumentos.find((obj) => obj.nombre === formulario.tipoDocumentoId[0]);
      else
        tipoDocumento = tipoDocumentos.find((obj) => obj.id === formulario.tipoDocumentoId);

      const flujo = tipoDocumento.flujo !== null ? tipoDocumento.flujo : null;
      formulario.flujo = flujo;
      formulario.tipoDocumentoNombre = tipoDocumento.nombre;
      formulario = {...formulario, tipoDocumentoId: [tipoDocumento.id]};
      //console.log(tipoDocumento)
    }
    
    
    //let aprobacion = tipoDocumentos.find((obj)=>obj.id===formulario.tipoDocumentoId).aprobacion;
    if(isSelectionMultiple==="multiple" && formulario.tipoDocumentoId.length > 1)
      formulario.seleccionMultiple=true;
    else
      formulario.seleccionMultiple= false;
    //formulario.seleccionMultiple=isSelectionMultiple==="multiple"?true:false;

    formulario.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
    formulario.organizacionId = getUsuarioAutenticado().organizacionId;
    
    //formulario.aprobacion = aprobacion != null? aprobacion : null;
    
    formulario.usuarioNumeroDocumentoIdentidad = getUsuarioAutenticado().numeroDocumentoIdentidad;
    formulario.usuarioLogin = getUsuarioAutenticado().login;
    formulario.usuarioNombreCompleto = getUsuarioAutenticado().nombreCompleto;
    formulario.usuarioCorreoElectronico = getUsuarioAutenticado().correoElectronico;
    formulario.periodo = formulario.periodo.format("MM/YYYY");

    //console.log(formulario)
    
    confirm({
      title: "¿ Esta seguro de  crear la notificación ?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        crearNotificacion(formulario);
      },
      onCancel() {
        handleOnClickCancelar();
      },
    });

    /*}*/

    //  let tipoDocumentoNombre=tipoDocumentos.find((obj)=>obj.id===formulario.tipoDocumentoId).nombre;
    //  let tipoDocumentoAprobacion=tipoDocumentos.find((obj)=>obj.id===formulario.tipoDocumentoId).aprobacion;
    //  setDatosTipoDocumento(formulario.tipoDocumentoId,tipoDocumentoNombre,formulario.descripcion,tipoDocumentoAprobacion);
    //  history.push("/documento-laboral/listarEnvioNotificacion");
  }

  async function crearNotificacion(formulario) {
    try {
      //setDeshabilitarBtnCancelar(true);
      setLoading(true);
      const data = await EnvioService.crear(formulario);
      notification.success({
        message: "Operación exitosa",
        description: "Se creo el registro correctamente.",
      });
      //console.log(data)
      setDatosEnvio(
        data.id,
        data.tipoDocumentoNombre,
        data.descripcion,
        Array.isArray(data.tipoDocumentoId)?data.tipoDocumentoId[0]:data.tipoDocumentoId,
        data.flujo,
        data.formatoCargaId,
        null,
        data.listaTipoDocumentos
      );

      //setDatosEnvio("455252525252525", "Boleta de pago", "noviembre 2020");
      setLoading(false);

      history.push("/documento-laboral/listarEnvioNotificacion");
    } catch (error) {
      console.error(error);
      setLoading(false);
      //setDeshabilitarBtnCancelar(false);
    }
  }

  function handleOnClickCancelar() {
    // history.push("/documento-laboral/envioNuevo");
    history.push("/documento-laboral/envio");
  }
  function onChangeSelectCarpeta(value, option) {
    formularioCrearNotificacion.setFieldsValue({ carpeta: { descripcion: option.descripcion } });
  }

  return (<React.Fragment>
    {
      mostrarAlerta && (
        <div style={{ marginBottom: "10px" }}>
          <Alert
            message={
              estado === EstadoCertificadoDigital.EXPIRADO ?
                <Text>No puede enviar este documento porque su certificado digital se encuentra expirado. </Text> :
                <Text >No hay certificado registrado para está empresa</Text>
            }
            type="warning"
            showIcon
          />
        </div>
      )
    }
  
    <ContainerCrear titulo="Crear Notificación" colSpan={16}>
      
      <Form {...layout} size="medium" onFinish={handleOnFinish} form={formularioCrearNotificacion}>
        <Form.Item
          label="Periodo"
          name="periodo"
          rules={[{ required: true, message: "Seleccione el periodo." }]}
        >
          <DatePicker
            format="MM/YYYY"
            style={{ width: "65%" }}
            picker="month"
            placeholder="Selecciona un periodo"
          />

        </Form.Item>

        <Form.Item
          label="Documento"
          name="tipoDocumentoId"
          rules={[
            {
              required: true,
              message: "El campo documento es obligatorio.",
            },
          ]}
        >
          <Select
            placeholder="Seleccione un documento"
            onChange={(value,data,groupdata)=> handleOnChangeSeleccionarTipoDocumento(value,data,groupdata)}
            mode={isSelectionMultiple}
          >
            {tipoDocumentos?.length > 0 &&
              tipoDocumentos?.map((documento) => {
                return (
                  <Option key={documento.id} value={isSelectionMultiple!==null?documento.nombre:documento.id}>
                    {documento.nombre}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        {frecuencia && (
          <Form.Item label="Semana" name="frecuencia" rules={[{ required: true, message: "Seleccione la semana." }]}>
            <Select placeholder="Ingrese la semana" showSearch>
              {childrenOption}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label="Descripción"
          name="descripcion"
          // rules={[
          //   {
          //     required: true,
          //     message: "El campo descripción es obligatorio.",
          //   },
          // ]}
        >
          <Input
            placeholder="Ingrese una descripción"
            maxLength="50"
            autoComplete="off"
            //onChange={handleOnChangeDescripcion}
          />
        </Form.Item>
        {getUsuarioAutenticado().empresaSeleccionada.nombreCampoSede !== null &&
          getUsuarioAutenticado().empresaSeleccionada.nombreCampoSede !== "" && (
            <Form.Item label={getUsuarioAutenticado().empresaSeleccionada.nombreCampoSede} name="sedeNombre">
              <Input placeholder="Ingrese unidad de trabajo" autoComplete="off" />
            </Form.Item>
          )}
        <Form.Item label="Carpeta" name={["carpeta", "id"]}>
          <Select onChange={onChangeSelectCarpeta} optionLabelProp="label" placeholder="Seleccione la carpeta">
            {carpetas.length > 0 &&
              carpetas.map((carpeta) => {
                return (
                  <Option
                    key={carpeta.id}
                    disabled={listaCarpetasFiltro.filter((item) => item.id == carpeta.id).length == 0}
                    value={carpeta.id}
                    label={
                      <div>
                        <FolderTwoTone style={{ fontSize: "20px", color: "#F7D358" }} twoToneColor="#F7D358" />{" "}
                        {carpeta.ruta}
                      </div>
                    }
                    descripcion={carpeta.descripcion}
                  >
                    <Space size={carpeta.nivel * 10}>
                      <span></span>
                      <span>
                        {carpeta.nivel === 0 ? (
                          <FolderFilled style={{ fontSize: "20px", color: "#F7D358" }} />
                        ) : (
                          <FolderTwoTone style={{ fontSize: "18px", color: "#F7D358" }} twoToneColor="#F7D358" />
                        )}{" "}
                        {carpeta.descripcion}
                      </span>
                    </Space>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name={["carpeta", "descripcion"]} noStyle>
          <Input type="hidden" />
        </Form.Item>

        <Row justify="center">
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              // icon={<MailOutlined />}
              //  loading={loading}
               disabled={mostrarAlerta}
              htmlType="submit"
            >
              Guardar
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </ContainerCrear>
    </React.Fragment>
  );
}
