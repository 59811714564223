import React, { useEffect, useState, useContext } from "react";
import { Breadcrumb, Button, Space, notification } from "antd";
import { Container } from "components/Container";
import { HomeOutlined, LoadingOutlined,DownloadOutlined } from "@ant-design/icons";
import { buttonMasivoAtom, descripcionEnvioAtom, isDetalleAtom,
  filtroColaboradorAtom,filtroFechaInicioAtom,filtroFechaFinAtom,
  filtroProyectoAtom, filtroReclutadorAtom, filtroProcesoAtom
 } from "./SeguimientoOnboardingStore";
import { useAtom } from "jotai";
import { EnvioOnboardingPage } from "./componentes/EnvioOnboarding";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import UsuarioService from "services/UsuarioService";
import { SecurityContext } from "context/SecurityContextProvider";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";
import { saveAs } from "file-saver";

function SeguimientoOnboarding() {
  const [isDetalle, setIsDetalle] = useAtom(isDetalleAtom);
  const [descripcionEnvio, setDescripcionEnvio] = useAtom(descripcionEnvioAtom);
  const [, setButtonMasivo] = useAtom(buttonMasivoAtom);
  const [esUsuarioReclutador,setEsUsuarioReclutador]=useState(false);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [loadingExportar, setLoadingExportar] = useState(false);
  const [filtroColaborador] = useAtom(filtroColaboradorAtom);
  const [filtroInicio] = useAtom(filtroFechaInicioAtom);
  const [filtroFin] = useAtom(filtroFechaFinAtom);
  const [filtroProyecto] = useAtom(filtroProyectoAtom);
  const [filtroReclutador] = useAtom(filtroReclutadorAtom);
  const [filtroProceso] = useAtom(filtroProcesoAtom);
  const isExplomin =
    getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837"
      ? true
      : false;
  // const onClickBtnRetornar = () => {
  //   setIsDetalle(false);
  //   setDescripcionEnvio();
  //   setEnvioId();
  //   setDetallesOnboarding();
  // };
 useEffect(()=>{
  const cargarDatos =async()=>{
    const respuesta= await UsuarioService.esUsuarioRolReclutador(getUsuarioAutenticado().login);
    setEsUsuarioReclutador(respuesta);
  }
   cargarDatos();
 },[])

  async function handleOnclickExportarDatos() {
    setLoadingExportar(true);
		try {
			let respuesta = await OnboardingColaboradorService.descargarExcel(
        filtroColaborador,
        filtroInicio,
        filtroFin,
        filtroProyecto,
        filtroReclutador,
        filtroProceso,
				getUsuarioAutenticado().login
			);
			setLoadingExportar(false);
			const filename = respuesta.headers.filename;
			saveAs(respuesta.data, filename);
		} catch (error) {
			setLoadingExportar(false);
			console.log(error);
			notification.error({
				message: "No hay colaboradores para exportar",
				description: error.response.data.mensaje,
			});
		}
  }

  return (
    <Container
      icono={
        <Breadcrumb itemRender>
          <Breadcrumb.Item href="/dashboard">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/onboarding/seguimiento">Seguimiento Onboarding</Breadcrumb.Item>
          {isDetalle && <Breadcrumb.Item href={null}>{"Proceso : " + descripcionEnvio}</Breadcrumb.Item>}
        </Breadcrumb>
      }      
      botonExtra={!esUsuarioReclutador &&(
        <Space>
          {isExplomin &&(
            <Button loading={loadingExportar} icon={<DownloadOutlined />} onClick={handleOnclickExportarDatos}>
						  Exportar
					  </Button>
          )}
          <Button type="primary" onClick={()=>setButtonMasivo(true)}>Asignar Carpeta</Button>
        </Space>
      )}
      // onClickRetornar={isDetalle && onClickBtnRetornar}
    >
      {!isDetalle && <EnvioOnboardingPage />}
      {/* {isDetalle && <DetalleOnboardingPage />} */}
      <ModalVisorPdf />
    </Container>
  );
}

export default SeguimientoOnboarding;
