import React, { useContext, useState } from "react";
import { Alert, Table, Typography, Tag, Space, message } from "antd";
import {
  FolderFilled,
  FileTextFilled,
  MobileOutlined,
  MailOutlined,
  SendOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";

import { EstadoDocumento } from "enums/EstadoDocumento";
import { MenuAcciones } from "../menu-acciones";

import { useModalVisorPdf } from "store/global";
import { EtiquetaEstadoNotificacion } from "./etiqueta-estado-notificacion";
import { EtiquetaEstadoVisualizacion } from "./etiqueta-estado-visualizacion";
import { EtiquetaEstadoFirmaColaborador } from "./etiqueta-estado-firma-colaborador";
import { OpcionService } from "services";
import { TipoDocumentoLegajo } from "enums/TipoDocumentoLegajo";
import LegajoService from "services/LegajoService";
import DetalleEnvioService from "services/DetalleEnvioService";

const TablaDocumentos = ({ props, onChange }) => {
  const { Text } = Typography;
  const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  const verDocumentosCarpeta = async (documento) => {
    try {
      onChange({ valor: { loading: true } })
      const documentosResponse = await LegajoService.listarDocumentosCarpeta(
        props.numeroDocumento,
        getUsuarioAutenticado().empresaSeleccionada.id,
        documento.carpetaId,
        getUsuarioAutenticado().login
      );
      const mensaje = documentosResponse.find((d) => d.tipo === "MENSAJE");

      const _documentos = documentosResponse.filter((d) => d.tipo !== "MENSAJE");

      onChange({
        valor: {
          documentos: _documentos, breadcrumbItems: [
            ...props.breadcrumbItems,
            {
              nombre: documento.nombre,
              carpetaId: documento.carpetaId,
            },
          ],
          loading: false,
          mensajeLegajo: mensaje ? mensaje.mensaje : undefined

        }
      })

    } catch (error) {
      console.error(error);
      onChange({ valor: { loading: false } })
    }
    // finally {
    // }
  };

  const verDocumento = async (documento) => {
    try {
      onChange({ valor: { loading: true } })
      if (documento.categoriaDocumento === "FORMULARIOS" && documento.estadoFirmaColaborador === "PENDIENTE")
        message.error("El formulario seleccionado aun no ha sido completado.");
      else {
        const respuesta = await DetalleEnvioService.obtenerUrlDocumento(
          getUsuarioAutenticado().empresaSeleccionada.id,
          documento.detalleEnvioId
        );

        mostrarModalVisorPdf(respuesta.urlDocumento, documento.nombre, true, true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      onChange({ valor: { loading: false } })
    }
  };

  const columnaDocumento = [
    // {
    //   title: "Nombre",
    //   //dataIndex: "nombre",
    //   key: "nombre",
    //   width: "25%",
    //   render: (text, fila) => (
    //     <span>
    //       {fila.tipo === TipoDocumentoLegajo.CARPETA ? (
    //         <React.Fragment>
    //           <a onClick={() => verDocumentosCarpeta(fila)}>
    //             <FolderFilled style={{ fontSize: "30px", color: "#F7D358" }} /> {fila.nombre}
    //           </a>
    //         </React.Fragment>
    //       ) : (
    //         <React.Fragment>
    //           <a onClick={() => verDocumento(fila)}>
    //             <FileTextFilled style={{ fontSize: "30px", color: "#0080FF" }} /> {fila.nombre}
    //           </a>
    //         </React.Fragment>
    //       )}
    //     </span>
    //   ),
    // },

    // {
    //   title: "Periodo",
    //   //dataIndex: "fechaNotificacion",
    //   key: "fechaNotificacion",
    //   align: "center",
    //   render: (text, fila) => {
    //     if (fila.tipo === TipoDocumentoLegajo.CARPETA) {
    //       return <Text>{"-"}</Text>;
    //     } else {
    //       return <React.Fragment>{fila.periodo}</React.Fragment>;
    //     }
    //   },
    // },
    {
      title: "Nombre",
      //dataIndex: "nombre",
      key: "nombre",
      width: "45%",
      render: (text, fila) => (
        <span>
          {fila.tipo === TipoDocumentoLegajo.CARPETA ? (
            <React.Fragment>
              <a onClick={() => verDocumentosCarpeta(fila)}>
                <FolderFilled style={{ fontSize: "30px", color: "#F7D358" }} /> {fila.nombre}
              </a>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <a onClick={() => verDocumento(fila)}>
                <div style={{ display: "flex" }}>
                  <FileTextFilled style={{ fontSize: "30px", color: "#0080FF" }} />
                  <span style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <span>{fila.nombre}</span>
                    {fila.estadoDocumento === EstadoDocumento.CARGADO
                      &&
                      <span>
                        <Tag color="cyan" style={{ borderRadius: "8px" }}>Carga Manual</Tag>
                      </span>
                    }
                  </span>
                </div>
              </a>
            </React.Fragment>
          )}
        </span>
      ),
    },
    // {
    //   title: "Bandeja",
    //   key: "bandejaNotificacion",
    //   align: "center",
    //   width: "20%",
    //   render: (text, fila) => {
    //     if (fila.tipo === TipoDocumentoLegajo.CARPETA) {
    //       return <Text>{"-"}</Text>;
    //     } else {
    //       return (
    //         <React.Fragment>
    //           {fila.fechaNotificacion && (
    //             <p>
    //               <SendOutlined /> {fila.fechaNotificacion}
    //             </p>
    //           )}
    //           {fila.correoElectronico && (
    //             <p>
    //               <MailOutlined /> {fila.correoElectronico}
    //             </p>
    //           )}
    //           {fila.celular && (
    //             <p>
    //               <MobileOutlined /> {fila.celular}
    //             </p>
    //           )}
    //         </React.Fragment>
    //       );
    //     }
    //   },
    // },
    {
      title: "Notificación",
      dataIndex: "estadoNotificacion",
      key: "estadoNotificacion",
      align: "center",
      render: (text, fila) => {
        if (fila.tipo === TipoDocumentoLegajo.CARPETA) {
          return <Text>{"-"}</Text>;
        }
        if (fila.estadoDocumento === EstadoDocumento.GENERADO || fila.estadoDocumento === null) {
          return <EtiquetaEstadoNotificacion documento={fila} />;
        }
        if (fila.estadoProcesoId === 6) {
          return {
            children: <Tag>PENDIENTE DE APROBACIÓN</Tag>,
            props: {
              colSpan: 2,
            },
          };
        }
      },
    },
    {
      title: "Visualizado",
      dataIndex: "estadoVisualizacion",
      key: "estadoVisualizacion",
      align: "center",
      render: (text, fila) => {
        if (fila.tipo === TipoDocumentoLegajo.CARPETA) {
          return <Text>{"-"}</Text>;
        }
        if (fila.estadoDocumento === EstadoDocumento.GENERADO || fila.estadoDocumento === null) {
          return <EtiquetaEstadoVisualizacion documento={fila} />;
        }
        if (fila.estadoProcesoId === 6) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
      },
    },
    {
      title: "Firmado",
      dataIndex: "estadoFirmaColaborador",
      key: "estadoFirmaColaborador",
      align: "center",
      render: (text, fila) => {
        if (fila.tipo === TipoDocumentoLegajo.CARPETA) {
          return <Text>{"-"}</Text>;
        }
        if (fila.estadoDocumento === EstadoDocumento.GENERADO || fila.estadoDocumento === null) {
          return <EtiquetaEstadoFirmaColaborador documento={fila} />;
        }
      },
      onCell: (fila) => ({
        colSpan: fila.estadoProcesoId === 6 ? 0 : 1,
      }),
    },
    {
      title: "",
      key: "acciones",
      align: "center",
      width: "8%",
      render: (fila) => fila.tipo === TipoDocumentoLegajo.DOCUMENTO && <MenuAcciones documento={fila} opciones={props.codigosOpciones} />,
    },
  ];

  return (
    <Table
      //bordered
      rowKey={(documento) => (documento.detalleEnvioId ? documento.detalleEnvioId : documento.carpetaId)}
      loading={{
        spinning: props.loading,
        indicator: <LoadingOutlined style={{ fontSize: 28 }} />,
        tip: "Cargando...",
      }}
      dataSource={props.documentos}
      columns={columnaDocumento}
      pagination={false}
      scroll={{ y: "calc(100vh - 320px)" }}
    //footer={() => mensajeLegajo!==undefined?<Alert message={mensajeLegajo} type="warning" />:undefined}
    />
  );
};
export default TablaDocumentos;
