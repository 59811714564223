import Axios from "axios";
import { BaseService } from "./BaseService";
const GestionarClaveService = {

  async cambiarClave(subdominio, login, clave,claveActual) {
    const respuesta = await BaseService.post("/cuenta/cambiarClave", {
      subdominio: subdominio,
      //numeroDocumentoIdentidad: numeroDocumentoIdentidad,
      login: login,
      clave: clave,
      claveActual: claveActual,
    });
    return respuesta.data;
  },
  // async actualizarClave(
  //   subdominio,
  //   tipoDocumentoIdentidad,
  //   numeroDocumentoIdentidad,
  //   codigo,
  //   fechaNacimiento,
  //   clave
  // ) {
  //   const respuesta = await Axios.post(
  //     process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE +
  //       "/cuenta/actualizarClave",
  //     {
  //       subdominio: subdominio,
  //       tipoDocumentoIdentidad: tipoDocumentoIdentidad,
  //       numeroDocumentoIdentidad: numeroDocumentoIdentidad,
  //       codigo: codigo,
  //       fechaNacimiento: fechaNacimiento,
  //       clave: clave,
  //     }
  //   );
  //   return respuesta.data;
  // },
  async actualizarClave(correoElectronico) {
     const respuesta = await Axios.post(
      process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE +
      "/recuperarClaveAdmin",
     
      {
        //subdominio: subdominio,        
       // numeroDocumentoIdentidad: numeroDocumentoIdentidad,
       correoElectronico:correoElectronico,       
      }
    ); 
   
    return respuesta.data;
  },
  // async validarInformacion(nc, td, nd) {
    async validarInformacion(nc, id) {
    const respuesta = await Axios.post(
      process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE + "/validarInformacion",
      {
        nc: nc,
        //lg: lg,
        id: id,
      }
    );
    return respuesta.data;
  },
  async buscarCorreo(subdominio, numeroDocumentoIdentidad) {
    const respuesta = await Axios.post(
      process.env.process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE +
        "/buscarCorreo",
      {
        subdominio: subdominio,
        numeroDocumentoIdentidad: numeroDocumentoIdentidad,
      }
    );
    return respuesta.data;
  },
  async generarClave(subdominio, id, clave) {
    const respuesta = await Axios.post(
      process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE + "/generarClave",
      {
        subdominio: subdominio,
        //numeroDocumentoIdentidad: numeroDocumentoIdentidad,
        id: id,
        clave: clave,
      }
    );
    return respuesta.data;
  },  
  async cambiarClaveColaboradorPorAdmin(subdominio,numeroDocumentoIdentidad,clave) {
		const respuesta = await BaseService.post(
			"/cuenta/cambiarClaveColaboradorPorAdmin",
			{
				subdominio: subdominio,
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
				clave: clave,				
			}
		);
		return respuesta.data;
	},
  async generarClaveColaborador(subdominio,empresaId,numeroDocumentoIdentidad){
    const respuesta=await BaseService.post("cuenta/generarClaveColaborador",
      {
        subdominio:subdominio,
        empresaId:empresaId,
        numeroDocumentoIdentidad:numeroDocumentoIdentidad,
      }
    );
    return respuesta.data;
  }
};
export default GestionarClaveService;
