import { BaseService } from "./BaseService";

const LegajoColaboradorService = {
  async listarColaboradores(data) {
    const respuesta = await BaseService.get("/v2/legajo/colaboradores", {
      params: {
        ...data,
      },
    });
    return respuesta.data;
  },
  async listarColaboradoresPorfiltroUsuario(data) {
    const respuesta = await BaseService.get("/v2/legajo/colaboradores-por-filtro-usuario", {
      params: {
        ...data,
      },
    });
    return respuesta.data;
  },
  async listarColaboradoresPorfiltroUsuarioYColaborador(data) {
    const respuesta = await BaseService.get("/v2/legajo/colaboradores-por-filtro-usuario-colaborador", {
      params: {
        ...data,
      },
    });
    return respuesta.data;
  },
};
export default LegajoColaboradorService;
