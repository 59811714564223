import React, { useState } from "react";
import { Col, DatePicker, Descriptions, Divider, Form, Input, Modal, Row, Select, message } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useModalConfirmar } from "store/global";
import { useEffect } from "react";
import { documentoSeleccionadoAtom, onboardingsIngresoAtom } from "pages/legajo/legajoV3/LegajoColaboradorStore";
import DetalleEnvioService from "services/DetalleEnvioService";

const ModalAnularDocumento = () => {
  const [loading, setLoading] = useState(false);
  const documentoSeleccionado = useAtomValue(documentoSeleccionadoAtom);
  const visible = useModalConfirmar(state => state.visible);
  const cerrarModal = useModalConfirmar(state => state.cerrarModal);
  const [, setOnboardings] = useAtom(onboardingsIngresoAtom);

  const [form] = Form.useForm();

  const onClickBtnCancelar = () => {
    cerrarModal();
    form.resetFields();
    setLoading(false)
  };

  useEffect(() => {
    if (documentoSeleccionado != null) {
      form.setFieldsValue(documentoSeleccionado);
    }
  }, [documentoSeleccionado, form]);
  

  const onFinishForm = async (formulario) =>{
    try {
        setLoading(true)
        await DetalleEnvioService.inhabilitar(formulario.id, formulario.motivoAnulacion)
        setOnboardings((onboardings) => {
          return onboardings.map((onboarding) => {
            if (onboarding.id === formulario.id) {
              return { ...onboarding, estadoRegistro: "INACTIVO" };
            }
            return onboarding;
          });
        });    
        message.success("El documento se ha anulado correctamente.")
        onClickBtnCancelar()
      } catch (error) {
        message.error("Ocurrio un error al intentar actualizar el documento.")
        onClickBtnCancelar()
      }
  }

  

  return (
    <Modal
      title="Anular Documento"
      destroyOnClose={true}
      open={visible}
      okText="Anular"
      onOk={()=>form.submit()}
      confirmLoading={loading}
      onCancel={onClickBtnCancelar}
    >
      <Form form={form} onFinish={onFinishForm} layout="vertical">
      <Form.Item name="id" hidden>
            <Input disabled />
        </Form.Item>
      <Form.Item label="Nombre del documento" name="tipoDocumentoNombre">
            <Input disabled />
        </Form.Item>
        <Form.Item label="Tipo de documento" name="categoriaDocumentoNombre">
            <Input disabled />
        </Form.Item>
        <Form.Item label="Motivo de anulación" name="motivoAnulacion"  rules={[ { required: true, }, ]} >
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 5}} showCount maxLength={500}/>
        </Form.Item>
        </Form>
    </Modal>
  );
};

export default ModalAnularDocumento;
