import React,{useEffect,useState} from "react";
import {Modal,Form,Divider,Input, Button, Space,Col,Row,notification, Select, DatePicker} from "antd";
import {visibleModalReingresoColaboradorAtom} from "../../ColaboradorListarStore";
import { useAtom } from "jotai";
import {
  CheckOutlined,
  EditOutlined,
  MailOutlined,
  BankOutlined,
  FolderAddOutlined,
  CarOutlined,
  PlusOutlined,
  WalletOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import ParametrosService from "../../../../../services/ParametrosService";
import { CampoColaborador } from "enums/CampoColaborador";
import { SecurityContext } from "../../../../../context/SecurityContextProvider";
import ColaboradorService from "../../../../../services/ColaboradorService";
import { PlantillaEnvioDocumentoService } from "../../../../../services/PlantillaEnvioDocumentoService";
// import { SecurityContext } from "../../../../context/SecurityContextProvider";
// import GestionarClaveService from "../../../../services/GestionarClaveService";
import { UbigeoService } from "services";
const { Option } = Select;

const ModalReingreso= ({colaboradorSeleccionado, setColaboradores, colaboradores}) =>{
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [camposColaboradores, setCamposColaboradores] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [subsedes, setSubsedes] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [tipoPlanillas, setTipoPlanillas] = useState([]);
  const [ocultarAdminObra, setOcultarAdminObra] = useState(false);
  const [ocultarTipoServicio, setOcultarTipoServicio] = useState(false);
  const [seccionNueva, setSeccionNueva] = useState();
  const [subsedeNueva, setSubsedeNueva] = useState();
  const [perfilNuevo, setPerfilNuevo] = useState();
  const [tipoPlanillaNueva, setTipoPlanillaNueva] = useState();
  const [seccionSeleccionada, setSeccionSeleccionada] = useState(null);
  const [subsedeSeleccionada, setSubsedeSeleccionada] = useState(null);
  const [perfilSeleccionado, setPerfilSeleccionado] = useState(null);
  const [tipoPlanillaSeleccionado, setTipoPlanillaSeleccionado] = useState(null);
  const [plantillasOnboarding, setPlantillasOnbording] = useState([]);
  const [responsablesRenovarContrato, setresponsablesRenovarContrato] = useState([]);
  const [responsableRenovarContratoSeleccionado, setresponsableRenovarContratoSeleccionado] = useState(undefined);
  const [administradoresObra, setAdministradoresObra] = useState([]);
  const [administradorObraSeleccionado, setAdministradorObraSeleccionado] = useState(undefined);
  const [departamentos, setDepartamentos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [visibleModalReingresoColaborador,setVisibleModalReingresoColaborador] = useAtom(visibleModalReingresoColaboradorAtom);
  const [formularioCrear] = Form.useForm();
  //const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [generaClave,setGeneraClave]=useState(false);
  const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
const [loading, setLoading] = useState(false);
let history = useHistory();
  useEffect(() => {
    async function cargarDatos() {
        // const parametros = await ParametrosService.buscarPorEmpresaConCodigoColaboradorActivo(
        //     getUsuarioAutenticado().empresaSeleccionada.id
        // );
        // setCodigoColaboradorActivo(parametros.respuesta);

        const campos_colaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
        setCamposColaboradores(campos_colaborador);
        // console.log("Los campos colaborador:",campos_colaborador);
        const campoEnviado = await ColaboradorService.camposColaboradorListarNuevo(
            getUsuarioAutenticado().empresaSeleccionada.id
        );
        setPerfiles(campoEnviado.PERFIL);
        setSedes(campoEnviado.SEDE);
        setSubsedes(campoEnviado.SUBSEDE);
        setSecciones(campoEnviado.SECCION);
        setCargos(campoEnviado.CARGO);
        setTipoPlanillas(campoEnviado.PLANILLA);

        const departamentos = await UbigeoService.listarDepartamentos();
        setDepartamentos(departamentos);

        // const colaboradores = await ColaboradorService.listarTodos(getUsuarioAutenticado().login);
        // colaboradores.forEach((colaborador) => {
        //     opcionesOtrosAprobadores.push({
        //         label: colaborador.nombreCompleto,
        //         value: colaborador.id,
        //     });
        // });
        // setColaboradoresOtrosAprobadores(opcionesOtrosAprobadores);

        PlantillaEnvioDocumentoService.listar(getUsuarioAutenticado().login)
            .then((plantillasOnboarding) => {
                setPlantillasOnbording(plantillasOnboarding);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    cargarDatos();
  }, []);

  const cerrarModal = () => {
      formularioCrear.resetFields();
      setVisibleModalReingresoColaborador(false);
  }

  const onFinishReingresarColaborador = async(colaborador) => {
      try {    
        
          setLoading(true);
          colaborador.seccion = seccionSeleccionada;
          colaborador.subsede = subsedeSeleccionada;
          colaborador.perfil = perfilSeleccionado;
          colaborador.tipoPlanilla = tipoPlanillaSeleccionado;

          colaborador.empresa = {
            id: getUsuarioAutenticado().empresaSeleccionada.id,
            razonSocial: getUsuarioAutenticado().empresaSeleccionada.razonSocial,
            urlImagenLogotipo: getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo,
          };

          colaborador.numeroDocumentoIdentidad = colaboradorSeleccionado;
          colaborador.administradorObraId = administradorObraSeleccionado;
          colaborador.responsableRenovarContratoId = responsableRenovarContratoSeleccionado;
          
          colaborador.fechaIngreso = colaborador.contrato.fechaInicioContrato.format("YYYY-MM-DD");
                colaborador.contrato.fechaInicioContrato =
                    colaborador.contrato.fechaInicioContrato.format("DD/MM/YYYY");
          colaborador.contrato.fechaFinContrato = colaborador.contrato.fechaFinContrato.format("DD/MM/YYYY");
          
          colaborador.usuarioRegistro = getUsuarioAutenticado().nombreCompleto;
          const formData = new FormData();

          formData.append(
              "colaborador",
              new Blob([JSON.stringify(colaborador)], {
                  type: "application/json",
              })
          );

         // console.log("formData::::", formData);
          await ColaboradorService.reingresarColaborador(formData);
          setColaboradores(
			colaboradores.map((colaboradorMaper) => {
				if (colaboradorMaper.numeroDocumentoIdentidad === colaboradorSeleccionado) {
                    return { ...colaboradorMaper, estadoRegistro: "ONBOARDING"};
					//return { ...colaboradorMaper, estadoRegistro: "ONBOARDING", tieneAccesoPlataforma:true };
				}
				return colaboradorMaper;
			})
		);

          notification.success({
              message: "Operación exitosa",
              description: "Se registró el colaborador correctamente.",
          });
          formularioCrear.resetFields();
          setVisibleModalReingresoColaborador(false);
          setLoading(false);

   } catch (error) {
    console.log(error);
            notification.error({
                message: "No se puede registrar el Colaborador",
                description: error.response.data.mensaje,
            });
            setLoading(false);     
   }

  }

  async function onSelectPerfil(value, option) {
    const perfil = {
        id: option.value,
        nombre: option.children,
    };
    setPerfilSeleccionado(perfil);

    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
        getUsuarioAutenticado().empresaSeleccionada.id,
        value,
        CampoColaborador.PERFIL
    );
 
    procesarCambios(campoEnviado);
  }

  async function handleOnChangeSede(value, option) {
    formularioCrear.setFieldsValue({ sede: { nombre: option.children } });

    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
        getUsuarioAutenticado().empresaSeleccionada.id,
        value,
        CampoColaborador.SEDE
    );
    procesarCambios(campoEnviado);

    if (isExplomin && option.children === "PRINCIPAL") {
        setOcultarTipoServicio(true);
        setOcultarAdminObra(true);
    } else {
        setOcultarTipoServicio(false);
        setOcultarAdminObra(false);
    }
  }

  async function handleOnChangeCargo(value, option) {
      formularioCrear.setFieldsValue({ cargo: { nombre: option.children } });
      const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
          getUsuarioAutenticado().empresaSeleccionada.id,
          value,
          CampoColaborador.CARGO
      );
      procesarCambios(campoEnviado);
  }

  async function onSelectSubsede(value, option) {
    const subsede = {
        id: option.value,
        nombre: option.children,
    };
    setSubsedeSeleccionada(subsede);

    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
        getUsuarioAutenticado().empresaSeleccionada.id,
        value,
        CampoColaborador.SUBSEDE
    );
    // console.log(campoEnviado)
    procesarCambios(campoEnviado);
  }

  async function onSelectSeccion(value, option) {
    const seccion = {
        id: option.value,
        nombre: option.children,
    };
    setSeccionSeleccionada(seccion);
    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
        getUsuarioAutenticado().empresaSeleccionada.id,
        value,
        CampoColaborador.SECCION
    );
    procesarCambios(campoEnviado);
  }

  async function onSelectTipoPlanilla(value, option) {
    const tipoPlanilla = {
        id: option.value,
        nombre: option.children,
    };
    setTipoPlanillaSeleccionado(tipoPlanilla);

    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
        getUsuarioAutenticado().empresaSeleccionada.id,
        value,
        CampoColaborador.PLANILLA
    );
    // console.log(campoEnviado)
    procesarCambios(campoEnviado);
  }

  function procesarCambios(campoEnviado) {
    if (campoEnviado.PLANILLA != null) {
        setTipoPlanillas(campoEnviado.PLANILLA);
        formularioCrear.setFieldsValue({ tipoPlanilla: "" });
    }
    if (campoEnviado.CARGO != null) {
        setCargos(campoEnviado.CARGO);
        formularioCrear.setFieldsValue({ cargo: { id: "" } });
    }
    if (campoEnviado.PERFIL != null) {
        setPerfiles(campoEnviado.PERFIL);
        formularioCrear.setFieldsValue({ perfil: "" });
    }
    if (campoEnviado.SECCION != null) {
        setSecciones(campoEnviado.SECCION);
        formularioCrear.setFieldsValue({ seccion: "" });
    }
    if (campoEnviado.SEDE != null) {
        setSedes(campoEnviado.SEDE);
        formularioCrear.setFieldsValue({ sede: { id: "" } });
    }
    if (campoEnviado.SUBSEDE != null) {
        setSubsedes(campoEnviado.SUBSEDE);
        formularioCrear.setFieldsValue({ subsede: "" });
    }
  }

  const onChangeDepartamento = async (value) => {
    const formData = new FormData();
    formData.append("departamento", value);
    const provincias = await UbigeoService.listarProvincias(formData);
    setProvincias(provincias);

    formularioCrear.setFieldsValue({
        domicilioActual: {
            provincia: provincias[0].provincia,
        },
    });

    const formDataDistrito = new FormData();
    formDataDistrito.append("provincia", provincias[0].provincia);
    const distritos = await UbigeoService.listarDistritos(formDataDistrito);
    setDistritos(distritos);

    formularioCrear.setFieldsValue({
        domicilioActual: {
            distrito: distritos[0].distrito,
        },
    });
  };

  const onChangeProvincia = async (value) => {
      const formData = new FormData();
      formData.append("provincia", value);
      const distritos = await UbigeoService.listarDistritos(formData);
      setDistritos(distritos);

      formularioCrear.setFieldsValue({
          domicilioActual: {
              distrito: distritos[0].distrito,
          },
      });
  };

  const onChangeDireccion = ()=>{
    const direccion= formularioCrear.getFieldValue(["domicilioActual","direccion"]).toUpperCase();
    formularioCrear.setFieldsValue({
        domicilioActual:{
            direccion:direccion
        }
    })
  }

  const onSearchColaboradorResponsableContrato = async (value) => {
    const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
        filtro: value.trim(),
        login: getUsuarioAutenticado().login,
    });

    if (value === "") {
        formularioCrear.setFieldsValue({
            responsableRenovarContratoId: "",
        });
        setresponsableRenovarContratoSeleccionado(undefined);
        setresponsablesRenovarContrato([]);
    } else {
        setresponsablesRenovarContrato(respuesta.colaboradores);
    }
  };

  const onSeleccionarResponsableRenovacionContrato = (value) => {
    setresponsableRenovarContratoSeleccionado(value);
  };

  const onSearchAdministradorObra = async (value) => {
    const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
        filtro: value.trim(),
        login: getUsuarioAutenticado().login,
    });

    if (value === "") {
        formularioCrear.setFieldsValue({
            responsableRenovarContratoId: "",
        });
        setAdministradorObraSeleccionado(undefined);
        setAdministradoresObra([]);
    } else {
        setAdministradoresObra(respuesta.colaboradores);
    }
  };

  const onSeleccionarAdministradorObra = (value) => {
    setAdministradorObraSeleccionado(value);
  };

    return(

        <Modal 
          title="Reingresar Colaborador" 
          open={visibleModalReingresoColaborador}
          onOk={onFinishReingresarColaborador}
          onCancel={cerrarModal}
          loading={loading}
          // width="calc(100vw - 60px)"
          // height="calc(100vh - 60px)"
          footer={null}          
         >
           <Form 
            form={formularioCrear}
            layout="vertical"
            onFinish={onFinishReingresarColaborador}
           >
               <Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
                    <Space>
                        <BankOutlined /> Empresa
                    </Space>
                </Divider>
                <Row gutter={16}>
                    {camposColaboradores.length > 0 &&
                        camposColaboradores.map((campoColaborador) => {
                            if (
                                campoColaborador.visible === true &&
                                campoColaborador.campo === CampoColaborador.PERFIL
                            ) {
                                return (
                                    <Col key={campoColaborador.nombre} span={12}>
                                        <Form.Item
                                            label={campoColaborador.etiqueta}
                                            name="perfil"
                                            rules={
                                                campoColaborador.campoObligatorio === true
                                                    ? [
                                                          {
                                                              required: true,
                                                              message: "Seleccione Perfil",
                                                          },
                                                      ]
                                                    : []
                                            }
                                        >
                                            <Select
                                                onSelect={onSelectPerfil}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {perfiles.length > 0 &&
                                                    perfiles.map((perfil) => {
                                                        return (
                                                            <Option key={perfil.id} value={perfil.id}>
                                                                {perfil.nombre}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                );
                            } else if (
                                campoColaborador.visible === true &&
                                campoColaborador.campo === CampoColaborador.SEDE
                            ) {
                                return (
                                    <Col key={campoColaborador.nombre} span={12}>
                                        <Form.Item
                                            name={["sede", "id"]}
                                            label={campoColaborador.etiqueta}
                                            rules={
                                                campoColaborador.campoObligatorio === true
                                                    ? [
                                                          {
                                                              required: true,
                                                              message: `Seleccione ${campoColaborador.etiqueta}`,
                                                          },
                                                      ]
                                                    : []
                                            }
                                        >
                                            <Select onChange={handleOnChangeSede}>
                                                {sedes.length > 0 &&
                                                    sedes.map((sede) => {
                                                        return (
                                                            <Option key={sede.id} value={sede.id}>
                                                                {sede.nombre}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name={["sede", "nombre"]} noStyle>
                                            <Input type="hidden" />
                                        </Form.Item>
                                    </Col>
                                );
                            } else if (
                                campoColaborador.visible === true &&
                                campoColaborador.campo === CampoColaborador.SUBSEDE
                            ) {
                                return (
                                    <Col key={campoColaborador.nombre} span={12}>
                                        <Form.Item
                                            label={campoColaborador.etiqueta}
                                            name="subsede"
                                            rules={
                                                campoColaborador.campoObligatorio === true
                                                    ? [
                                                          {
                                                              required: true,
                                                              message: "Seleccione Subcentro de costo",
                                                          },
                                                      ]
                                                    : []
                                            }
                                        >
                                            <Select
                                                onSelect={onSelectSubsede}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {subsedes.length > 0 &&
                                                    subsedes.map((subsede) => {
                                                        return (
                                                            <Option key={subsede.id} value={subsede.id}>
                                                                {subsede.nombre}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                );
                            } else if (
                                campoColaborador.visible === true &&
                                campoColaborador.campo === CampoColaborador.SECCION
                            ) {
                                return (
                                    <Col key={campoColaborador.nombre} span={12}>
                                        <Form.Item
                                            label={campoColaborador.etiqueta}
                                            name="seccion"
                                            rules={
                                                campoColaborador.campoObligatorio === true
                                                    ? [
                                                          {
                                                              required: true,
                                                              message: "Seleccione Sección",
                                                          },
                                                      ]
                                                    : []
                                            }
                                        >
                                            <Select
                                                onSelect={onSelectSeccion}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {secciones.length > 0 &&
                                                    secciones.map((seccion) => {
                                                        return (
                                                            <Option key={seccion.id} value={seccion.id}>
                                                                {seccion.nombre}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                );
                            } else if (
                                campoColaborador.visible === true &&
                                campoColaborador.campo === CampoColaborador.PLANILLA
                            ) {
                                return (
                                    <Col key={campoColaborador.nombre} span={12}>
                                        <Form.Item
                                            label={campoColaborador.etiqueta}
                                            name="tipoPlanilla"
                                            rules={
                                                campoColaborador.campoObligatorio === true
                                                    ? [
                                                          {
                                                              required: true,
                                                              message: "Seleccione Tipo Planilla",
                                                          },
                                                      ]
                                                    : []
                                            }
                                        >
                                            <Select
                                                onSelect={onSelectTipoPlanilla}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {tipoPlanillas.length > 0 &&
                                                    tipoPlanillas.map((tipoPlanilla) => {
                                                        return (
                                                            <Option key={tipoPlanilla.id} value={tipoPlanilla.id}>
                                                                {tipoPlanilla.nombre}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                );
                            } else if (
                                campoColaborador.visible === true &&
                                campoColaborador.campo === CampoColaborador.CARGO
                            ) {
                                return (
                                    <Col key={campoColaborador.nombre} span={12}>
                                        <Form.Item
                                            name={["cargo", "id"]}
                                            label={campoColaborador.etiqueta}
                                            rules={
                                                campoColaborador.campoObligatorio === true
                                                    ? [
                                                          {
                                                              required: true,
                                                              message: "Seleccione Cargo",
                                                          },
                                                      ]
                                                    : []
                                            }
                                        >
                                            <Select
                                                onChange={handleOnChangeCargo}
                                                showSearch
                                                optionFilterProp="children"
                                            >
                                                {cargos.length > 0 &&
                                                    cargos.map((cargo) => {
                                                        return (
                                                            <Option key={cargo.id} value={cargo.id}>
                                                                {cargo.nombre}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name={["cargo", "nombre"]} noStyle>
                                            <Input type="hidden" />
                                        </Form.Item>
                                    </Col>
                                );
                            }
                        })}
                </Row>
                {getUsuarioAutenticado().empresaSeleccionada?.moduloContratoHabilitado && isExplomin && (
                    <React.Fragment>
                        <Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
                            <Space>
                                <FolderAddOutlined /> Datos Contrato
                            </Space>
                        </Divider>

                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name="estadoCivil"
                                    label="Estado Civil"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        options={[
                                            {
                                                value: "SOLTERO",
                                                label: "SOLTERO",
                                            },
                                            {
                                                value: "CONVIVIENTE",
                                                label: "CONVIVIENTE",
                                            },
                                            {
                                                value: "CASADO",
                                                label: "CASADO",
                                            },
                                            {
                                                value: "DIVORCIADO",
                                                label: "DIVORCIADO",
                                            },
                                            {
                                                value: "VIUDO",
                                                label: "VIUDO",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={["contrato", "sueldo"]}
                                    label="Sueldo"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                 name={["contrato", "bono"]}
                                 label="Bono"
                                >
                                   <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name={["domicilioActual", "departamento"]}
                                    label="Departamento"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={onChangeDepartamento}
                                    >
                                        {departamentos !== undefined &&
                                            departamentos?.map((departamento) => (
                                                <Select.Option key={departamento.nombre} value={departamento.nombre}>
                                                    {departamento.nombre}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Provincia"
                                    name={["domicilioActual", "provincia"]}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={onChangeProvincia}
                                    >
                                        {provincias !== undefined &&
                                            provincias?.map((info) => (
                                                <Select.Option key={info.provincia} value={info.provincia}>
                                                    {info.provincia}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    label="Distrito"
                                    name={["domicilioActual", "distrito"]}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {distritos !== undefined &&
                                            distritos?.map((objeto) => (
                                                <Select.Option key={objeto.distrito} value={objeto.distrito}>
                                                    {objeto.distrito}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name={["domicilioActual", "direccion"]}
                                    label="Direccion"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input onChange={onChangeDireccion}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name={["contrato", "fechaInicioContrato"]}
                                    label="Fecha Inicio Contrato"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={["contrato", "fechaFinContrato"]}
                                    label="Fecha Fin Contrato"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {!ocultarTipoServicio && (
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name={["contrato", "tipoServicio"]}
                                        label="Tipo de Servicio"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            options={[
                                                {
                                                    value: "SERVICIO TECNICO DE MUESTRERIA",
                                                    label: "SERVICIO TECNICO DE MUESTRERIA",
                                                },
                                                {
                                                    value: "PERFORACIÓN DE DIAMANTINA",
                                                    label: "PERFORACIÓN DE DIAMANTINA",
                                                },
                                                {
                                                    value: "PERFORACIÓN DE AIRE REVERSO",
                                                    label: "PERFORACIÓN DE AIRE REVERSO",
                                                },
                                                {
                                                    value: "PERFORACIÓN POZOS DE AGUA",
                                                    label: "PERFORACIÓN POZOS DE AGUA",
                                                },
                                                {
                                                    value: "PERFORACIÓN GEOTECNIA",
                                                    label: "PERFORACIÓN GEOTECNIA",
                                                  },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="responsableRenovarContratoId"
                                    label="Responsable de Renovación de Contrato"
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        onSearch={onSearchColaboradorResponsableContrato}
                                        onChange={onSeleccionarResponsableRenovacionContrato}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {responsablesRenovarContrato !== undefined &&
                                            responsablesRenovarContrato.map((colaborador) => {
                                                return (
                                                    <Select.Option key={colaborador.id} value={colaborador.id}>
                                                        {colaborador.nombreCompleto}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="administradorObraId"
                                    label="Administrador de Obra"
                                    hidden={ocultarAdminObra}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        onSearch={onSearchAdministradorObra}
                                        onChange={onSeleccionarAdministradorObra}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {administradoresObra !== undefined &&
                                            administradoresObra.map((colaborador) => {
                                                return (
                                                    <Select.Option key={colaborador.id} value={colaborador.id}>
                                                        {colaborador.nombreCompleto}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
               <Row justify="center">
                 <Space>
                   <Col span={12}>
                   <Button type="default" onClick={cerrarModal}>Cancelar</Button>
                   </Col>
                   <Col span={12}>
                   <Button type="primary" htmlType="submit">Reingresar colaborador</Button>
                   </Col>
                 </Space>
                </Row>   
               {/* </Form.Item> */}
           </Form>
        </Modal>
    )

}
export default ModalReingreso;
