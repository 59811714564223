import React, { useEffect } from "react";
import { Collapse } from "antd";
import {
  EditOutlined,
  CheckSquareOutlined,
  CheckCircleOutlined,
  HighlightOutlined,
  FileImageOutlined,
  UnorderedListOutlined,
  FontColorsOutlined
} from "@ant-design/icons";
import { ControlPdf } from "./ControlPdf";
import { TipoControl } from "enums";
import { useAtom } from "jotai";
import { paginaPdfAtom, posicionControlEnMovimientoAtom } from "../../DisenarFormularioPdfStore";

const ListaControlesPdf = () => {
  const [paginaPdf] = useAtom(paginaPdfAtom);
  const [posicionControlEnMovimiento, setPosicionControlEnMovimiento] = useAtom(posicionControlEnMovimientoAtom);

  useEffect(() => {
    const left = paginaPdf.ancho;
    const top = paginaPdf.alto;
    setPosicionControlEnMovimiento({ left, top });
  }, [paginaPdf]);

  return (
    <div
      style={{
        width: "15%",
        maxWidth: "15%",
        paddingLeft: "10px",
        paddingTop: "10px",
      }}
    >
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Collapse.Panel header="Controles PDF" key="1" showArrow={false}>
          <ControlPdf
            icono={<EditOutlined />}
            tipo={TipoControl.CAJA_TEXTO}
            nombre="Caja de Texto"
            posicion={posicionControlEnMovimiento}
          />
          <ControlPdf
            icono={<FontColorsOutlined />}
            tipo={TipoControl.ETIQUETA}
            nombre="Etiqueta"
            posicion={posicionControlEnMovimiento}
          />
          <ControlPdf
            icono={<UnorderedListOutlined />}
            tipo={TipoControl.LISTA}
            nombre="Lista Desplegable"
            posicion={posicionControlEnMovimiento}
          />
          <ControlPdf
            icono={<CheckSquareOutlined />}
            tipo={TipoControl.CHECKBOX}
            nombre="Caja Selección Multiple"
            posicion={posicionControlEnMovimiento}
          />
          <ControlPdf
            icono={<CheckCircleOutlined />}
            tipo={TipoControl.RADIO_BUTTON}
            nombre="Caja Selección Única"
            posicion={posicionControlEnMovimiento}
          />
          <ControlPdf
            icono={<FileImageOutlined />}
            tipo={TipoControl.IMAGEN}
            nombre="Imagen"
            posicion={posicionControlEnMovimiento}
          />
          <ControlPdf
            icono={<HighlightOutlined />}
            tipo={TipoControl.SIGNATURE}
            nombre="Firma"
            posicion={posicionControlEnMovimiento}
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default ListaControlesPdf;
