import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "../../../components/Container";
import {
	Input,
	Button,
	Table,
	Space,
	notification,
	Modal,
	Menu,
	Dropdown,
	Tag,
	Spin,
	Card,
	message,
	Typography,
	Alert,
} from "antd";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { CheckCircleFilled, LoadingOutlined, WarningOutlined } from "@ant-design/icons";

import {
	FileExcelOutlined,
	UnorderedListOutlined,
	PlusOutlined,
	SearchOutlined,
	UploadOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
	LockOutlined,
	UnlockOutlined,
	CloseOutlined,
	CheckOutlined,
	ContainerOutlined,
	DownloadOutlined,
	KeyOutlined,
	MailOutlined,
	RollbackOutlined,
	CaretDownOutlined,
	ContactsOutlined,
	UserOutlined,
	FieldNumberOutlined,
	PictureOutlined,
	Loading3QuartersOutlined,
	CopyOutlined
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";

import ColaboradorService from "../../../services/ColaboradorService";
import ModalCargaMasiva from "../carga-masiva/modalCargaMasiva";
import MenuItem from "antd/lib/menu/MenuItem";
import { tipoDocumentoIdentidad } from "../../../enums/TipoDocumentoIdentidad";
import { saveAs } from "file-saver";
import {
	visibleModalRestablecerClaveAtom,
	visibleModalReingresoColaboradorAtom,
	visibleModalExportarFormularioAtom,
	eventoDescargaAtom,
	visibleModalConfigurarOpcionPortalColaboradorAtom
} from "./ColaboradorListarStore";
import { useAtom } from "jotai";
import { ModalExportarFormulario, ModalRestablecerClave } from "../listar/componentes";
import { ModalReingreso } from "../listar/componentes/ModalReingreso";
import { ModalHabilitarOpcionPortalColaborador } from "../listar/componentes/ModalHabilitarOpcionPortalColaborador";
import { visibleModalHistorialCodigosAtom } from "./ColaboradorListarStore";
import { ModalHistorialColaborador } from "./historialColaborador";
import { historialCodigosAtom } from "./ColaboradorListarStore";
import HistorialColaboradorService from "../../../services/HistorialColaboradorService";
import { FiltroBusqueda } from "./componentes/FiltroBusqueda";
import { useParams } from "react-router-dom";
import { EstadoColaboradorTag } from "./componentes/EstadoColaboradorTag";
import { OPCIONES } from "enums";
import { ExportadorService } from "services";

export function ColaboradorListarPage() {
	//const [vinculoLaboral] = useState(searchParams.get("vinculoLaboral"));
	const [colaboradores, setColaboradores] = useState();
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [visibleModal, SetVisibleModal] = useState(false);
	const [, setVisibleModalRestablecerClave] = useAtom(visibleModalRestablecerClaveAtom);
	const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState("");
	const [objetoColaboradorSeleccionado, setObjetoColaboradorSeleccionado] = useState("");
	const [, setVisibleModalHistorialCodigos] = useAtom(visibleModalHistorialCodigosAtom);
	const [, setHistorialCodigos] = useAtom(historialCodigosAtom);
	const [loading, setLoading] = useState(false);
	const [paginaActual, setPaginaActual] = useState(0);
	const [totalPagina, setTotalPagina] = useState(0);
	const [filtros, setFiltros] = useState(undefined);
	const [reset, setReset] = useState(false);
	const [loadingNotificar, setLoadingNotificar] = useState(false);
	const [loadingMigrar, setLoadingMigrar] = useState(false);
	const [loadingExportar, setLoadingExportar] = useState(false);
	const [, setVisibleModalReingresoColaborador] = useAtom(visibleModalReingresoColaboradorAtom);
	const [, setVisibleModalExportarFormulario] = useAtom(visibleModalExportarFormularioAtom);
	const [eventoDescarga, setEventoDescarga] = useAtom(eventoDescargaAtom);

	let history = useHistory();
	const { vinculoLaboral } = useParams();
	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	const opcionesEmpresaSeleccionada = getUsuarioAutenticado().empresaSeleccionada.opciones;
	const [, setVisibleModalConfigurarOpcionPortalColaborador] = useAtom(visibleModalConfigurarOpcionPortalColaboradorAtom);

	async function handleOnOkQuitarAccesoPlataforma(colaboradorId) {
		await ColaboradorService.quitarAccesoPlataforma(colaboradorId);
		setColaboradores(
			colaboradores.map((colaborador) => {
				if (colaborador.id === colaboradorId) {
					return { ...colaborador, tieneAccesoPlataforma: false };
				}
				return colaborador;
			})
		);
		notification.success({
			message: "Operación exitosa",
			description: "Se ha quitado el acceso a la plataforma al colaborador correctamente.",
		});
	}
	async function handleOnOkHabilitarAccesoPlataforma(colaboradorId) {
		await ColaboradorService.habilitarAccesoPlataforma(colaboradorId);
		setColaboradores(
			colaboradores.map((colaborador) => {
				if (colaborador.id === colaboradorId) {
					return { ...colaborador, tieneAccesoPlataforma: true };
				}
				return colaborador;
			})
		);
		notification.success({
			message: "Operación exitosa",
			description: "Se ha habilitado el acceso a la plataforma al colaborador correctamente.",
		});
	}

	async function listarHistorialCambios(idColaborador) {
		const historial = await HistorialColaboradorService.listarHistorialCodigos(
			getUsuarioAutenticado().empresaSeleccionada.id,
			idColaborador
		);
		setHistorialCodigos(historial);
	}
	function handleOnclickMenu({ item, key }) {
		if (key === "1" && item.props.colaborador.tieneAccesoPlataforma) {
			Modal.confirm({
				title: "¿Está seguro de quitar el acceso a la plataforma al colaborador?",
				icon: <ExclamationCircleOutlined />,
				okText: "Si",
				cancelText: "No",
				onOk() {
					handleOnOkQuitarAccesoPlataforma(item.props.colaborador.id);
				},
			});
		} else if (key === "1" && !item.props.colaborador.tieneAccesoPlataforma) {
			Modal.confirm({
				title: "¿Está seguro de habilitar el acceso a la plataforma al colaborador?",
				icon: <ExclamationCircleOutlined />,
				okText: "Si",
				cancelText: "No",
				onOk() {
					handleOnOkHabilitarAccesoPlataforma(item.props.colaborador.id);
				},
			});
		} else if (
			key === "2" &&
			(item.props.colaborador.estadoRegistro === "ACTIVO" || item.props.colaborador.estadoRegistro === null)
		) {
			Modal.confirm({
				title: "¿Está seguro de cesar al colaborador seleccionado?",
				content: (
					<div style={{ display: "flex", alignItems: "flex-start" }}>
						<WarningOutlined style={{ fontSize: "20px", color: "#dc3545" }} />
						<Typography.Text style={{ marginLeft: "8px", wordWrap: "break-word" }}>
							Se borrará toda la informacion del colaborador.
						</Typography.Text>
					</div>
				),
				icon: <ExclamationCircleOutlined />,
				okText: "si",
				cancelText: "No",
				onOk() {
					handleOnOkDesactivarColaborador(item.props.colaborador.id);
				},
			});
		} else if (key === "2" && item.props.colaborador.estadoRegistro === "CESADO") {
			Modal.confirm({
				title: "Está seguro de activar al colaborador seleccionado?",
				icon: <ExclamationCircleOutlined />,
				okText: "si",
				cancelText: "No",
				onOk() {
					handleOnOkActivarColaborador(item.props.colaborador.id);
				},
			});
		} else if (key === "4") {
			setVisibleModalRestablecerClave(true);
			setColaboradorSeleccionado(item.props.colaborador.numeroDocumentoIdentidad);
		} else if (key === "5") {
			listarHistorialCambios(item.props.colaborador.id);
			setVisibleModalHistorialCodigos(true);
		} else if (key === "6") {
			Modal.confirm({
				title: "Está seguro de eliminar al colaborador seleccionado?",
				icon: <ExclamationCircleOutlined />,
				okText: "si",
				cancelText: "No",
				onOk() {
					handleOnOkEliminarColaborador(item.props.colaborador.id);
				},
			});
		} else if (key === "7") {
			setVisibleModalReingresoColaborador(true);
			//setVisibleModalRestablecerClave(true);
			setColaboradorSeleccionado(item.props.colaborador.numeroDocumentoIdentidad);
		} else if (key === "8") {
			setVisibleModalConfigurarOpcionPortalColaborador(true);
			//setVisibleModalRestablecerClave(true);
			setColaboradorSeleccionado(item.props.colaborador.numeroDocumentoIdentidad);
			setObjetoColaboradorSeleccionado(item.props.colaborador);
		}
	}

	const menu = (colaborador) => (
		<Menu onClick={handleOnclickMenu}>
			{colaborador.tieneAccesoPlataforma ? (
				<Menu.Item key="1" colaborador={colaborador}>
					<LockOutlined /> Quitar Acceso a la Plataforma
				</Menu.Item>
			) : (
				<Menu.Item key="1" colaborador={colaborador}>
					<UnlockOutlined /> Habilitar Acceso a la Plataforma
				</Menu.Item>
			)}
			{colaborador.estadoRegistro === "ACTIVO" || colaborador.estadoRegistro === null ? (
				<Menu.Item key="2" colaborador={colaborador}>
					<CloseOutlined /> Cesar
				</Menu.Item>
			) : (
				<Menu.Item key="2" colaborador={colaborador}>
					<CheckOutlined /> Activar
				</Menu.Item>
			)}
			<MenuItem key="3" colaborador={colaborador}>
				<ContainerOutlined /> Legajo
			</MenuItem>
			<MenuItem key="4" colaborador={colaborador}>
				<KeyOutlined /> Restablecer Clave
			</MenuItem>
			{
				<MenuItem key="5" colaborador={colaborador}>
					<KeyOutlined /> Ver histórico de cambios
				</MenuItem>
			}
			{isAceros && (
				<MenuItem key="6" colaborador={colaborador}>
					<CloseOutlined /> Eliminar colaborador
				</MenuItem>
			)}
			{isExplomin && colaborador?.estadoRegistro === "CESADO" && (
				<MenuItem key="7" colaborador={colaborador}>
					<RollbackOutlined /> Reingresar colaborador
				</MenuItem>
			)}
			{isExplomin && (
				<MenuItem key="8" colaborador={colaborador}>
					<CopyOutlined /> Habilitar opción Portal Colaborador
				</MenuItem>
			)}
		</Menu>
	);

	let columnas = [
		{
			title: "Tipo de Documento",
			dataIndex: "tipoDocumentoIdentidad",
			width: 120,
			align: "center",
			render: (text, fila) => (
				<React.Fragment>
					{fila.tipoDocumentoIdentidad == "CARNET_EXTRANJERIA" &&
						fila.tipoDocumentoIdentidad?.replace("CARNET_EXTRANJERIA", "CE")}
					{fila.tipoDocumentoIdentidad == "OTRO_DOCUMENTO" &&
						fila.tipoDocumentoIdentidad?.replace("OTRO_DOCUMENTO", "OD")}
					{fila.tipoDocumentoIdentidad != "OTRO_DOCUMENTO" &&
						fila.tipoDocumentoIdentidad != "OTRO_DOCUMENTO" &&
						fila.tipoDocumentoIdentidad}
				</React.Fragment>
			),
		},
		{
			title: "Documento",
			dataIndex: "numeroDocumentoIdentidad",
			align: "right",
			width: "5%",
		},
		{
			title: "Apellidos y Nombres",
			dataIndex: "nombreCompleto",
			render: (text, fila) => (
				<Button type="text" onClick={() => onClickLegajoColaborador(fila.id)}>
					{fila.nombreCompleto}
				</Button>
			),
		},
		{
			title: "Código",
			dataIndex: "codigoTrabajador",
			align: "right",
			width: "5%",
		},
		{
			title: "Correo Electrónico",
			dataIndex: "correoElectronico",
			render: (text, fila) => (
				<React.Fragment>
					{fila.correoElectronico !== null && fila.correoElectronicoVerificado === fila.correoElectronico ? (
						<span>
							{fila.correoElectronico}
							{"  "}
							<CheckCircleFilled style={{ fontSize: "20px", color: "#68BA39" }} />
						</span>
					) : (
						<>
							<div>{fila.correoElectronico}</div>
							<div>{fila.correoElectronicoAlternativo}</div>
						</>
					)}
				</React.Fragment>
			),
		},
		{
			title: "Acceso",
			dataIndex: "tieneAccesoPlataforma",
			align: "center",
			width: "10%",
			//...getColumnSearchProps("estadoAccesoPlataforma"),
			render: (text, fila) => (
				<React.Fragment>
					{fila.tieneAccesoPlataforma ? <Tag color="green">SI</Tag> : <Tag color="red">NO</Tag>}
				</React.Fragment>
			),
		},
		{
			title: "Estado",
			dataIndex: "estadoRegistro",
			align: "center",
			width: "10%",
			render: (_, fila) => <EstadoColaboradorTag estado={fila.estadoRegistro} />,
		},
		{
			title: "Acciones",
			align: "center",
			align: "center",
			render: (text, fila) => (
				<Space size={8}>
					<a onClick={() => handleOnClickEditarColaborador(fila.id)} className="custom-color-element">
						<EditOutlined /> Editar
					</a>
					<Dropdown overlay={() => menu(fila)}>
						<a className="custom-color-element" onClick={(e) => e.preventDefault()}>
							<UnorderedListOutlined /> Otros
						</a>
					</Dropdown>
				</Space>
			),
		},
	];

	if (isExplomin) {
		columnas = [
			{
				title: "Colaborador",
				width: "25%",
				render: (text, fila) => (
					<>
						<div>
							<Button
								type="text"
								style={{ padding: "0px", paddingInline: "0px" }}
								onClick={() => onClickLegajoColaborador(fila.id)}
							>
								<Space>
									<UserOutlined style={{ fontSize: "18px" }} />
									<span style={{ borderBottom: "1px dashed #607D8B" }}>{fila.nombreCompleto}</span>
								</Space>
							</Button>
						</div>
						<div>
							{fila.tipoDocumentoIdentidad == "CARNET_EXTRANJERIA" && (
								<Space>
									<ContactsOutlined style={{ fontSize: "18px" }} />
									{fila.tipoDocumentoIdentidad?.replace("CARNET_EXTRANJERIA", "CE")}:
									{fila.numeroDocumentoIdentidad}
								</Space>
							)}
							{fila.tipoDocumentoIdentidad == "OTRO_DOCUMENTO" && (
								<Space>
									<ContactsOutlined style={{ fontSize: "18px" }} />
									{fila.tipoDocumentoIdentidad?.replace("OTRO_DOCUMENTO", "OD")}:
									{fila.numeroDocumentoIdentidad}
								</Space>
							)}
							{fila.tipoDocumentoIdentidad != "OTRO_DOCUMENTO" &&
								fila.tipoDocumentoIdentidad != "CARNET_EXTRANJERIA" && (
									<Space>
										<ContactsOutlined style={{ fontSize: "18px" }} />
										<span>
											{fila.tipoDocumentoIdentidad}: {fila.numeroDocumentoIdentidad}
										</span>
									</Space>
								)}
						</div>
						<div>
							<Space>
								<FieldNumberOutlined style={{ fontSize: "18px" }} />
								Código : {fila.codigoTrabajador}
							</Space>
						</div>
					</>
				),
			},
			// {
			// 	title: "Tipo de Documento",
			// 	dataIndex: "tipoDocumentoIdentidad",
			// 	width: 120,
			// 	align: "center",
			// 	render: (text, fila) => (
			// 		<React.Fragment>
			// 			{fila.tipoDocumentoIdentidad == "CARNET_EXTRANJERIA" &&
			// 				fila.tipoDocumentoIdentidad?.replace("CARNET_EXTRANJERIA", "CE")}
			// 			{fila.tipoDocumentoIdentidad == "OTRO_DOCUMENTO" &&
			// 				fila.tipoDocumentoIdentidad?.replace("OTRO_DOCUMENTO", "OD")}
			// 			{fila.tipoDocumentoIdentidad != "OTRO_DOCUMENTO" &&
			// 				fila.tipoDocumentoIdentidad != "OTRO_DOCUMENTO" &&
			// 				fila.tipoDocumentoIdentidad}
			// 		</React.Fragment>
			// 	),
			// },
			// {
			// 	title: "Documento",
			// 	dataIndex: "numeroDocumentoIdentidad",
			// 	align: "right",
			// 	width: "5%",
			// },
			// {
			// 	title: "Apellidos y Nombres",
			// 	dataIndex: "nombreCompleto",
			// 	render: (text, fila) => (
			// 		<Button type="text" onClick={() => onClickLegajoColaborador(fila.id)}>
			// 			{fila.nombreCompleto}
			// 		</Button>
			// 	),
			// },

			{
				title: "Correo Electrónico",
				dataIndex: "correoElectronico",
				render: (text, fila) => (
					<React.Fragment>
						{fila.correoElectronico !== null &&
							fila.correoElectronicoVerificado === fila.correoElectronico ? (
							<span>
								{fila.correoElectronico}
								{"  "}
								<CheckCircleFilled style={{ fontSize: "20px", color: "#68BA39" }} />
							</span>
						) : (
							fila.correoElectronico
						)}
					</React.Fragment>
				),
			},
			{
				title: "Proyecto",
				dataIndex: ["seccion", "nombre"],
				width: "15%",
			},
			{
				title: "Reclutador",
				dataIndex: "usuarioRegistro",
				width: "20%",
			},
			{
				title: "Acceso",
				dataIndex: "tieneAccesoPlataforma",
				align: "center",
				width: "5%",
				//...getColumnSearchProps("estadoAccesoPlataforma"),
				render: (text, fila) => (
					<React.Fragment>
						{fila.tieneAccesoPlataforma ? <Tag color="green">SI</Tag> : <Tag color="red">NO</Tag>}
					</React.Fragment>
				),
			},
			{
				title: "Estado",
				dataIndex: "estadoRegistro",
				align: "center",
				width: "5%",
				render: (_, fila) => <EstadoColaboradorTag estado={fila.estadoRegistro} />,
			},
			{
				title: "Acciones",
				align: "center",
				width: "5%",
				render: (text, fila) => (
					<Space size={8}>
						<a onClick={() => handleOnClickEditarColaborador(fila.id)} className="custom-color-element">
							<EditOutlined /> Editar
						</a>
						<Dropdown overlay={() => menu(fila)}>
							<a className="custom-color-element" onClick={(e) => e.preventDefault()}>
								<UnorderedListOutlined /> Otros
							</a>
						</Dropdown>
					</Space>
				),
			},
		];
	}

	async function actualizarData(pagina) {
		//console.log("vinculo", vinculoLaboral);

		setLoading(true);

		const _accesoPlataforma = filtros["accesoPlataforma"]?.map((p) => {
			return p.replace("SI", true).replace("NO", false);
		});
		const _tipoDocumento = filtros["tipoDocumento"]?.map((p) => {
			return p.replace("Carnet de Extranjería", "CARNET_EXTRANJERIA").replace("Pasaporte", "PASAPORTE");
		});
		const _colaboradores = await ColaboradorService.listarPaginado(
			"TRABAJADOR",
			getUsuarioAutenticado().empresaSeleccionada.id,
			_accesoPlataforma,
			filtros["estado"],
			_tipoDocumento,
			filtros["colaborador"],
			filtros["documentoIdentidad"],
			filtros["codigoColaborador"],
			filtros["centroCosto"],
			filtros["proyecto"],
			filtros["reclutador"],
			filtros["fechaIngreso"],
			getUsuarioAutenticado().login,
			pagina
		);
		setColaboradores(_colaboradores.content);
		setTotalPagina(_colaboradores.totalElements);
		setReset(false);
		if (pagina === undefined) {
			setPaginaActual(1);
		}

		setLoading(false);
	}

	useEffect(() => {
		if (filtros != undefined) {
			actualizarData();
		}
	}, [filtros]);

	function handleOnClickCrearColaborador() {
		history.push(`/personal/trabajador/crear`);
	}

	function handleOnClickEditarColaborador(idColaborador) {
		history.push(`/personal/trabajador/editar/${idColaborador}`);
	}

	function handleOnClickCargaMasivaColaborador() {
		//history.push("/colaborador/carga-masiva");
		SetVisibleModal(true);
	}
	async function cerrarModal() {
		SetVisibleModal(false);
		setReset(true);
		setFiltros({});
	}

	async function handleOnOkDesactivarColaborador(colaboradorId) {
		await ColaboradorService.desactivarColaborador(colaboradorId);
		setColaboradores(
			colaboradores.map((colaborador) => {
				if (colaborador.id === colaboradorId) {
					return { ...colaborador, estadoRegistro: "CESADO" };
				}
				return colaborador;
			})
		);
		notification.success({
			message: "Operación exitosa",
			description: "Se ha desactivado el registro.",
		});
	}

	async function handleOnOkActivarColaborador(colaboradorId) {
		await ColaboradorService.activarColaborador(colaboradorId);
		setColaboradores(
			colaboradores.map((colaborador) => {
				if (colaborador.id === colaboradorId) {
					return { ...colaborador, estadoRegistro: "ACTIVO" };
				}
				return colaborador;
			})
		);
		notification.success({
			message: "Operación exitosa",
			description: "Se ha activado el registro.",
		});
	}
	async function handleOnOkEliminarColaborador(colaboradorId) {
		let respuesta = await ColaboradorService.eliminarColaborador(colaboradorId);
		console.log(respuesta);
		if (respuesta) {
			setColaboradores(colaboradores.filter((item) => item.id !== colaboradorId));
			notification.success({
				message: "Operación exitosa",
				description: "Se ha eliminado el registro.",
			});
		} else {
			notification.warning({
				message: "No se pude realizar la operación",
				description: "El colaborador tiene documentos asociados.",
			});
		}
	}

	async function handleOnclickExportarDatos() {
		setLoadingExportar(true);
		try {
			const _accesoPlataforma = filtros["accesoPlataforma"]?.map((p) => {
				return p.replace("SI", true).replace("NO", false);
			});
			const _tipoDocumento = filtros["tipoDocumento"]?.map((p) => {
				return p.replace("Carnet de Extranjería", "CARNET_EXTRANJERIA").replace("Pasaporte", "PASAPORTE");
			});

			let mostrarSueldo = false;
			if (opcionesEmpresaSeleccionada && opcionesEmpresaSeleccionada.includes(OPCIONES.PAGINA_MANTENIMIENTO_COLABORADOR.TXT_REPORTE_VER_SUELDO)) {
				mostrarSueldo = true;
			}
			let respuesta = await ColaboradorService.descargarExcel(
				getUsuarioAutenticado().empresaSeleccionada.id,
				_accesoPlataforma,
				filtros["estado"],
				_tipoDocumento,
				filtros["colaborador"],
				filtros["documentoIdentidad"],
				filtros["codigoColaborador"],
				filtros["centroCosto"],
				filtros["fechaIngreso"],
				getUsuarioAutenticado().login,
				mostrarSueldo
			);
			setLoadingExportar(false);
			const filename = respuesta.headers.filename;
			saveAs(respuesta.data, filename);
		} catch (error) {
			setLoadingExportar(false);
			console.log(error);
			notification.error({
				message: "No hay colaboradores para exportar",
				description: error.response.data.mensaje,
			});
		}
	}

	function onChangePagination(page) {
		setPaginaActual(page);
		actualizarData(page - 1);
	}

	const onClickLegajoColaborador = (colaboradorId) => {
		history.push(`/v3/legajos/${colaboradorId}`);
	};

	const handleOnClickNotificarMensajeBienvenida = async () => {
		try {
			setLoadingNotificar(true);
			const respuesta = await ColaboradorService.notificarMensajeBienvenida();
			setLoadingNotificar(false);
			if (respuesta) {
				notification.success({
					message: "Notificación",
					description: "Colaboradores notificados correctamente",
				});
			} else
				notification.error({
					message: "Notificación",
					description: "No hay Colaboradores notificados",
				});
		} catch (error) {
			setLoadingNotificar(false);
		}
	};

	const handleOnclickExportarSeguimientoOnboarding = async () => {
		try {
			console.log("somos");
			// setLoadingNotificar(true);
			// const respuesta = await ColaboradorService.notificarMensajeBienvenida();
			// setLoadingNotificar(false);
			// if (respuesta ){
			//     notification.success({
			//         message:"Notificación",
			//         description:"Colaboradores notificados correctamente"
			//     })
			// }
			// else
			// notification.error({
			//     message:"Notificación",
			//     description:"No hay Colaboradores notificados"
			// })
		} catch (error) {
			setLoadingNotificar(false);
		}
	};

	const handleOnClickMigrarExplomin = async () => {
		console.log("ingrese");
		try {
			setLoadingMigrar(true);
			const respuesta = await ColaboradorService.procesarMigracion();
			setLoadingMigrar(false);
			if (respuesta) {
				notification.success({
					message: "Migración",
					description: "Migración realizada correctamente",
				});
			} else
				notification.error({
					message: "Notificación",
					description: "No se pudo realizar la migración",
				});
		} catch (error) {
			setLoadingMigrar(false);
		}
	};

	const onClickBtnReportes = (e) => {
		if (e.key === "exportar-formulario") {
			setVisibleModalExportarFormulario(true);
		} else if (e.key === "descargar-fotos") {
			console.log("descargando fotos....")
			descargarFotos();
		}
	};

	const descargarFotos = async () => {
		setEventoDescarga({ estado: 'loading', mensaje: 'Se está procesando la descarga de fotos. Espere un momento...' });
		const accesoPlataforma = filtros["accesoPlataforma"]?.map((p) => {
			return p.replace("SI", true).replace("NO", false);
		});
		const tipoDocumento = filtros["tipoDocumento"]?.map((p) => {
			return p.replace("Carnet de Extranjería", "CARNET_EXTRANJERIA").replace("Pasaporte", "PASAPORTE");
		});
		// const _colaboradores = await ColaboradorService.listarPaginado(
		// 	"TRABAJADOR",
		// 	getUsuarioAutenticado().empresaSeleccionada.id,
		// 	_accesoPlataforma,
		// 	filtros["estado"],
		// 	_tipoDocumento,
		// 	filtros["colaborador"],
		// 	filtros["documentoIdentidad"],
		// 	filtros["codigoColaborador"],
		// 	filtros["centroCosto"],
		// 	filtros["proyecto"],
		// 	filtros["reclutador"],
		// 	filtros["fechaIngreso"],
		// 	getUsuarioAutenticado().login,
		// 	pagina
		// );

		// accesoPlataforma: accesoPlataforma,
		// estado: estado,
		// tipoDocumento: tipoDocumento,
		// colaborador: colaborador,
		// documentoIdentidad: documentoIdentidad,
		// codigoColaborador: codigoColaborador,
		// centroCosto: centroCosto,
		// fechaIngreso:fechaIngreso,
		// login: login,
		// mostrarSueldo: mostrarSueldo
		ExportadorService
			.descargarFotos({
				accesoPlataforma: accesoPlataforma,
				estado: filtros["estado"],
				tipoDocumento: tipoDocumento,
				colaborador: filtros["colaborador"],
				documentoIdentidad: filtros["documentoIdentidad"],
				codigoColaborador: filtros["codigoColaborador"],
				centroCosto: filtros["centroCosto"],
				fechaIngreso: filtros["fechaIngreso"],
				proyecto: filtros["proyecto"],
				reclutador: filtros["reclutador"],
				login: getUsuarioAutenticado().login
			})
			.then((response) => {
				console.log("response descarga fotos....", response)
				setEventoDescarga({ estado: undefined });
				saveAs(response.url);
			})
			.catch((error) => {
				console.error("error al descargar fotos..", error);
				setEventoDescarga({ estado: undefined });
			})
	}

	return (
		<Container
			icono={<UnorderedListOutlined />}
			titulo="Colaborador"
			botonExtra={
				<Space>
					<Dropdown.Button
						menu={{
							items: [
								{
									label: "Exportar Formulario",
									key: "exportar-formulario",
									icon: <FileExcelOutlined style={{ fontSize: "18px" }} />,
								},
								{
									label: "Descargar Fotos",
									key: "descargar-fotos",
									icon: <PictureOutlined style={{ fontSize: "18px" }} />,
								},
							],
							onClick: onClickBtnReportes,
						}}
						icon={<CaretDownOutlined />}
						trigger={["click"]}
						arrow
					>
						Descargar
					</Dropdown.Button>
					<Button icon={<UploadOutlined />} onClick={handleOnClickCargaMasivaColaborador}>
						Importar
					</Button>

					<Button loading={loadingExportar} icon={<DownloadOutlined />} onClick={handleOnclickExportarDatos}>
						Exportar
					</Button>

					<Button type="primary" icon={<PlusOutlined />} onClick={handleOnClickCrearColaborador}>
						Crear Colaborador
					</Button>
				</Space>
			}
		>
			<Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} />} spinning={loading} tip="Cargando...">

				{eventoDescarga && eventoDescarga.estado === "loading" &&
					<Alert message={eventoDescarga?.mensaje} type="info" showIcon icon={<Loading3QuartersOutlined spin />} />
				}
				<FiltroBusqueda setFiltros={setFiltros} filtro={filtros} reset={reset} filtroActual={filtros} />
				<Table
					rowKey={(fila) => fila.id}
					columns={columnas}
					dataSource={colaboradores}
					//title={()=>}
					pagination={{
						pageSize: [10],
						total: totalPagina,
						current: paginaActual,
						showTotal: (total) => `Total ${total} colaboradores`,
						onChange: (page, pageSize) => onChangePagination(page, pageSize),
					}}
					style={{
						marginTop: "10px"
					}}
				/>
				{/* </Card> */}
			</Spin>

			<ModalCargaMasiva visibleModal={visibleModal} onCerrarModal={cerrarModal} />
			<ModalRestablecerClave colaborador={colaboradorSeleccionado} />
			<ModalHistorialColaborador colaborador={colaboradorSeleccionado} />
			<ModalReingreso colaboradorSeleccionado={colaboradorSeleccionado} setColaboradores={setColaboradores} colaboradores={colaboradores} />
			<ModalHabilitarOpcionPortalColaborador objetoColaboradorSeleccionado={objetoColaboradorSeleccionado} colaboradorSeleccionado={colaboradorSeleccionado} setColaboradores={setColaboradores} colaboradores={colaboradores} />
			<ModalExportarFormulario />
		</Container>
	);
}
