import React from "react";

import { PlantillaWeb } from "../pages/plantilla-web";

import { Route, Switch, BrowserRouter } from "react-router-dom";
import { ColaboradorListarPage } from "../pages/colaborador/listar";
import { ColaboradorCrearPage } from "../pages/colaborador/crear";
import { ColaboradorCargaMasivaPage } from "../pages/colaborador/carga-masiva";
import { SedeListarPage } from "../pages/sede/listar";
import { SedeCrearPage } from "../pages/sede/crear";
import { SedeEditarPage } from "../pages/sede/editar";
import { ColaboradorEditarPage } from "../pages/colaborador/editar";
import { CargoListarPage } from "../pages/cargo/listar";
import { CargoCrearPage } from "../pages/cargo/crear";
import { CargoEditarPage } from "../pages/cargo/editar";
import { DashboardPage } from "../pages/dashboard";
import { DocumentoLaboralListarPage } from "../pages/documento-laboral/listar";
import { DocumentoLaboralListarDetallePage } from "../pages/documento-laboral/litarDetalle";
import { LegajoListarPage } from "../pages/legajo/listar";
import { LegajoListarDocumento } from "../pages/legajo/listar-documento";
import { LegajoCrearDocumento } from "../pages/legajo/crear-documento";
import { ListarDetalleEditarPage } from "../pages/documento-laboral/litarDetalle/editar";
import { CambiarClavePage } from "../pages/cuenta/cambiar-clave";
import { ActualizarClavePage } from "../pages/cuenta/actualizar-clave";
import { DocumentoPersonalListarDetallePage } from "../pages/documento-personal/listarDetalle";
import { DocumentoLaboralPorAprobarListarPage } from "../pages/documento-laboral-porAprobar/listar";
import { DocumentoLaboralPorAprobarListarDetallePage } from "../pages/documento-laboral-porAprobar/listarDetalle";
import { DocumentoLaboralEnvioNuevoPage } from "../pages/documento-laboral/envio/indexNuevo";
import { ListarDetalleNotificacionPage } from "../pages/documento-laboral/envio/listarDetalleNotificacion";
import { GenerarClavePage } from "../pages/cuenta/generar-clave";
import { UsuarioListarPage } from "../pages/usuario/listar";
import { RolCrearPage } from "../pages/rol/crear";
import { RolListarPage } from "../pages/rol/listar";
import { RolEditarPage } from "../pages/rol/editar";
import { EliminarDatosPage } from "../pages/eliminarDatos";
import { PlantillaContratoCrearPage } from "../pages/contrato/plantillaContrato/crear";
import { PlantillaContratoListarPage } from "../pages/contrato/plantillaContrato/listar";
import { PlantillaContratoEditarPage } from "../pages/contrato/plantillaContrato/editar";
import { ListarEnvioContratoPage } from "../pages/contrato/envio/listarEnvioContrato";
import { CertificadosDigitalesListarPage } from "../pages/certificados-digitales/listar";
import { ComunicadosCrearPage } from "../pages/notificacion/comunicado/notificar";
import { DetalleSeguimientoContratoPage } from "../pages/contrato/seguimiento-detalle/index";
import { ContratoCrearPage } from "pages/contrato/crear";
import { MisCertificadosListarPage } from "../pages/mis-certificados/listar";
import { SeguimientoContratoPage } from "../pages/contrato/seguimiento";
import { ContratoListarDetallePage } from "../pages/contrato/Detalle";
import {
    FirmaDocumentoGenericoCrearPage,
    FirmaDocumentoGenericoListarPage,
    FirmaDocumentoGenericoMisDocumentosPage,
} from "pages/firma-documento-generico";
import {
    CrearFormulariosPage,
    ListarFormulariosPage,
    EditarFormulariosPage,
    DisenarFormularioPdfPage,
} from "pages/configuracion/formularios";
import { SeguimientoFormulariosPage } from "pages/notificacion/formulario/seguimiento";
import { NotificarFormularioPage } from "pages/notificacion/formulario/notificar";
import {
    ListarTipoDocumentosPage,
    CrearTipoDocumentosPage,
    EditarTipoDocumentoPage,
} from "pages/configuracion/tipo-documentos";
import { DetalleSeguimientoFormulariosPage } from "../pages/notificacion/formulario/seguimiento-detalle/index";
import {
    ListarCarpetasLegajosPage,
    CrearCarpetasLegajosPage,
    EditarCarpetaLegajoPage,
} from "pages/configuracion/carpetas-legajos";
import { ConsultarLegajoColaboradorPage } from "pages/legajo/consultas";
import { ListarFirmaHolografaPage } from "pages/configuracion/firmas-holografas";
import { CrearFirmaHolografaPage } from "pages/configuracion/firmas-holografas";

import { ListarSeguimiento } from "pages/seguimiento/listar";
import { ListarDetalleSeguimiento } from "pages/detalle-seguimiento/listar";

import { ListarPlantillaEnvioDocumentosPage } from "pages/configuracion/plantilla-envio-documentos";
import {
    CrearPlantillaEnvioDocumentosPage,
    EditarPlantillaEnvioDocumentosPage,
} from "pages/configuracion/plantilla-envio-documentos";
import { ConsultaDocumentoPage } from "pages/consulta-documento";
import { CrearSeccionPage, ListarSeccionPage, EditarSeccionPage } from "pages/seccion";
import { CrearSubsedePage, ListarSubsedePage, EditarSubsedePage } from "pages/subsede";
import { CrearPerfilPage, ListarPerfilPage, EditarPerfilPage } from "pages/perfil";
import { CrearTipoPlanillaPage, ListarTipoPlanillaPage, EditarTipoPlanillaPage } from "pages/tipo-planilla";
import { UsuarioEditarPage, UsuarioCrearPage } from "pages/usuario";
import { NotificacionPorCompletar } from "pages/notificacion-por-completar/listar";
import { ListarNotificacionCompletarDetalle } from "pages/notificacion-por-completar-detalle";
import {
    ListarVerificarDatosColaboradorPage,
    VerificarDatosColaboradorPage,
} from "pages/configuracion/verificación-datos-colaborador";
import { ListarConsultasColaboradorPage } from "pages/consulta-mensajes-colaborador";
import { OnboardingIndividualPage } from "pages/configuracion/onboarding";
import { ConsultaOnboarding } from "pages/consultar/consulta-onboarding";
import { SeguimientoOnboarding, DetalleSeguimientoOnboarding } from "pages/consultar/onboarding";
import { ListarReportesPage } from "pages/reportes";
import { NotificarEvaluacionPage } from "pages/notificacion/evaluacion/notificar";
import { CrearTipoAbsentismoPage, ListarTipoAbsentismoPage, EditarTipoAbsentismoPage } from "pages/tipoAbsentismo";
import { ConsultaVacacionesPage } from "pages/vacaciones/consulta-vacaciones";
import { ControlVacacionalPage, SolicitudesAusenciaPage } from "pages/vacaciones";
import { SeguimientoEvaluacionPage } from "pages/notificacion/evaluacion/seguimiento";
import { SeguimientoContratosPage } from "pages/contratos-vencidos";
import { RutasReclutamientoSeleccion } from "./RutasReclutamientoSeleccion";
import { DescargaMasivaListarPage, DescargaMasivaCrearPage } from "pages/consultar/descargamasiva";
import { PostulantesListarPage } from "pages/seleccion";
import { PostulanteCrearPage } from "pages/seleccion/postulantes/crear/PostulanteCrearPage";
import { PostulanteEditarPage } from "pages/seleccion/postulantes/editar/PostulanteEditarPage";
import { LegajoColaboradorPage } from "pages/legajo/legajoV3";
import { SeguimientoRenovacionContratoPage } from "pages/contrato";
import { BandejaAprobacionAdministracionPersonalPage } from "pages/personalizacion/explomin";
import { SeguimientoContratosAprobadosExplominPage } from "pages/personalizacion/explomin";
import BandejaAprobacionReclutamientoYSeleccionPage from "pages/personalizacion/explomin/bandeja-aprobacion-reclutamiento-y-seleccion/BandejaAprobacionReclutamientoYSeleccionPage";
import { LegajoCargaPage } from "pages/legajo/carga";

export function RutasApp() {
    return (
        <BrowserRouter>
            <PlantillaWeb>
                <Switch>
                    <Route exact path="/dashboard" component={DashboardPage} />

                    <Route exact path="/personal/trabajador/editar/:idColaborador" component={ColaboradorEditarPage} />
                    <Route exact path="/personal/trabajador/crear" component={ColaboradorCrearPage} />
                    <Route exact path="/personal/trabajador" component={ColaboradorListarPage} />
                    <Route exact path="/personal/postulante" component={PostulantesListarPage} />
                    <Route exact path="/personal/postulante/crear" component={PostulanteCrearPage} />
                    <Route exact path="/personal/postulante/editar/:postulanteId" component={PostulanteEditarPage} />
                    {/* <Route exact path="/colaborador/crear" component={ColaboradorCrearPage} /> */}
                    {/* <Route exact path="/colaborador/editar/:idColaborador" component={ColaboradorEditarPage} /> */}
                    <Route exact path="/colaborador/carga-masiva" component={ColaboradorCargaMasivaPage} />

                    <Route exact path="/cargo" component={CargoListarPage} />
                    <Route exact path="/cargo/crear" component={CargoCrearPage} />
                    <Route exact path="/cargo/editar/:idCargo" component={CargoEditarPage} />

                    <Route exact path="/sede" component={SedeListarPage} />
                    <Route exact path="/sede/crear" component={SedeCrearPage} />
                    <Route exact path="/sede/editar/:idSede" component={SedeEditarPage} />

                    <Route exact path="/seccion/crear" component={CrearSeccionPage} />
                    <Route exact path="/seccion" component={ListarSeccionPage} />
                    <Route exact path="/seccion/editar/:idSeccion" component={EditarSeccionPage} />

                    <Route exact path="/subsede/crear" component={CrearSubsedePage} />
                    <Route exact path="/subsede" component={ListarSubsedePage} />
                    <Route exact path="/subsede/editar/:idSubsede" component={EditarSubsedePage} />

                    <Route exact path="/perfil/crear" component={CrearPerfilPage} />
                    <Route exact path="/perfil" component={ListarPerfilPage} />
                    <Route exact path="/perfil/editar/:idPerfil" component={EditarPerfilPage} />

                    <Route exact path="/tipoPlanilla/crear" component={CrearTipoPlanillaPage} />
                    <Route exact path="/tipoPlanilla" component={ListarTipoPlanillaPage} />
                    <Route exact path="/tipoPlanilla/editar/:idTipoPlanilla" component={EditarTipoPlanillaPage} />

                    <Route exact path="/documento-laboral/envio" component={DocumentoLaboralEnvioNuevoPage} />
                    <Route exact path="/documento-laboral" component={DocumentoLaboralListarPage} />

                    <Route
                        exact
                        path="/documento-laboral/listar-detalle"
                        component={DocumentoLaboralListarDetallePage}
                    />
                    <Route exact path="/listar-detalle/editar/:detalleEnvioId" component={ListarDetalleEditarPage} />

                    <Route exact path="/contrato/crear" component={ContratoCrearPage} />

                    <Route exact path="/contrato/seguimiento" component={SeguimientoContratoPage} />

                    <Route
                        exact
                        path="/contrato/seguimiento/:envioId/detalle"
                        component={DetalleSeguimientoContratoPage}
                    />

                    <Route exact path="/contrato/listarContratoDetalle" component={ContratoListarDetallePage} />

                    <Route exact path="/legajos" component={LegajoListarPage} />
                    <Route exact path="/v2/legajos" component={ConsultarLegajoColaboradorPage} />
                    <Route exact path="/legajos/documento" component={LegajoListarDocumento} />
                    <Route exact path="/legajos/documento/crear" component={LegajoCrearDocumento} />
                    <Route exact path="/cuenta/cambiar-clave" component={CambiarClavePage} />
                    <Route exact path="/cuenta/actualizar-clave" component={ActualizarClavePage} />

                    <Route
                        exact
                        path="/documento-personal/listar-detalle"
                        component={DocumentoPersonalListarDetallePage}
                    />
                    <Route
                        exact
                        path="/documento-laboral-porAprobar"
                        component={DocumentoLaboralPorAprobarListarPage}
                    />
                    <Route
                        exact
                        path="/documento-laboral-porAprobar/listar-detalle"
                        component={DocumentoLaboralPorAprobarListarDetallePage}
                    />
                    <Route
                        exact
                        path="/documento-laboral/listarEnvioNotificacion"
                        component={ListarDetalleNotificacionPage}
                    />
                    <Route exact path="/generarClave" component={GenerarClavePage} />
                    <Route exact path="/usuario" component={UsuarioListarPage} />
                    <Route exact path="/usuario/crear" component={UsuarioCrearPage} />
                    <Route exact path="/usuario/editar/:idUsuario" component={UsuarioEditarPage} />
                    <Route exact path="/rol/crear" component={RolCrearPage} />
                    <Route exact path="/rol" component={RolListarPage} />
                    <Route exact path="/rol/editar/:idRol" component={RolEditarPage} />
                    <Route exact path="/eliminarDatos" component={EliminarDatosPage} />

                    {/* Opción Configuración - inicio */}

                    <Route exact path="/configuracion/formularios/listar" component={ListarFormulariosPage} />

                    <Route exact path="/configuracion/formularios/crear" component={CrearFormulariosPage} />

                    <Route
                        exact
                        path="/configuracion/formularios/:formularioId/disenar"
                        component={DisenarFormularioPdfPage}
                    />
                    <Route
                        exact
                        path="/configuracion/formularios/:formularioId/editar"
                        component={EditarFormulariosPage}
                    />

                    <Route exact path="/configuracion/misCertificados" component={MisCertificadosListarPage} />

                    <Route path="/configuracion/certificadosDigitales" component={CertificadosDigitalesListarPage} />
                    <Route exact path="/configuracion/tipo-documentos/crear" component={CrearTipoDocumentosPage} />

                    <Route exact path="/configuracion/tipo-documentos/listar" component={ListarTipoDocumentosPage} />
                    <Route
                        exact
                        path="/configuracion/tipo-documentos/:idTipoDocumento/editar"
                        component={EditarTipoDocumentoPage}
                    />
                    <Route exact path="/configuracion/carpetas-legajos/crear" component={CrearCarpetasLegajosPage} />
                    <Route exact path="/configuracion/carpetas-legajos/listar" component={ListarCarpetasLegajosPage} />
                    <Route
                        exact
                        path="/configuracion/carpetas-legajos/:idCarpeta/editar"
                        component={EditarCarpetaLegajoPage}
                    />
                    <Route exact path="/configuracion/firmas-holografas/listar" component={ListarFirmaHolografaPage} />
                    <Route exact path="/configuracion/firmas-holografas/crear" component={CrearFirmaHolografaPage} />

                    <Route
                        exact
                        path="/configuracion/verificar-datos-colaborador"
                        component={ListarVerificarDatosColaboradorPage}
                    />
                    <Route
                        exact
                        path="/configuracion/verificar-datos-colaborador/notificar"
                        component={VerificarDatosColaboradorPage}
                    />

                    <Route
                        exact
                        path="/configuracion/plantilla-envio-documentos/listar"
                        component={ListarPlantillaEnvioDocumentosPage}
                    />
                    <Route
                        exact
                        path="/configuracion/plantilla-envio-documentos/crear"
                        component={CrearPlantillaEnvioDocumentosPage}
                    />
                    <Route
                        exact
                        path="/configuracion/plantilla-envio-documentos/:idPlantilla/editar"
                        component={EditarPlantillaEnvioDocumentosPage}
                    />
                    <Route exact path="/notificacion/onboarding" component={OnboardingIndividualPage} />

                    {/* Opción Configuración - fin */}

                    {/* Opción: Notificación - Inicio */}

                    <Route exact path="/notificacion/comunicado/notificar" component={ComunicadosCrearPage} />

                    <Route exact path="/notificacion/formulario/seguimiento" component={SeguimientoFormulariosPage} />

                    <Route exact path="/notificacion/formulario/notificar" component={NotificarFormularioPage} />
                    <Route
                        exact
                        path="/notificacion/formulario/seguimiento/:envioId/detalle"
                        component={DetalleSeguimientoFormulariosPage}
                    />

                    {/* Opción: Notificación - Fin */}

                    <Route exact path="/contrato/plantilla-contrato/crear" component={PlantillaContratoCrearPage} />
                    <Route exact path="/contrato/plantilla-contrato/listar" component={PlantillaContratoListarPage} />
                    <Route
                        exact
                        path="/contrato/plantilla-contrato/editar/:idPlantillaContrato"
                        component={PlantillaContratoEditarPage}
                    />
                    <Route exact path="/contrato/listarEnvioContrato" component={ListarEnvioContratoPage} />

                    <Route
                        exact
                        path="/contrato/seguimiento/renovacion-contratos"
                        component={SeguimientoRenovacionContratoPage}
                    />

                    <Route
                        exact
                        path="/explomin/contrato/bandeja/aprobacion/administracion-personal"
                        component={BandejaAprobacionAdministracionPersonalPage}
                    />

                    <Route
                        exact
                        path="/explomin/contrato/bandeja/aprobacion/reclutamiento-y-seleccion"
                        component={BandejaAprobacionReclutamientoYSeleccionPage}
                    />

                    <Route path="/configuracion/misCertificados" component={MisCertificadosListarPage} />

                    <Route
                        exact
                        path="/notificacion/firma-documento-generico/crear"
                        component={FirmaDocumentoGenericoCrearPage}
                    />

                    <Route
                        exact
                        path="/notificacion/firma-documento-generico/listar"
                        component={FirmaDocumentoGenericoListarPage}
                    />

                    <Route
                        exact
                        path="/notificacion/firma-documento-generico/mis-documentos"
                        component={FirmaDocumentoGenericoMisDocumentosPage}
                    />
                    <Route exact path="/notificacion/seguimiento/" component={ListarSeguimiento} />
                    <Route
                        exact
                        path="/notificacion/seguimiento/detalle/:categoria/:envioId"
                        component={ListarDetalleSeguimiento}
                    />
                    <Route exact path="/consulta-documento" component={ConsultaDocumentoPage} />
                    <Route exact path="/notificacion-por-completar" component={NotificacionPorCompletar} />
                    <Route
                        exact
                        path="/notificacion-por-completar/:envioId"
                        component={ListarNotificacionCompletarDetalle}
                    />
                    <Route
                        exact
                        path="/listar-observaciones-colaboradores"
                        component={ListarConsultasColaboradorPage}
                    />
                    <Route exact path="/Onboarding/consulta" component={ConsultaOnboarding} />
                    <Route exact path="/onboarding/seguimiento" component={SeguimientoOnboarding} />
                    <Route
                        exact
                        path="/onboarding/seguimiento/:envioId/:descripcion"
                        component={DetalleSeguimientoOnboarding}
                    />
                    <Route exact path="/listar-reportes" component={ListarReportesPage} />
                    <Route exact path="/listar-legajosCargados" component={LegajoCargaPage} />
                    <Route exact path="/notificacion/evaluacion/notificar" component={NotificarEvaluacionPage} />

                    <Route exact path="/tipoAbsentismo/crear" component={CrearTipoAbsentismoPage} />
                    <Route exact path="/tipoAbsentismo" component={ListarTipoAbsentismoPage} />
                    <Route exact path="/tipoAbsentismo/editar/:idTipoAbsentismo" component={EditarTipoAbsentismoPage} />

                    <Route exact path="/solicitud-vacaciones/consulta" component={ConsultaVacacionesPage} />
                    <Route exact path="/ausencias/solicitudes" component={SolicitudesAusenciaPage} />
                    <Route exact path="/ausencias/vacaciones" component={ControlVacacionalPage} />
                    <Route exact path="/evaluacion/seguimiento" component={SeguimientoEvaluacionPage} />

                    <Route exact path="/contratos-vencidos/seguimiento" component={SeguimientoContratosPage} />

                    <Route exact path="/consultar/descarga-masiva" component={DescargaMasivaListarPage} />
                    <Route exact path="/consultar/descarga-masiva/crear" component={DescargaMasivaCrearPage} />

                    <Route exact path="/v3/legajos/:colaboradorId" component={LegajoColaboradorPage} />

                    <RutasReclutamientoSeleccion />
                </Switch>
            </PlantillaWeb>
        </BrowserRouter>
    );
}
