import React, { useContext, useEffect, useState } from "react";
import { SearchOutlined,FolderOutlined , UnorderedListOutlined,CaretDownOutlined, LoadingOutlined,DeleteOutlined  } from "@ant-design/icons";
import { Menu, Button, Dropdown, Table, Tag, message,Popconfirm } from "antd";
import { useHistory } from "react-router";

import { useAtom } from "jotai";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";
import { SecurityContext } from "context/SecurityContextProvider";
import { TablaAvance } from "../TablaAvance";
import { TablaAvanceAprobados } from "../TablaAvanceAprobados";
import { buttonMasivoAtom, documentosOnboardingAtom, filtroColaboradorAtom, filtroFechaFinAtom, filtroFechaInicioAtom, 
  loadingAtom,totalEnviosAtom,paginaActualAtom, filtroProyectoAtom, filtroReclutadorAtom, filtroProcesoAtom} from "../../SeguimientoOnboardingStore";
import UsuarioService from "services/UsuarioService";
import CarpetaService from "services/CarpetaService";
import ModalAsignarCarpeta from "./ModalAsignarCarpeta";

const TablaListar = () => {
  const [documentosOnboarding, setDocumentosOnboarding] = useAtom(documentosOnboardingAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [totalPaginas, setTotalPaginas] = useState(null);
  const [totalEnvios, setTotalEnvios] = useAtom(totalEnviosAtom);
  const [filtroColaborador] = useAtom(filtroColaboradorAtom);
  const [filtroInicio] = useAtom(filtroFechaInicioAtom);
  const [filtroFin] = useAtom(filtroFechaFinAtom);
  const [filtroProyecto, setFiltroProyecto] = useAtom(filtroProyectoAtom);
  const [filtroReclutador, setFiltroReclutador] = useAtom(filtroReclutadorAtom);
  const [filtroProceso, setFiltroProceso] = useAtom(filtroProcesoAtom);
  //const [paginaActual, setPaginActual] = useState(1);
  const [paginaActual, setPaginActual] = useAtom(paginaActualAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  const history = useHistory();

  const [carpetas, setCarpetas] = useState([]);
  const [carpetasFiltro, setCarpetasFiltro] = useState([]);
  const [filaDocumento, setFilaDocumento] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [isMasivo, setIsMasivo] = useState(false);
  const [buttonMasivo, setButtonMasivo] = useAtom(buttonMasivoAtom)
  const [esUsuarioReclutador,setEsUsuarioReclutador]=useState(false);
  //const [finishCarpeta, setFinishCarpeta] = useState(false);
  const isExplomin =
  getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837"
    ? true
    : false;


  useEffect(() => {
    const cargarOnboardings = async () => {      
      setLoading(true)
      const _documentos = await OnboardingColaboradorService.listarEnvioOnboarding(
        null,
        null,
        null,
        null,
        null,
        null,
        getUsuarioAutenticado().login,
        0
      );
      setDocumentosOnboarding(_documentos.envios);
      setTotalPaginas(_documentos.totalPaginas);
      setTotalEnvios(_documentos.totalEnvios);

      const usuario = await UsuarioService.buscarPorOrganizacionLogin(
        getUsuarioAutenticado().organizacionId,
        getUsuarioAutenticado().login
      );

      const empresaSeleccionada = usuario?.empresas.find(
        (empresa) => empresa.id === getUsuarioAutenticado().empresaSeleccionada.id
      );
      const _carpetas = await getListaCarpetasFiltro(empresaSeleccionada);
      setCarpetasFiltro(_carpetas);

      const respuesta= await UsuarioService.esUsuarioRolReclutador(getUsuarioAutenticado().login);
      setEsUsuarioReclutador(respuesta);
      setLoading(false)
    };
    cargarOnboardings();
  }, []);
  

  
  useEffect(() => {
    if(buttonMasivo){
      if(filaDocumento!= null && filaDocumento?.length!==0){
        setIsMasivo(true)
        setModalVisible(true)
      }else
        message.error("Seleccione al menos 1 documento.")
      
      setButtonMasivo(false);
    }
  }, [buttonMasivo]);

  const onChangePagination = async (page) => {
    setPaginActual(page);
    const _documentos = await OnboardingColaboradorService.listarEnvioOnboarding(
      filtroColaborador,
      filtroInicio,
      filtroFin,
      filtroProyecto,
      filtroReclutador,
      filtroProceso,
      getUsuarioAutenticado().login,
      page - 1
    );
    setDocumentosOnboarding(_documentos.envios);
    setTotalPaginas(_documentos.totalPaginas);
    setTotalEnvios(_documentos.totalEnvios);
  };

  const getListaCarpetasFiltro = async (empresaSeleccionada) => {
    const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
    setCarpetas(_carpetas);
    if (!empresaSeleccionada?.filtroCarpeta?.length > 0) {
      return _carpetas;
    }

    var temp = [];
    const _ = empresaSeleccionada?.filtroCarpeta?.map((e) => {
      temp.push(_carpetas.find((c) => c.id == e.id));
    });

    return temp;
  };

  const rowSelection = {
    selectedRowKeys: filaDocumento,

    onChange: (selectedRowKeys, selectedRows) => {
      setFilaDocumento(selectedRowKeys);
    },
  };

  const menu = (fila) => (
    <Menu onClick={handleOnclickMenu}>
      <Menu.Item key="1" fila={fila}>
        <SearchOutlined /> Ver detalle
      </Menu.Item>
      {!esUsuarioReclutador &&(
      <Menu.Item key="2" fila={fila}>
        <FolderOutlined  /> Asignar Carpeta
      </Menu.Item>
      )}
      {!esUsuarioReclutador &&(
      <Popconfirm
       title="¿Está seguro de eliminar el registro seleccionado?"
       okText="Si"
       cancelText="No"
       onConfirm={()=>onConfirmEliminar(fila)}
      >
      <Menu.Item >
      <DeleteOutlined /> Eliminar
      </Menu.Item>
      </Popconfirm>
      )}
    </Menu>
  );

  async function handleOnclickMenu({ item, key }) {
    if (key === "1") {
      history.push("/onboarding/seguimiento/" + item.props.fila.id + "/" + item.props.fila.descripcion);
    } else if (key === "2") {
      setIsMasivo(false)
      setFilaDocumento([item.props.fila])
      setModalVisible(true)

    } 
          
  }
  const onConfirmEliminar = async (fila) =>{
    await OnboardingColaboradorService.eliminar(fila.id);
    setDocumentosOnboarding(documentosOnboarding.filter(documento=>documento.id!==fila.id));
  }

  let columnas = [];
  
  if(isExplomin){
    columnas = [
      {
        title: "#",
        dataIndex: "indice",
        width: "5%",
        render: (text, fila,index) => (
          <a
            onClick={() => {
              history.push("/onboarding/seguimiento/" + fila.id + "/" + fila.descripcion);
            }}
            className="custom-color-element"
          >
            {(paginaActual?paginaActual-1:0)*30+index+1}
          </a>
        ),
      },
      {
        title: "colaborador",
        width: "20%",
        render: (fila) => (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {fila.colaboradores[0].nombreCompleto}
            </div>
            <div style={{ alignItems: "center" }}>
              <Tag color="blue">{fila.colaboradores[0].numeroDocumentoIdentidad}</Tag>
            </div>
          </div>
        ),
      },
      {
        title: "Proceso",
        dataIndex: "descripcion",
        align: "center",
        width: "20%",
      },
      {
        title: "Proyecto",
        dataIndex: "proyecto",
        align: "center",
        width: "20%",
      },
      {
        title: "Reclutador",
        dataIndex: ["reclutador","nombreCompleto"],
        align: "center",
        width: "20%",
      },
      {
        title: "Inicio Proceso",
        dataIndex: "fechaEnvio",
        align: "center",
        width: "10%",
      },
      {
        title: "Fin Proceso",
        dataIndex: "fechaOnboardingFin",
        align: "center",
        width: "10%",
        render: (_, fila) => {
          return fila.fechaOnboardingFin !== null ? fila.fechaOnboardingFin : "-";
        },
      },
      {
        title: "% Avance de Formularios Completados",
        dataIndex: "avance",
        width: "10%",
        render: (_, fila) => <TablaAvance documento={fila} />,
      },
      {
        title: "% Avance de Formularios Aprobados",
        dataIndex: "avanceAprobados",
        width: "10%",
        render: (_, fila) => <TablaAvanceAprobados documento={fila} />,
      },
      {
        title: "Acciones",
        align: "center",
        width: "15%",
        render: (text, fila) => (
          <Dropdown overlay={() => menu(fila)}>
              <a className="custom-color-element" onClick={(e) => e.preventDefault()}>
                <UnorderedListOutlined  /> <CaretDownOutlined  />
              </a>
            </Dropdown>
        ),
      },
    ];
  }else{
    columnas = [
      {
        title: "#",
        dataIndex: "indice",
        width: "5%",
        render: (text, fila,index) => (
          <a
            onClick={() => {
              history.push("/onboarding/seguimiento/" + fila.id + "/" + fila.descripcion);
            }}
            className="custom-color-element"
          >
            {(paginaActual?paginaActual-1:0)*30+index+1}
          </a>
        ),
      },
      {
        title: "colaborador",
        dataIndex: ["colaboradores", "0", "nombreCompleto"],
        width: "20%",
      },
      {
        title: "Proceso",
        dataIndex: "descripcion",
        align: "center",
        width: "20%",
      },
      {
        title: "Inicio Proceso",
        dataIndex: "fechaEnvio",
        align: "center",
        width: "10%",
      },
      {
        title: "Fin Proceso",
        dataIndex: "fechaOnboardingFin",
        align: "center",
        width: "10%",
        render: (_, fila) => {
          return fila.fechaOnboardingFin !== null ? fila.fechaOnboardingFin : "-";
        },
      },
      {
        title: "% Avance de Formularios Completados",
        dataIndex: "avance",
        width: "10%",
        render: (_, fila) => <TablaAvance documento={fila} />,
      },
      {
        title: "% Avance de Formularios Aprobados",
        dataIndex: "avanceAprobados",
        width: "10%",
        render: (_, fila) => <TablaAvanceAprobados documento={fila} />,
      },
      {
        title: "Acciones",
        align: "center",
        width: "15%",
        render: (text, fila) => (
          <Dropdown overlay={() => menu(fila)}>
              <a className="custom-color-element" onClick={(e) => e.preventDefault()}>
                <UnorderedListOutlined  /> <CaretDownOutlined  />
              </a>
            </Dropdown>
          // <a
          //   onClick={() => {
          //     history.push("/onboarding/seguimiento/" + fila.id + "/" + fila.descripcion);
          //     // setDescripcionEnvio(fila.descripcion);
          //     // setEnvioId(fila.id);
          //     // setIsDetalle(true);
          //   }}
          //   className="custom-color-element"
          // >
          //   <SearchOutlined /> Ver Detalle
          // </a>
        ),
      },
    ];
  }
  

  return (
    <React.Fragment>
      <Table
        rowKey={(documento) => documento.id}
        dataSource={documentosOnboarding}
        columns={columnas}
        rowSelection={rowSelection}
        size="small"
        loading={{
          spinning: loading,
          indicator: <LoadingOutlined style={{ fontSize: 28 }}  />,
          tip:"Cargando..."
        }}
        pagination={{
          size: "small",
          defaultCurrent: paginaActual ? paginaActual : 1,
          current: paginaActual,
          total: totalEnvios,
          showSizeChanger: false,
          showTotal: (total) => `Total ${total} documentos`,
          pageSize: 30,
          onChange: onChangePagination,
        }}
      />
      <ModalAsignarCarpeta carpeta={carpetas} carpetaFiltro={carpetasFiltro} documento={filaDocumento} /*setFinishCarpeta={setFinishCarpeta}*/
                            setModalVisible={setModalVisible} modalVisible={modalVisible} isMasivo={isMasivo} setFilaDocumento={setFilaDocumento} />
    </React.Fragment>
  );
};

export default TablaListar;
