import React, { useEffect, useContext } from "react";
import { Dropdown, Menu, Button, Modal, notification } from "antd";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import { OPCIONES } from "enums";
import {
  SearchOutlined,
  MailOutlined,
  StopOutlined,
  EditOutlined,
  AuditOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
  UnorderedListOutlined,
  CaretDownOutlined,
  UploadOutlined
} from "@ant-design/icons";
import { useAtom } from "jotai";
import {
  visibleModalHistorialNotifiacaionAtom,
  consultaDocumentoSeleccionadoAtom,
  documentosAtom,
  visibleModalActualizarCorreoAtom,
  visibleModalAsignarCarpetaAtom,
  codigosOpcionesAtom,
  visibleModalCargarDocumentoAtom
} from "../../ConsultaDocumentoStore";
import DetalleEnvioService from "services/DetalleEnvioService";
import { ContratoService } from "services/ContratoService";
import { SeguimientoService } from "services/SeguimientoService";
import { SecurityContext } from "context/SecurityContextProvider";
import { useModalAsignarCarpeta, useModalVisorPdf } from "store/global";
import { saveAs } from "file-saver";

const MenuAcciones = ({ envio }) => {
  const [consultaDocumento, setConsultaDocumento] = useAtom(documentosAtom);
  const [, setConsultaDocumentoSeleccionado] = useAtom(consultaDocumentoSeleccionadoAtom);
  const [, setVisibleModalCargarDocumento] = useAtom(visibleModalCargarDocumentoAtom);

  const [, setVisibleModalActualizarCorreo] = useAtom(visibleModalActualizarCorreoAtom);
  const [, setVisibleModalHistorialNotificacion] = useAtom(visibleModalHistorialNotifiacaionAtom);
  const [, setVisibleModalAsignarCarpeta] = useAtom(visibleModalAsignarCarpetaAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const mostrarModalAsignarCarpeta = useModalAsignarCarpeta((state) => state.mostrarModal);
  const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
  const cerrarModalAsignarCarpeta = useModalAsignarCarpeta((state) => state.cerrarModal);

  const [codigosOpciones] = useAtom(codigosOpcionesAtom);

  const onClickMenu = (opcion) => {
    switch (opcion.key) {
      case "ver-documento":
        onClickBtnVerDocumento(envio);
        break;
      case "reenviar-documento":
        onClickBtnReenviarNotificacion();
        break;
      case "inhabilitar":
        onClickBtnInhabilitarContrato();
        break;
      case "habilitar":
        onClickBtnHabilitarContrato();
        break;
      case "actualizar-correo":
        setVisibleModalActualizarCorreo(true);
        setConsultaDocumentoSeleccionado(envio);
        break;
      case "hitstorial-notificacion":
        setVisibleModalHistorialNotificacion(true);
        setConsultaDocumentoSeleccionado(envio);
        break;
      case "asignar-carpeta":
        setVisibleModalAsignarCarpeta(true);
        setConsultaDocumentoSeleccionado(envio);
        break;
      case "descargar-Evidencia":
        setConsultaDocumentoSeleccionado(envio);
        onClickMenuEvidencia({ documentoSeleccionado: envio });
        break;
      case "subir-documento":
          setVisibleModalCargarDocumento(true);
          setConsultaDocumentoSeleccionado(envio);
      default:
        console.log("informacion de envio: ", envio);
        console.error("No se encuentra esa opción");
    }
  };

  const onClickBtnVerDocumento = async () => {
    try {
      //setVisibleMenu(false);
      console.log("envio", envio);
      if (envio?.estadoVistaColaborador !== "FIRMADO" && envio.categoriaDocumentoId === CategoriaDocumento.CARGA_DOCUMENTO.id) {
        Modal.error({
          title: "Falta completar",
          content: "El documento no ha sido cargado.",
        });
      }else{
        const respuesta = await ContratoService.getUrlDocumento(getUsuarioAutenticado().empresaSeleccionada.id, envio.id);
        mostrarModalVisorPdf(respuesta.urlDocumento, envio.envio.descripcion, true, true);
      }  
    } catch (error) {
      //setVisibleMenu(false);
      console.error(error);
    } finally {
    }
  };

  const onClickBtnReenviarNotificacion = async () => {
    Modal.confirm({
      bodyStyle: { display: "flex", flexDirection: "column" },
      icon: <QuestionCircleOutlined style={{ fontSize: "32px" }} />,
      content: (
        <span style={{ textAlign: "center", display: "block", fontSize: "16px" }}>
          ¿Está seguro de <b>reenvíar la notificación</b> del documento seleccionado?
        </span>
      ),
      autoFocusButton: "cancel",
      centered: true,
      maskClosable: true,
      cancelText: "No, cerrar ventana",
      okText: "Si, envíar notificación",
      onOk: async () => {
        try {
          if (envio.categoriaDocumento.nombre == "DOCUMENTO LABORAL") {
            await DetalleEnvioService.reenviarNotificacionPorId(
              envio.id,
              getUsuarioAutenticado().organizacionId,
              getUsuarioAutenticado().empresaSeleccionada.id
            );
          } else {
            await ContratoService.reenviarNotificacionMasivamente(
              getUsuarioAutenticado().organizacionId,
              getUsuarioAutenticado().empresaSeleccionada.id,
              envio.envio.id,
              [envio.id]
            );
          }

          //setVisibleMenu(false);
          notification.success({
            message: `Notificación`,
            description: "Se reenvió la notificación exitosamente.",
            placement: "topRight",
          });
        } catch (error) {
          console.error(error);
        } finally {
        }
      },
    });
    //setVisibleMenu(false);
  };

  const onClickBtnInhabilitarContrato = () => {
    Modal.confirm({
      bodyStyle: { display: "flex", flexDirection: "column" },
      icon: <QuestionCircleOutlined style={{ fontSize: "32px" }} />,
      content: (
        <span style={{ textAlign: "center", display: "block", fontSize: "16px" }}>
          ¿Está seguro de <b>inhabilitar</b> el documento seleccionado?
        </span>
      ),
      autoFocusButton: "cancel",
      centered: true,
      maskClosable: true,
      cancelText: "No, cerrar ventana",
      okText: "Si, inhabilitar documento",
      onOk: async () => {
        try {
          const documentoActualizado = await SeguimientoService.inhabilitar(
            getUsuarioAutenticado().empresaSeleccionada.id,
            envio.id
          );
          setConsultaDocumento({
            ...consultaDocumento,
            data: consultaDocumento.data.map((envio) => {
              if (envio.id === documentoActualizado.id) {
                return documentoActualizado;
              }
              return envio;
            }),
          });

          notification.success({
            message: `Notificación`,
            description: "Se inhabilitó el documento exitosamente.",
            placement: "topRight",
          });
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const onClickBtnHabilitarContrato = () => {
    Modal.confirm({
      bodyStyle: { display: "flex", flexDirection: "column" },
      icon: <QuestionCircleOutlined style={{ fontSize: "32px" }} />,
      content: (
        <span style={{ textAlign: "center", display: "block", fontSize: "16px" }}>
          ¿Está seguro de <b>habilitar</b> el documento seleccionado?
        </span>
      ),
      autoFocusButton: "cancel",
      centered: true,
      maskClosable: true,
      cancelText: "No, cerrar ventana",
      okText: "Si, habilitar documento",
      onOk: async () => {
        try {
          const documentoActualizado = await SeguimientoService.habilitar(
            getUsuarioAutenticado().empresaSeleccionada.id,
            envio.id
          );

          setConsultaDocumento({
            ...consultaDocumento,
            data: consultaDocumento.data.map((envio) => {
              if (envio.id === documentoActualizado.id) {
                return documentoActualizado;
              }
              return envio;
            }),
          });

          notification.success({
            message: `Notificación`,
            description: "Se habilitó el contrato exitosamente.",
            placement: "topRight",
          });
        } catch (error) {
          console.error(error);
        }
      },
    });
    //setVisibleMenu(false);
  };

  const onClickMenuAsignarCarpeta = () => {
    mostrarModalAsignarCarpeta();
  };

  const onClickMenuEvidencia = async ({ documentoSeleccionado }) => {
    const parametros = {
      empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      detalleEnvioId: documentoSeleccionado.id,
    };

    const respuesta = await DetalleEnvioService.descargarPDFEvidenciaConsultaDocumento(parametros);
    const filename = respuesta.headers.filename;
    saveAs(respuesta.data, filename);
  };

  function onClickBtnCancelarModal() {
    cerrarModalAsignarCarpeta();
  }

  const menu = () => {
    if (envio.eliminado === true) {
      return (
        <Menu onClick={onClickMenu}>
          {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS.BOTON_VER_DOCUMENTO) && (
            <Menu.Item key="ver-documento">
              <SearchOutlined /> Ver Documento
            </Menu.Item>
          )}
          {codigosOpciones &&
            codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS.BOTON_HABILITAR_DOCUMENTO) && (
              <Menu.Item key="habilitar">
                <CheckCircleOutlined /> Habilitar documento
              </Menu.Item>
            )}
        </Menu>
      );
    }

    return (
      <Menu onClick={onClickMenu}>
        {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS.BOTON_VER_DOCUMENTO) && (
          <Menu.Item key="ver-documento">
            <SearchOutlined /> Ver Documento
          </Menu.Item>
        )}
        {codigosOpciones &&
          codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS.BOTON_REENVIAR_DOCUMENTO) && (
            <Menu.Item key="reenviar-documento">
              <MailOutlined /> Reenviar Documento
            </Menu.Item>
          )}
        {codigosOpciones &&
          codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS.BOTON_INHABILITAR_DOCUMENTO) && (
            <Menu.Item key="inhabilitar">
              <StopOutlined /> Inhabilitar Documento
            </Menu.Item>
          )}
        {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS.BOTON_ACTUALIZAR_CORREO) && (
          <Menu.Item key="actualizar-correo">
            <EditOutlined /> Actualizar Correo
          </Menu.Item>
        )}
        {codigosOpciones &&
          codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS.BOTON_HISTORIAL_NOTIFICACION) && (
            <Menu.Item key="hitstorial-notificacion">
              <AuditOutlined />
              Historial Notificación
            </Menu.Item>
          )}
          {
            envio.categoriaDocumentoId === CategoriaDocumento.CARGA_DOCUMENTO.id &&
            envio?.interno &&(
              <Menu.Item key="subir-documento">
              <UploadOutlined />
              Subir Documento
            </Menu.Item>
            )
          }
        {/* <Menu.Item key="asignar-carpeta">
                 <SelectOutlined />Asignar Carpeta
                </Menu.Item> */}
        {/* <Menu.Item key="descargar-Evidencia">
                <DownloadOutlined />Evidencia
        </Menu.Item>*/}
      </Menu>
    );
  };

  return (
    // <React.Fragment>
    <Dropdown overlay={menu} placement="bottomCenter">
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
        <CaretDownOutlined style={{ paddingLeft: "2px", fontSize: "12px", fontWeight: "bold", color: "#1678c1" }} />
      </a>
    </Dropdown>
    // </React.Fragment>
  );
};

export default MenuAcciones;
