import React, { useContext, useState, useEffect } from "react";
import { Dropdown, Menu } from "antd";
import { UnorderedListOutlined, CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { useModalVisorPdf } from "store/global";

import { SecurityContext } from "context/SecurityContextProvider";
import { OPCIONES } from "enums";
import { useAtom } from "jotai";
import DetalleEnvioService from "services/DetalleEnvioService";

const MenuAcciones = ({ documento, opciones }) => {
  const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
  const [, setLoading] = useState(false);
  const { getUsuarioAutenticado } = useContext(SecurityContext);


  const onClickMenu = async ({ key }) => {
    if (key === "ver-documento") {
      await onclickBtnVerDocumento();
    }
  };

  const onclickBtnVerDocumento = async () => {
    try {
      setLoading(true);

      const respuesta = await DetalleEnvioService.obtenerUrlDocumento(
        getUsuarioAutenticado().empresaSeleccionada.id,
        documento.detalleEnvioId
      );
      mostrarModalVisorPdf(respuesta.urlDocumento, documento.nombre, true, true);
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const menu = () => {
    return (
      <Menu onClick={onClickMenu}>
        {opciones && opciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_CARPETAS.BOTON_VER_DOCUMENTO) && (
          <Menu.Item key="ver-documento">
            <SearchOutlined /> Ver Documento
          </Menu.Item>
        )}
      </Menu>
    );
  };
  return (
    <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
        <CaretDownOutlined
          style={{
            paddingLeft: "2px",
            fontSize: "12px",
            fontWeight: "bold",
            color: "#1678c1",
          }}
        />
      </a>
    </Dropdown>
  );
};
export default MenuAcciones;
