import React, { useContext, useEffect, useState } from "react";
import {
    Drawer,
    Button,
    Space,
    Form,
    Row,
    Col,
    Input,
    Divider,
    Checkbox,
    InputNumber,
    message,
    Select,
    Card,
    Badge,
} from "antd";
import { useAtom } from "jotai";
import {
    visibleDrawerPropiedadesAtom,
    controlSeleccionadoAtom,
    componentesAtom,
    paginaPdfAtom,
    cargosAtom,
    sedesAtom,
    formulariosAtom,
} from "../../DisenarFormularioPdfStore";
import produce from "immer";
import { TipoControl, SignaturePerson } from "enums";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { SecurityContext } from "context/SecurityContextProvider";
import CargoService from "services/CargoService";
import SedeService from "services/SedeService";
// import { FormularioService } from "services/FormularioService";

const DrawerPropiedades = () => {
    const [paginaPdf] = useAtom(paginaPdfAtom);
    const [componentes, setComponentes] = useAtom(componentesAtom);
    const [visible, setVisible] = useAtom(visibleDrawerPropiedadesAtom);
    const [controlSeleccionado, setControlSeleccionado] = useAtom(controlSeleccionadoAtom);
    const [frmPropiedades] = Form.useForm();
    const [camposColaborador, setCamposColaborador] = useState(undefined);
    const [cargos, setCargos] = useAtom(cargosAtom);
    const [sedes, setSedes] = useAtom(sedesAtom);
    const [componenteCondicionalSeleccionado, setComponenteCondicionalSeleccionado] = useState(undefined);

    const defaultValue = Form.useWatch("defaultValue", frmPropiedades);
    const signaturePerson = Form.useWatch("signaturePerson", frmPropiedades);
    // const [formularios, setFormularios] = useAtom(formulariosAtom);
    const { Option } = Select;
    const { getUsuarioAutenticado } = useContext(SecurityContext);

    const onClose = () => {
        setVisible(false);
    };

    const onClickBtnGuardar = () => {
        frmPropiedades
            .validateFields()
            .then((form) => {
                const existeControl = componentes.find((c) => c.key === form.key);
                if (existeControl && existeControl.key !== form.keyPrev) {
                    message.error("Ya existe un control con ese nombre");
                    return;
                }

                setComponentes(
                    produce((draft) => {
                        //actualiza el control seleccionado
                        controlSeleccionado.ref.updateSize({
                            width: parseFloat(form.width),
                            height: parseFloat(form.height),
                        });
                        controlSeleccionado.ref.updatePosition({ x: parseFloat(form.left), y: parseFloat(form.top) });
                        setControlSeleccionado({ ...controlSeleccionado, key: form.key });
                        //actualiza control dentro del array de componentes
                        const controlEncontrado = draft.find((c) => c.key === form.keyPrev);
                        controlEncontrado.key = form.key;
                        controlEncontrado.label = form.label;
                        controlEncontrado.overlay.height = parseFloat(form.height);
                        controlEncontrado.overlay.width = parseFloat(form.width);
                        controlEncontrado.overlay.left = parseFloat(form.left);
                        controlEncontrado.overlay.top = parseFloat(form.top);
                        // controlEncontrado.overlay.topPdf = parseFloat(form.top);
                        controlEncontrado.required = form.required;
                        controlEncontrado.size = form.fuenteSize;

                        /* condicional */
                        if (form.accion !== undefined && form.control !== undefined && form.valor !== undefined) {
                            controlEncontrado.condicional = {
                                accion: form.accion,
                                control: form.control,
                                valor: form.valor,
                            };
                        }

                        /** Atributos para manejar el responsable del componente */
                        controlEncontrado.signaturePerson = form.signaturePerson;
                        controlEncontrado.cargo = form.cargo;
                        controlEncontrado.sede = form.sede;

                        /** Formularios */
                        controlEncontrado.formularioId = form.formularioId;

                        if (controlEncontrado.type === TipoControl.CHECKBOX) {
                            controlEncontrado.value = form.value;
                            controlEncontrado.defaultValue = form.defaultValue;
                        }

                        if (controlEncontrado.type === TipoControl.IMAGEN) {
                            controlEncontrado.defaultValue = form.defaultValue;
                        }

                        if (controlEncontrado.type === TipoControl.RADIO_BUTTON) {
                            controlEncontrado.value = form.value;
                            controlEncontrado.name = form.name;
                            controlEncontrado.defaultValue = form.defaultValue;
                        }
                        if (controlEncontrado.type === TipoControl.CAJA_TEXTO || controlEncontrado.type === TipoControl.ETIQUETA) {
                            controlEncontrado.defaultValue = form.defaultValue;
                            controlEncontrado.formatDefaultValue = form.formatDefaultValue;
                            controlEncontrado.readOnly = form.readOnly;
                            controlEncontrado.customDefaultValue = form.customDefaultValue;
                        }
                        if (controlEncontrado.type === TipoControl.LISTA) {
                            controlEncontrado.items = form.items;
                        }
                        if (controlEncontrado.type === TipoControl.SIGNATURE) {
                            controlEncontrado.signatureType = form.signatureType;
                            controlEncontrado.overlay.pageHeight = paginaPdf.alto;
                            console.log("overlay.pageHeight: ", paginaPdf.alto);
                            controlEncontrado.signatureInfo = form.signatureInfo;
                            controlEncontrado.requiereFirmaElectronica = form.requiereFirmaElectronica;
                        }
                        // if (controlEncontrado.type === TipoControl.SIGNATURE_DATA) {
                        //   controlEncontrado.overlay.pageHeight = paginaPdf.alto;
                        // }
                        if (form.table !== undefined && form.column !== undefined && form.rowNumber !== undefined) {
                            controlEncontrado.groupBy = {
                                table: form.table,
                                column: form.column,
                                rowNumber: form.rowNumber,
                            };
                        }
                        frmPropiedades.setFieldsValue({ keyPrev: form.key });
                    })
                );
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const onSelectControl = (key, item) => {
        const idControl = item.key.includes("|") ? item.key.split("|")[0] : item.key;
        const componente = componentes.find((componente) => componente.key === idControl);
        setComponenteCondicionalSeleccionado(componente);
    };

    useEffect(() => {
        const cargarDatos = async () => {
            if (visible) {
                const camposColaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
                setCamposColaborador(camposColaborador);

                //if (controlSeleccionado.type === TipoControl.SIGNATURE) {
                const cargos = await CargoService.listar();
                setCargos(cargos);
                const sedes = await SedeService.listar();
                setSedes(sedes);
                //}

                frmPropiedades.setFieldsValue({
                    label: controlSeleccionado.label,
                    key: controlSeleccionado.key,
                    keyPrev: controlSeleccionado.key,
                    type: controlSeleccionado.type,
                    left: controlSeleccionado.overlay.left,
                    top: controlSeleccionado.overlay.top,
                    // topPdf: paginaPdf.alto - controlSeleccionado.overlay.top - controlSeleccionado.overlay.height,
                    width: controlSeleccionado.overlay.width,
                    height: controlSeleccionado.overlay.height,
                    accion: controlSeleccionado.condicional?.accion,
                    control: controlSeleccionado.condicional?.control,
                    valor: controlSeleccionado.condicional?.valor,
                    required: controlSeleccionado.required,
                    table: controlSeleccionado.groupBy?.table,
                    column: controlSeleccionado.groupBy?.column,
                    rowNumber: controlSeleccionado.groupBy?.rowNumber,
                    value: controlSeleccionado.value,
                    name:
                        controlSeleccionado.groupBy && controlSeleccionado.name.includes("-")
                            ? controlSeleccionado.name.split("-")[1]
                            : controlSeleccionado.name,
                    defaultValue: controlSeleccionado.defaultValue,
                    formatDefaultValue: controlSeleccionado.formatDefaultValue,
                    customDefaultValue: controlSeleccionado.customDefaultValue,
                    readOnly: controlSeleccionado.readOnly,
                    items: controlSeleccionado.items,
                    signaturePerson: controlSeleccionado.signaturePerson,
                    cargo: controlSeleccionado.cargo,
                    sede: controlSeleccionado.sede,
                    signatureType: controlSeleccionado.signatureType,
                    signatureInfo: controlSeleccionado.signatureInfo,
                    requiereFirmaElectronica: controlSeleccionado.requiereFirmaElectronica,
                    formularioId: controlSeleccionado.formularioId,
                    fuenteSize: controlSeleccionado.size,
                });
            }
        };
        cargarDatos();
    }, [visible]);

    // useEffect(() => {
    //     const fetch = async () => {
    //         try {
    //             //setLoading(true);
    //             const formularios = await FormularioService.listarPorEmpresaId(
    //                 getUsuarioAutenticado().empresaSeleccionada.id
    //             );
    //             setFormularios(formularios);
    //         } catch (error) {
    //             console.error(error);
    //         } finally {
    //             //setLoading(false);
    //         }
    //     };
    //     fetch();
    // }, []);

    return (
        <Drawer
            title="Propiedades Control"
            placement="right"
            onClose={onClose}
            visible={visible}
            width="420px"
            extra={
                <Space>
                    <Button onClick={onClose}>Cerrar</Button>
                    <Button onClick={onClickBtnGuardar} type="primary">
                        Aplicar
                    </Button>
                </Space>
            }
        >
            <Form layout="vertical" form={frmPropiedades}>
                <Form.Item name="keyPrev" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="key"
                            label="Identificador"
                            rules={[{ required: true, message: "El campo identificador es requerido" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="label"
                            label="Etiqueta"
                            rules={[{ required: true, message: "El campo etiqueta es requerido" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="signaturePerson"
                            label={
                                controlSeleccionado?.type === TipoControl.SIGNATURE
                                    ? "¿Persona que firma?"
                                    : "Responsable"
                            }
                            rules={[
                                {
                                    required: controlSeleccionado?.type === TipoControl.SIGNATURE ? true : false,
                                    message: "Seleccione la persona que firma.",
                                },
                            ]}
                        >
                            <Select
                                style={{ width: "100%" }}
                                allowClear={controlSeleccionado?.type === TipoControl.SIGNATURE ? false : true}
                            >
                                <Select.Option value={SignaturePerson.COLABORADOR}>COLABORADOR</Select.Option>
                                <Select.Option value={SignaturePerson.JEFE_INMEDIATO}>JEFE INMEDIATO</Select.Option>
                                <Select.Option value={SignaturePerson.JEFE_GESTION_HUMANA}>GESTIÓN HUMANA</Select.Option>
                                <Select.Option value={SignaturePerson.CARGO}>CARGO</Select.Option>
                                <Select.Option value={SignaturePerson.CARGO_Y_SEDE}>CARGO Y SEDE</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {(signaturePerson === SignaturePerson.CARGO || signaturePerson === SignaturePerson.CARGO_Y_SEDE) && (
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="cargo"
                                label="Cargo"
                                rules={[{ required: true, message: "Seleccione el cargo" }]}
                            >
                                <Select style={{ width: "100%" }} showSearch>
                                    {cargos &&
                                        cargos.map((cargo) => {
                                            return (
                                                <Select.Option key={cargo.id} value={cargo.id}>
                                                    {cargo.nombre}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {signaturePerson === SignaturePerson.CARGO_Y_SEDE && (
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="sede"
                                label="Sede"
                                rules={[{ required: true, message: "Seleccione la sede" }]}
                            >
                                <Select style={{ width: "100%" }} showSearch>
                                    {sedes &&
                                        sedes.map((sede) => {
                                            return (
                                                <Select.Option key={sede.id} value={sede.id}>
                                                    {sede.nombre}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                {controlSeleccionado && controlSeleccionado.type === TipoControl.SIGNATURE && (
                    <React.Fragment>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="signatureType"
                                    label="Tipo Firma"
                                    initialValue={"IMAGEN_Y_DESCRIPCION"}
                                    rules={[{ required: true, message: "El campo tipo firma es requerido" }]}
                                >
                                    <Select style={{ width: "100%" }}>
                                        <Select.Option value="IMAGEN">IMAGEN</Select.Option>
                                        <Select.Option value="IMAGEN_Y_DESCRIPCION">IMAGEN Y DESCRIPCION</Select.Option>
                                        <Select.Option value="DESCRIPCION">DESCRIPCION</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item name="signatureInfo" label="Personalizar Descripción de Firma">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item name="requiereFirmaElectronica" valuePropName="checked">
                                    <Checkbox>Requiere Firma Electrónica</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
                {controlSeleccionado && controlSeleccionado.type === TipoControl.RADIO_BUTTON && (
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Agrupar por"
                                rules={[{ required: true, message: "El campo nombre agrupamiento es requerido" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {controlSeleccionado &&
                    (controlSeleccionado.type === TipoControl.CHECKBOX ||
                        controlSeleccionado.type === TipoControl.RADIO_BUTTON) && (
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="value"
                                    label="Valor"
                                    rules={[{ required: true, message: "El campo valor es requerido" }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                <Divider orientation="left" plain>
                    Configuración
                </Divider>

                {controlSeleccionado && controlSeleccionado.type !== TipoControl.ETIQUETA &&
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="required" valuePropName="checked">
                                <Checkbox>Requerido</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                }

                {controlSeleccionado && controlSeleccionado.type === TipoControl.LISTA && (
                    <React.Fragment>
                        <Divider orientation="left" plain>
                            Valores Lista
                        </Divider>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.List name="items">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space
                                                    key={key}
                                                    style={{ display: "flex", marginBottom: 8 }}
                                                    align="baseline"
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "key"]}
                                                        rules={[{ required: true, message: "Código es obligatorio" }]}
                                                    >
                                                        <Input placeholder="Código" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "value"]}
                                                        rules={[{ required: true, message: "Valor es obligatorio" }]}
                                                    >
                                                        <Input placeholder="Valor" />
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                </Space>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    block
                                                    icon={<PlusOutlined />}
                                                >
                                                    Adicionar Valores
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                {controlSeleccionado && controlSeleccionado.type === TipoControl.CAJA_TEXTO
                    && (
                        <>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name="readOnly" valuePropName="checked">
                                        <Checkbox>Solo lectura</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )
                }

                {controlSeleccionado
                    && (controlSeleccionado.type === TipoControl.CAJA_TEXTO
                        || controlSeleccionado.type === TipoControl.ETIQUETA)
                    && (
                        <React.Fragment>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name="defaultValue" label="Valor Inicial">
                                        <Select style={{ width: "100%" }} showSearch allowClear={true}>
                                            <Select.Option value="${CODIGO}">CODIGO COLABORADOR</Select.Option>
                                            <Select.Option value="${DNI}">DNI</Select.Option>
                                            <Select.Option value="${DNI_NUMERO}">
                                                DNI {"(Mostrar si ocumento es DNI)"}
                                            </Select.Option>
                                            <Select.Option value="${CE_NUMERO}">
                                                CE {"(Mostrar si documento es CARNET DE EXTRANJERIA)"}
                                            </Select.Option>
                                            <Select.Option value="${APELLIDO_PATERNO}">APELLIDO PATERNO</Select.Option>
                                            <Select.Option value="${APELLIDO_MATERNO}">APELLIDO MATERNO</Select.Option>
                                            <Select.Option value="${NOMBRES}">NOMBRES</Select.Option>
                                            <Select.Option value="${NOMBRES_Y_APELLIDOS}">
                                                NOMBRES Y APELLIDOS
                                            </Select.Option>
                                            <Select.Option value="${CORREO_ELECTRONICO}">CORREO ELECTRÓNICO</Select.Option>
                                            <Select.Option value="${CELULAR}">CELULAR</Select.Option>
                                            <Select.Option value="${JEFE_INMEDIATO}">JEFE INMEDIATO</Select.Option>
                                            <Select.Option value="${PAIS_NACIMIENTO}">PAÍS NACIMIENTO</Select.Option>
                                            <Select.Option value="${NACIONALIDAD}">NACIONALIDAD</Select.Option>
                                            <Select.OptGroup label="DOMICILIO">
                                                <Select.Option value="${DIRECCION_DOMICILIO}">
                                                    DIRECCION (DOMICILIO)
                                                </Select.Option>
                                                <Select.Option value="${DEPARTAMENTO_DOMICILIO}">
                                                    DEPARTAMENTO (DOMICILIO)
                                                </Select.Option>
                                                <Select.Option value="${PROVINCIA_DOMICILIO}">
                                                    PROVINCIA (DOMICILIO)
                                                </Select.Option>
                                                <Select.Option value="${DISTRITO_DOMICILIO}">
                                                    DISTRITO (DOMICILIO)
                                                </Select.Option>
                                            </Select.OptGroup>
                                            <Select.Option value="${DIRECCION_NACIMIENTO}">
                                                DIRECCION NACIMIENTO
                                            </Select.Option>
                                            <Select.Option value="${DEPARTAMENTO_NACIMIENTO}">
                                                DEPARTAMENTO NACIMIENTO
                                            </Select.Option>
                                            <Select.Option value="${PROVINCIA_NACIMIENTO}">
                                                PROVINCIA NACIMIENTO
                                            </Select.Option>
                                            <Select.Option value="${DISTRITO_NACIMIENTO}">
                                                DISTRITO NACIMIENTO
                                            </Select.Option>
                                            <Select.Option value="${EDAD}">EDAD</Select.Option>
                                            <Select.Option value="${ESTADO_CIVIL}">ESTADO CIVIL</Select.Option>
                                            <Select.Option value="${CUSSP_AFP}">CUSPP AFP</Select.Option>
                                            <Select.Option value="${NOMBRE_AFP}">NOMBRE AFP</Select.Option>
                                            <Select.Option value="${FECHA_INGRESO}">FECHA DE INGRESO</Select.Option>
                                            <Select.Option value="${FECHA_NACIMIENTO}">FECHA NACIMIENTO</Select.Option>
                                            <Select.Option value="${FECHA_ACTUAL}">FECHA ACTUAL</Select.Option>
                                            {camposColaborador &&
                                                camposColaborador.length > 0 &&
                                                camposColaborador.map((campoColaborador) => {
                                                    return (
                                                        <Select.Option
                                                            key={campoColaborador.campo}
                                                            value={"${" + campoColaborador.campo + "}"}
                                                        >
                                                            {campoColaborador.etiqueta.toUpperCase()}
                                                        </Select.Option>
                                                    );
                                                })}

                                            <Select.Option value="${NOMBRES_Y_APELLIDOS_RESPONSABLE}">
                                                NOMBRES Y APELLIDOS (RESPONSABLE)
                                            </Select.Option>
                                            <Select.Option value="${CARGO_RESPONSABLE}">CARGO (RESPONSABLE)</Select.Option>

                                            <Select.OptGroup label="CUENTA BANCARIA HABERES">
                                                <Select.Option value="${BANCO_CTA_HABERES}">
                                                    BANCO (CTA. HABERES)
                                                </Select.Option>
                                                <Select.Option value="${NUMERO_CTA_HABERES}">
                                                    NÚMERO CUENTA (CTA. HABERES)
                                                </Select.Option>
                                                <Select.Option value="${NUMERO_CCI_CTA_HABERES}">
                                                    NÚMERO CUENTA CCI (CTA. HABERES)
                                                </Select.Option>
                                                <Select.Option value="${TIPO_MONEDA_CTA_HABERES}">
                                                    TIPO MONEDA (CTA. HABERES)
                                                </Select.Option>
                                            </Select.OptGroup>

                                            <Select.OptGroup label="CUENTA BANCARIA CTS">
                                                <Select.Option value="${BANCO_CTA_CTS}">BANCO (CTA. CTS)</Select.Option>
                                                <Select.Option value="${NUMERO_CTA_CTS}">
                                                    NÚMERO CUENTA (CTA. CTS)
                                                </Select.Option>
                                                <Select.Option value="${NUMERO_CCI_CTA_CTS}">
                                                    NÚMERO CUENTA CCI (CTA. CTS)
                                                </Select.Option>
                                                <Select.Option value="${TIPO_MONEDA_CTA_CTS}">
                                                    TIPO MONEDA (CTA. CTS)
                                                </Select.Option>
                                            </Select.OptGroup>

                                            <Select.OptGroup label="VACACIONES">
                                                <Select.Option value="${VACACIONES_DIAS_SOLICITADOS}">
                                                    DÍAS SOLICITADOS AUSENCIA
                                                </Select.Option>
                                                <Select.Option value="${VACACIONES_FECHA_INICIO}">
                                                    FECHA INICIO SOLICITUD AUSENCIA
                                                </Select.Option>
                                                <Select.Option value="${VACACIONES_FECHA_FIN}">
                                                    FECHA FIN SOLICITUD AUSENCIA
                                                </Select.Option>
                                                <Select.Option value="${VACACIONES_PERIODO_VACACIONAL_INICIO_FIN}">
                                                    PERIODO VACACIONAL
                                                </Select.Option>
                                            </Select.OptGroup>
                                            <Select.Option value="${PERSONALIZADO}">PERSONALIZADO</Select.Option>
                                        </Select>
                                    </Form.Item>

                                    {
                                        ("${FECHA_ACTUAL}".includes(defaultValue) ||
                                            "${FECHA_NACIMIENTO}".includes(defaultValue) ||
                                            "${FECHA_INGRESO}".includes(defaultValue)) && (
                                            <Form.Item name="formatDefaultValue" label="Personalizar">
                                                <Input />
                                            </Form.Item>
                                        )
                                    }

                                    {"${PERSONALIZADO}".includes(defaultValue)
                                        && controlSeleccionado.type === TipoControl.CAJA_TEXTO
                                        && (
                                            <Form.Item
                                                name="customDefaultValue"
                                                label="Personalizar"
                                                rules={[{ required: true }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        )
                                    }

                                    {"${PERSONALIZADO}".includes(defaultValue)
                                        && controlSeleccionado.type === TipoControl.ETIQUETA
                                        && (
                                            <Form.Item
                                                name="customDefaultValue"
                                                label="Personalizar"
                                                rules={[{ required: true }]}
                                            >
                                                <Input.TextArea />
                                            </Form.Item>
                                        )
                                    }

                                </Col>
                            </Row>
                        </React.Fragment>
                    )}

                {controlSeleccionado && controlSeleccionado.type === TipoControl.CHECKBOX && (
                    <React.Fragment>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item name="defaultValue" label="Valor Inicial">
                                    <Select style={{ width: "100%" }} allowClear={true}>
                                        <Select.Option value="checked">SELECCIONADO</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                {controlSeleccionado && controlSeleccionado.type === TipoControl.IMAGEN && (
                    <React.Fragment>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item name="defaultValue" label="Valor Inicial">
                                    <Select style={{ width: "100%" }} allowClear={true}>
                                        <Select.Option value="HUELLA_DACTILAR">HUELLA DACTILAR</Select.Option>
                                        <Select.Option value="FOTO">FOTO</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                {controlSeleccionado && controlSeleccionado.type === TipoControl.RADIO_BUTTON && (
                    <React.Fragment>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item name="defaultValue" label="Valor Inicial">
                                    <Select style={{ width: "100%" }} allowClear={true}>
                                        <Select.Option value="${GENERO}">GENERO</Select.Option>
                                        <Select.Option value="${ESTADO_CIVIL}">ESTADO CIVIL</Select.Option>
                                        <Select.Option value="${TIPO_TRABAJADOR}">TIPO TRABAJADOR</Select.Option>
                                        <Select.Option value="${REGIMEN_PENSIONARIO}">
                                            RÉGIMEN PENSIONARIO
                                        </Select.Option>
                                        {camposColaborador &&
                                            camposColaborador.length > 0 &&
                                            camposColaborador.map((campoColaborador) => {
                                                return (
                                                    <Select.Option
                                                        key={campoColaborador.campo}
                                                        value={"${" + campoColaborador.campo + "}"}
                                                    >
                                                        {campoColaborador.etiqueta.toUpperCase()}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                <Divider orientation="left" plain>
                    Posición y Tamaño
                </Divider>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="left"
                            label="Izquierda"
                            rules={[{ required: true, message: "El campo izquierda es requerido" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="top"
                            label="Arriba"
                            rules={[{ required: true, message: "El campo alto es requerido" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {/* <Col span={12}>
            <Form.Item
              name="topPdf"
              label="Arriba PDF"
              rules={[{ required: true, message: "El campo alto es requerido" }]}
            >
              <Input />
            </Form.Item>
          </Col> */}
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="width"
                            label="Ancho"
                            rules={[{ required: true, message: "El campo ancho es requerido" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="height"
                            label="Alto"
                            rules={[{ required: true, message: "El campo altura es requerido" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                {controlSeleccionado &&
                    controlSeleccionado.type !== TipoControl.SIGNATURE &&
                    controlSeleccionado.type !== TipoControl.IMAGEN && (
                        <React.Fragment>
                            <Divider orientation="left" plain>
                                Agrupar
                            </Divider>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="table" label="Nombre Tabla">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="column" label="Nombre Columna">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="rowNumber" label="Número Fila">
                                        <InputNumber min={1} max={20} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}

                <Divider orientation="left" plain>
                    Fuente
                </Divider>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="fuenteSize" label="Tamaño">
                            <InputNumber min={1} max={20} />
                        </Form.Item>
                        {/* <Form.Item
                            name="size"
                            label="size"
                        >
                            <Input />
                        </Form.Item> */}
                    </Col>
                </Row>

                <Divider orientation="left" style={{ fontWeight: "bold" }}>
                    Condicional
                </Divider>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="accion" label="Este control debe:">
                            <Select style={{ width: "100%" }} allowClear={true}>
                                <Select.Option value="show">MOSTRARSE</Select.Option>
                                <Select.Option value="hidden">OCULTARSE</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="control" label="Cuando el siguiente control:">
                            <Select style={{ width: "100%" }} showSearch allowClear={true} onSelect={onSelectControl}>
                                {/* {componentes &&
                                    componentes
                                        .filter(
                                            (componente) =>
                                                componente.type === "checkbox" || componente.type === "radiobutton"
                                        )
                                        .map((componente) => {
                                            return (
                                                <Select.Option key={componente.key} value={componente.key}>
                                                    {componente.label} ({componente.key})
                                                </Select.Option>
                                            );
                                        })} */}
                                {componentes &&
                                    componentes
                                        .filter((componente) => componente.type === TipoControl.CHECKBOX)
                                        .map((componente) => {
                                            return (
                                                <Select.Option key={componente.key} value={componente.key}>
                                                    {componente.label} ({componente.key})
                                                </Select.Option>
                                            );
                                        })}
                                {componentes &&
                                    componentes
                                        .filter((componente) => componente.type === TipoControl.RADIO_BUTTON)
                                        .filter(
                                            (componente, index, arr) =>
                                                arr.findIndex((e) => e.name === componente.name) === index
                                        )
                                        .map((componente) => {
                                            return (
                                                <Select.Option
                                                    key={componente.key + "|" + componente.name}
                                                    value={componente.name}
                                                >
                                                    {componente.name} ({"radiobutton"})
                                                </Select.Option>
                                            );
                                        })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        {componenteCondicionalSeleccionado?.type === "checkbox" && (
                            <Form.Item name="valor" label="Tenga el valor:">
                                <Select style={{ width: "100%" }} allowClear={true}>
                                    <Select.Option value="checked">SELECCIONADO</Select.Option>
                                </Select>
                            </Form.Item>
                        )}
                        {componenteCondicionalSeleccionado?.type === "radiobutton" && (
                            <Form.Item name="valor" label="Tenga el valor:">
                                <Input />
                                {/* <Select style={{ width: "100%" }} allowClear={true}>
                                    <Select.Option value={componenteCondicionalSeleccionado.value}>
                                        {componenteCondicionalSeleccionado.value}
                                    </Select.Option>
                                </Select> */}
                            </Form.Item>
                        )}
                    </Col>
                </Row>

                {/* <Divider orientation="left" style={{ fontWeight: "bold" }}>
                    ONBOARDING
                </Divider>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="formularioId" label="Seleccionar Formulario:">
                            <Select
                                style={{ width: "100%" }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear={true}
                            >
                                {formularios.length > 0 &&
                                    formularios.map((lista) => {
                                        return (
                                            <Option key={lista.id} value={lista.id}>
                                                {lista.nombre}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row> */}
            </Form>
        </Drawer>
    );
};

export default DrawerPropiedades;
