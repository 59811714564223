import React, { useEffect, useState, useContext } from "react";
import {
    colaboradorAtom,
} from "pages/legajo/legajoV3/LegajoColaboradorStore";
import { useAtom, useSetAtom } from "jotai";
import {TablaLegajoColaborador} from "components/TablaLegajoColaborador";
import { SecurityContext } from "context/SecurityContextProvider";
import LegajoService from "services/LegajoService";

const LegajoTab = () => {
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [colaborador] = useAtom(colaboradorAtom);
    const [documentosIniciales, setDocumentosIniciales] = useState(undefined)

    useEffect(() => {
        const onInit = async() =>{
            const _documentos = await LegajoService.listarDocumentosColaborador(
                colaborador?.numeroDocumentoIdentidad,
                getUsuarioAutenticado().empresaSeleccionada.id,
                getUsuarioAutenticado().login
              );
            setDocumentosIniciales(_documentos)
        }
     onInit()
    }, [])
    

    return (
        <React.Fragment>
           {documentosIniciales && <TablaLegajoColaborador listaDocumentos={documentosIniciales} numeroDocumento={colaborador?.numeroDocumentoIdentidad} />}
        </React.Fragment>
    );
};


export default LegajoTab;
