import React,{useRef} from "react";
import Highlighter from "react-highlight-words";
import { Table, Button, Modal, Tag, Popover, Spin, Typography, Row, Space, Input } from "antd";
import { useListarOnboardingIngreso } from "../hooks";
import FormularioSeguimientoService from "services/FormularioSeguimientoService";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import { MessageOutlined, FilePdfOutlined, CheckOutlined, CloseOutlined, SyncOutlined,
	SearchOutlined } from "@ant-design/icons";
import { EstadoVistaColaborador } from "enums/EstadoVistaColaborador";
import { OnboardingIngresoColaboradorService } from "services/OnboardingIngresoColaboradorService";
import { useState } from "react";
import MenuAcciones from "./MenuAcciones";
import { ModalVisorPdf } from "components/common";
import { useAtom, useSetAtom } from "jotai";
import {
	documentoSeleccionadoAtom,
	urlPdfAtom,
	visibleModalVisorPdfAtom,
} from "pages/legajo/legajoV3/LegajoColaboradorStore";
import { EstadoRegistro } from "enums/EstadoRegistro";

const tagStyle = {
	borderRadius: "15px",
};

const TablaSeguimientoOnboarding = () => {
	const { onboardings } = useListarOnboardingIngreso();
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	//const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
	const setVisibleModalVisorPdf = useSetAtom(visibleModalVisorPdfAtom);
	const setUrlPdf = useSetAtom(urlPdfAtom);
	const setDocumentoSeleccionado = useSetAtom(documentoSeleccionadoAtom);
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");

	const onClickVerDocumento = async (fila) => {
		setDocumentoSeleccionado(fila);
		if (fila?.estadoVistaColaborador !== "FIRMADO") {
			if (fila.categoriaDocumento.id === CategoriaDocumento.FORMULARIOS.id) {
				const respuesta = await FormularioSeguimientoService.getUrlPlantillaDocumento(
					fila.envio.tipoDocumentoId
				);
				setUrlPdf(respuesta.urlPlantilla);
				setVisibleModalVisorPdf(true);
			} else if (fila.categoriaDocumento.id === CategoriaDocumento.CARGA_DOCUMENTO.id) {
				Modal.error({
					title: "Falta completar",
					content: "El documento no ha sido cargado.",
				});
			}
		} else {
			try {
				const respuesta = await FormularioSeguimientoService.getUrlDocumento(
					getUsuarioAutenticado().empresaSeleccionada.id,
					fila.id
				);
				setUrlPdf(respuesta.urlDocumento);
				setVisibleModalVisorPdf(true);
			} catch (error) {
				console.error(error);
			}
		}
	};

	var adicionarColumnasFiltro = function getColumns(
		dataIndex,
		placeholder,
		propiedad
	  ) {
		return {
		  filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		  }) => (
			  <div style={{ padding: 8 }}>
				<Input
				  ref={searchInput}
				  placeholder={
					placeholder !== undefined
					  ? placeholder
					  : `Buscar por ${dataIndex}`
				  }
				  value={selectedKeys[0]}
				  onChange={(e) =>
					setSelectedKeys(e.target.value ? [e.target.value] : [])
				  }
				  onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
				  style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
				<Space>
				  <Button
					type="primary"
					onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				  >
					Buscar
				</Button>
				  <Button
					onClick={() => handleReset(clearFilters)}
					size="small"
					style={{ width: 90 }}
				  >
					Limpiar
				</Button>
				</Space>
			  </div>
			),
		  filterIcon: (filtered) => (
			<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
		  ),
		  onFilter: (value, record) =>
			propiedad === undefined
			  ? record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase())
			  : record[dataIndex][propiedad]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		  onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
			  setTimeout(() => searchInput.current.select());
			}
		  },
		  render: (text) =>
			searchedColumn === dataIndex ? (
			  <Highlighter
				highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={
				  propiedad === undefined
					? text?.toString()
					: text[propiedad]?.toString()
				}
			  />
			) : propiedad === undefined ? (
			  text
			) : (
				  text[propiedad]
				),
		};
	  };

	  function handleSearch(selectedKeys, confirm, dataIndex) {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	  }
	
	  function handleReset(clearFilters) {
		clearFilters();
		setSearchText("");
	  }

	const columnas = [
		{
			title: "Documento",
			width: "70%",
			...adicionarColumnasFiltro("nombreDocumento"),
			render: (fila) => (
				<div>
					<div style={{ display: "flex", alignItems: "center" }}>
						<FilePdfOutlined style={{ fontSize: "24px" }} />
						<Button type="text" onClick={() => onClickVerDocumento(fila)}>
							{fila.estadoVistaColaborador === EstadoVistaColaborador.OMITIDO && (
								<span style={{ textDecoration: "line-through" }}>{fila.nombreDocumento}</span>
							)}
							{fila.estadoVistaColaborador !== EstadoVistaColaborador.OMITIDO && (
								<span>{fila.nombreDocumento}</span>
							)}
						</Button>
					</div>
					{fila.interno && (
						<div style={{ alignItems: "center" }}>
							<Tag color="blue">Documento Interno</Tag>
						</div>
					)}
				</div>
			),
		},
		{
			title: "Documento",
			align: "center",
			render: (fila, text) =>
				fila.estadoRegistro !== EstadoRegistro.INACTIVO ? (
					<TagDocumento
						id={fila?.id}
						estado={fila?.estadoVistaColaborador}
						requeridoFlujoFirmas={fila?.requeridoFlujoFirmas}
						motivoOmision={fila?.motivoOmision}
						estadoAprobacion={fila?.estadoAprobacion}
					/>
				) : (
					<Tag color="red" style={tagStyle}>
						ANULADO
					</Tag>
				),
		},
		{
			title: "Aprobación",
			align: "center",
			render: (fila, text) =>
				fila.estadoRegistro !== EstadoRegistro.INACTIVO ? (
					<EstadoAprobacion estado={fila?.estadoAprobacion} />
				) : (
					<Tag color="red" style={tagStyle}>
						ANULADO
					</Tag>
				),
		},
		{
			title: "Acciones",
			align: "center",
			render: (fila) => <MenuAcciones documento={fila} />,
		},
	];

	return <Table rowKey={(item) => item.id} columns={columnas} dataSource={onboardings} pagination={false} />;
};

const TagDocumento = ({ id, estado, requeridoFlujoFirmas, motivoOmision, estadoAprobacion }) => {
	const [firmantes, setFirmantes] = useState([]);
	const [loading, setLoading] = useState(false);

	const onOpenChange = async (visible) => {
		if (visible) {
			setLoading(true);
			const response = await OnboardingIngresoColaboradorService.obtenerEstadoDocumento({
				detalleEnvioId: id,
			});
			setFirmantes(response);
			setLoading(false);
		}
	};

	if (estado === EstadoVistaColaborador.FIRMADO && estadoAprobacion != "RECHAZADO") {
		return (
			<Tag color="green" className="milegajo-ant-tag" style={tagStyle}>
				COMPLETADO
			</Tag>
		);
	} else if (estado === EstadoVistaColaborador.PENDIENTE || estadoAprobacion == "RECHAZADO") {
		return (
			<Popover
				title={null}
				content={loading ? <Spin /> : <TablaFirmantesPendientes firmantes={firmantes} />}
				trigger="click"
				placement="bottom"
				onOpenChange={onOpenChange}
			>
				<Space>
					{requeridoFlujoFirmas && <SyncOutlined style={{ color: "green", fontWeight: "bold" }} />}
					<Tag color="gold" className="milegajo-ant-tag" style={{ cursor: "pointer", ...tagStyle }}>
						PENDIENTE
					</Tag>
				</Space>
			</Popover>
		);
	} else if (estado === EstadoVistaColaborador.OMITIDO) {
		return (
			<Popover
				title={null}
				content={
					<Space>
						<MessageOutlined />
						{motivoOmision}
					</Space>
				}
				trigger="click"
				placement="bottom"
			>
				<Space>
					<Tag className="milegajo-ant-tag" color="red" style={{ cursor: "pointer", ...tagStyle }}>
						OMITIDO
					</Tag>
				</Space>
			</Popover>
		);
	}

	return null;
};

const TablaFirmantesPendientes = ({ firmantes }) => {
	if (firmantes && firmantes.length === 0) {
		return <span style={{ fontSize: "14px", color: "#778da9" }}>Sin Firmantes</span>;
	}

	const columnas = [
		{
			title: "Firmantes",
			render: (fila) => (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<span>{fila.colaborador}</span>
					<span>{fila.numeroDocumentoIdentidad}</span>
				</div>
			),
		},
		{
			title: "Estado",
			align: "center",
			// dataIndex: "estado",
			key: "estado",
			render: (fila) =>
				fila.estado === "COMPLETADO" ? (
					<Tag color="success" style={tagStyle}>
						{fila.estado}
					</Tag>
				) : (
					<Tag color="gold" style={tagStyle}>
						{fila.estado}
					</Tag>
				),
		},
	];

	return (
		<>
			<Table
				rowKey={(fila) => fila.numeroDocumentoIdentidad}
				dataSource={firmantes}
				columns={columnas}
				pagination={false}
				className="onboarding-firmantes"
			/>
		</>
	);
};

const EstadoAprobacion = ({ estado }) => {
	if (estado === null || estado === "SIN_APROBACION") {
		return <Tag style={tagStyle}>NO REQUIERE</Tag>;
	} else if (estado === "APROBADO") {
		return (
			<Tag color="green" style={tagStyle}>
				<CheckOutlined /> APROBADO
			</Tag>
		);
	} else if (estado === "RECHAZADO") {
		return (
			<Tag color="red" style={tagStyle}>
				<CloseOutlined /> RECHAZADO
			</Tag>
		);
	}
	return (
		<Tag color="gold" style={tagStyle}>
			PENDIENTE
		</Tag>
	);
};

export default TablaSeguimientoOnboarding;
