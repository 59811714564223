import { BaseService } from "./BaseService";

export const OnboardingIngresoColaboradorService = {
    async listarDetalleOnboardingIngreso(login, envioId, numeroDocumentoIdentidad) {
        const respuesta = await BaseService.get("/onboarding/colaborador/documentos", {
            params: {
                login: login,
                envioId: envioId,
                numeroDocumentoIdentidad: numeroDocumentoIdentidad,
            },
        });
        return respuesta.data;
    },

    async obtenerEstadoDocumento(params) {
        const respuesta = await BaseService.get("/onboarding/documentos/status", {
            params: {
                ...params,
            },
        });
        return respuesta.data;
    },

    async activarColaborador(data) {
        const respuesta = await BaseService.put("/onboarding/colaborador/activar", data);
        return respuesta.data;
    },

    async listarEnviosPorColaborador(params) {
        const respuesta = await BaseService.get("/onboarding/colaborador/envios", {
            params: {
                ...params,
            },
        });
        return respuesta.data;
    },
};
