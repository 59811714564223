import React, { useState } from "react";
import { useEffect } from "react";
import { ContainerCrear } from "../../../components/ContainerCrear";
import {
    Form,
    Input,
    Row,
    Col,
    Button,
    notification,
    Space,
    Switch,
    Transfer,
    Typography,
    Checkbox,
    Collapse,
    Divider,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import RolService from "../../../services/RolService";
import { CampoColaborador } from "enums/CampoColaborador";
import produce from "immer";
import { OPCIONES } from "enums";
import ParametrosService from "services/ParametrosService";

const ACCIONES = [
    {
        opcionPadre: "consulta.documento",
        datos: Object.values(OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS),
    },
    {
        opcionPadre: "notificacion.seguimiento.v2",
        datos: Object.values(OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES),
    },
    {
        opcionPadre: "notificacion.seguimiento.v2.ver-detalle",
        datos: Object.values(OPCIONES.PAGINA_DETALLE_NOTIFICACION),
    },
    {
        opcionPadre: "legajo.v2.consultas",
        datos: Object.values(OPCIONES.PAGINA_CONSULTAR_POR_CARPETAS),
    },    
];

export function RolEditarPage() {
    const [formularioEditar] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [porCompletar, setPorCompletar] = useState([]);
    const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState([]);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [estadoAprobador, setEstadoAprobador] = useState();
    const [indeterminateMantenimiento, setIndeterminateMantenimiento] = useState(false);
    const [indeterminateNotificacion, setIndeterminateNotificacion] = useState(false);
    const [indeterminateContrato, setIndeterminateContrato] = useState(false);
    const [indeterminateLegajos, setIndeterminateLegajos] = useState(false);
    //  const [indeterminateConsultaDocumento, setIndeterminateConsultaDocumento] = useState(false);
    const [indeterminateConfiguracion, setIndeterminateconfiguracion] = useState(false);
    const [indeterminateSolicitudPersonal, setIndeterminateSolicitudPersonal] = useState(false);
    const [indeterminateVacaciones, setIndeterminateVacaciones] = useState(false);

    const [checkAllMantenimiento, setCheckAllMantenimiento] = useState(false);
    const [checkAllNotificacion, setCheckAllNotificacion] = useState(false);
    const [checkAllContrato, setCheckAllContrato] = useState(false);
    const [checkAllLegajos, setCheckAllLegajos] = useState(false);
    //  const [checkAllConsultaDocumento, setCheckAllConsultaDocumento] = useState(false);
    const [checkAllConfiguracion, setCheckAllConfiguracion] = useState(false);
    const [checkAllSolicitudPersonal, setCheckAllSolicitudPersonal] = useState(false);
    const [checkAllVacaciones, setCheckAllVacaciones] = useState(false);

    const [opcionesSeleccionadasMantenimiento, setOpcionesSeleccionadasMantenimiento] = useState([]);
    const [opcionesSeleccionadasNotificaciones, setOpcionesSeleccionadasNotificaciones] = useState([]);
    const [opcionesSeleccionadasContratos, setOpcionesSeleccionadasContratos] = useState([]);
    const [opcionesSeleccionadasLegajos, setOpcionesSeleccionadasLegajos] = useState([]);
    const [opcionesSeleccionadasConfiguracion, setOpcionesSeleccionadasConfiguracion] = useState([]);
    const [opcionesSeleccionadasSolicitudPersonal, setOpcionesSeleccionadasSolicitudPersonal] = useState([]);
    const [opcionesSeleccionadasVacaciones, setOpcionesSeleccionadasVacaciones] = useState([]);

    const [accionesSeleccionadas, setAccionesSeleccionadas] = useState([]);

    const [opcionesNotificacion, setOpcionesNotificacion] = useState([]);
    const [campoPerfil, setCampoPerfil] = useState(undefined);
    const [campoSede, setCampoSede] = useState(undefined);
    const [campoSubsede, setCampoSubsede] = useState(undefined);
    const [campoSeccion, setCampoSeccion] = useState(undefined);
    const [campoTipoPlanilla, setCampoTipoPlanilla] = useState(undefined);
    const [campoCargo, setCampoCargo] = useState(undefined);
    const [opcionesMantenimientoVisibles, setOpcionesMantenimientoVisibles] = useState([]);
    const { Text } = Typography;
    const { Panel } = Collapse;
    let history = useHistory();
    let { idRol } = useParams();

    useEffect(() => {
        async function cargarDatos() {
            const consulta = await ParametrosService.notificacionCompletarActivo(
                getUsuarioAutenticado().empresaSeleccionada.id
            );
            setPorCompletar(consulta);
            const campos_colaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
            var _campoPerfil, _campoSede, _campoSubsede, _campoSeccion, _campoTipoPlanilla, _campoCargo;
            campos_colaborador.map((item) => {
                if (item.campo === CampoColaborador.PERFIL) {
                    setCampoPerfil(item);
                    _campoPerfil = item;
                } else if (item.campo === CampoColaborador.SEDE) {
                    setCampoSede(item);
                    _campoSede = item;
                } else if (item.campo === CampoColaborador.SUBSEDE) {
                    setCampoSubsede(item);
                    _campoSubsede = item;
                } else if (item.campo === CampoColaborador.SECCION) {
                    setCampoSeccion(item);
                    _campoSeccion = item;
                } else if (item.campo === CampoColaborador.PLANILLA) {
                    setCampoTipoPlanilla(item);
                    _campoTipoPlanilla = item;
                } else if (item.campo === CampoColaborador.CARGO) {
                    setCampoCargo(item);
                    _campoCargo = item;
                }
            });
            const opcionesMantenimiento = [
                "mantenimiento",
                "mantenimiento.sede",
                "mantenimiento.cargo",
                "mantenimiento.colaborador",
                "mantenimiento.usuario",
                "mantenimiento.rol",
                "mantenimiento.seccion",
                "mantenimiento.subsede",
                "mantenimiento.perfil",
                "mantenimiento.tipoPlanilla",
                "mantenimiento.colaborador.reporte.ver-sueldo",
                "mantenimiento.colaborador.editar.ver-sueldo"
            ];
            let listaOpcionesMantenimientoVisibles = opcionesMantenimiento;
            if (_campoSede === undefined || _campoSede.visible === false) {
                listaOpcionesMantenimientoVisibles = listaOpcionesMantenimientoVisibles.filter(
                    (opcion) => opcion !== "mantenimiento.sede"
                );
            }
            if (_campoPerfil === undefined || _campoPerfil.visible === false) {
                listaOpcionesMantenimientoVisibles = listaOpcionesMantenimientoVisibles.filter(
                    (opcion) => opcion !== "mantenimiento.perfil"
                );
            }
            if (_campoSubsede === undefined || _campoSubsede.visible === false) {
                listaOpcionesMantenimientoVisibles = listaOpcionesMantenimientoVisibles.filter(
                    (opcion) => opcion !== "mantenimiento.subsede"
                );
            }
            if (_campoSeccion === undefined || _campoSeccion.visible === false) {
                listaOpcionesMantenimientoVisibles = listaOpcionesMantenimientoVisibles.filter(
                    (opcion) => opcion !== "mantenimiento.seccion"
                );
            }
            if (_campoTipoPlanilla === undefined || _campoTipoPlanilla.visible === false) {
                listaOpcionesMantenimientoVisibles = listaOpcionesMantenimientoVisibles.filter(
                    (opcion) => opcion !== "mantenimiento.tipoPlanilla"
                );
            }
            if (_campoCargo === undefined || _campoCargo.visible === false) {
                listaOpcionesMantenimientoVisibles = listaOpcionesMantenimientoVisibles.filter(
                    (opcion) => opcion !== "mantenimiento.cargo"
                );
            }
            setOpcionesMantenimientoVisibles(listaOpcionesMantenimientoVisibles);

            const rol = await RolService.buscarPorId(idRol);
            formularioEditar.setFieldsValue({
                id: rol.id,
                nombre: rol.nombre,
            });
            setOpcionesSeleccionadas(rol.opciones);
            setEstadoAprobador(rol.aprobador);

            if (consulta) {
                opcionesNotificacionesAlternativo.push("notificacion.completar");
                opcionesNotificaciones.push("notificacion.completar");
            }
            if (
                getUsuarioAutenticado().subdominio.includes("ingenioti") === false &&
                getUsuarioAutenticado().subdominio.includes("antalis") === false &&
                getUsuarioAutenticado().subdominio.includes("demo") === false
            ) {
                setOpcionesNotificacion(opcionesNotificaciones);
            } else {
                setOpcionesNotificacion(opcionesNotificacionesAlternativo);
            }
            var opcionesListadasMantenimiento = rol.opciones.filter(
                (opcion) =>
                    opcion === "mantenimiento" ||
                    opcion === "mantenimiento.sede" ||
                    opcion === "mantenimiento.cargo" ||
                    opcion === "mantenimiento.colaborador" ||
                    opcion === "mantenimiento.usuario" ||
                    opcion === "mantenimiento.rol" ||
                    opcion === "mantenimiento.seccion" ||
                    opcion === "mantenimiento.subsede" ||
                    opcion === "mantenimiento.perfil" ||
                    opcion === "mantenimiento.tipoPlanilla"||
                    opcion === "mantenimiento.colaborador.reporte.ver-sueldo" ||
                    opcion === "mantenimiento.colaborador.editar.ver-sueldo" 
            );
            setOpcionesSeleccionadasMantenimiento(opcionesListadasMantenimiento);
            var opcionesListadasNotificaciones = rol.opciones.filter(
                (opcion) =>
                    opcion === "notificacion" ||
                    opcion === "notificacion.crear" ||
                    // opcion === "notificacion.seguimiento" ||

                    opcion === "notificacion.seguimiento.formularios" ||
                    //opcion === "notificacion.comunicados" ||
                    opcion === "notificacion.comunicados.crear" ||
                    // opcion === "notificacion.comunicados.listar" ||
                    opcion === "notificacion.completar" ||
                    opcion === "notificacion.firma-documento-generico" ||
                    opcion === "notificacion.firma-documento-generico.crear" ||
                    opcion === "notificacion.firma-documento-generico.listar" ||
                    opcion === "notificacion.firma-documento-generico.bandeja-aprobador" ||
                    opcion === "notificacion.evaluacion"
            );
            setOpcionesSeleccionadasNotificaciones(opcionesListadasNotificaciones);

            var opcionesListadasContrato = rol.opciones.filter(
                (opcion) =>
                    opcion === "contrato" ||
                    opcion === "contrato.crear" ||
                    opcion === "contrato.seguimiento" ||
                    opcion === "contrato.registrarPlantilla" ||
                    opcion === "explomin.contrato.seguimiento.administracionpersonal" ||
                    opcion === "explomin.contrato.seguimiento.rys"
            );
            setOpcionesSeleccionadasContratos(opcionesListadasContrato);

            var opcionesListadasLegajos = rol.opciones.filter(
                (opcion) =>
                    opcion === "legajos" ||
                    opcion === "legajos.consultas" ||
                    opcion === "legajo.v2.consultas" ||
                    opcion === "notificacion.seguimiento.v2" ||
                    opcion === "consulta.documento" ||
                    opcion === "legajos.observacionesColaborador" ||
                    opcion === "legajos.consultaOnboarding" ||
                    opcion === "legajos.seguimientoOnboarding" ||
                    opcion === "legajos.reportes" ||
                    //  opcion === "ausencias.solicitudes" ||
                    //  opcion === "vacaciones.control-vacacional" ||
                    opcion === "legajos.seguimientoEvaluaciones" ||
                    opcion === "consulta.seguimientoContrato"
            );
            setOpcionesSeleccionadasLegajos(opcionesListadasLegajos);

            var opcionesListadasConfiguracion = rol.opciones.filter(
                (opcion) =>
                    opcion === "configuracion" ||
                    opcion === "configuracion.formularios" ||
                    opcion === "configuracion.misCertificados" ||
                    opcion === "configuracion.certificadosDigitales" ||
                    opcion === "configuracion.tipoDocumentos" ||
                    opcion === "configuracion.carpetasLegajos" ||
                    opcion === "configuracion.MiFirmaHolografa" ||
                    opcion === "configuracion.verficarDatosColaborador" ||
                    opcion === "configuracion.plantillaEnvioDocumentos" ||
                    opcion === "configuracion.onBoardingIndividual"
                // opcion === "configuracion.tipoAbsentismo"
            );
            setOpcionesSeleccionadasConfiguracion(opcionesListadasConfiguracion);

            var opcionesListadasReclutamientoSeleccion = rol.opciones.filter(
                (opcion) =>
                    opcion === "reclutamiento-y-seleccion" ||
                    opcion === "reclutamiento-y-seleccion.solicitud-personal.perfiles" ||
                    opcion === "reclutamiento-y-seleccion.postulantes" ||
                    opcion === "reclutamiento-y-seleccion.convocatoria" ||
                    opcion === "reclutamiento-y-seleccion.plantillas" ||
                    opcion === "reclutamiento-y-seleccion.procesos" ||
                    opcion === "reclutamiento-y-seleccion.solicitud-personal.solicitudes" ||
                    opcion === "reclutamiento-y-seleccion.solicitud-personal.entrevistas" ||
                    opcion === "reclutamiento-y-seleccion.solicitud-personal.reportes"
            );
            setOpcionesSeleccionadasSolicitudPersonal(opcionesListadasReclutamientoSeleccion);

            var opcionesListadasVacaciones = rol.opciones.filter(
                (opcion) =>
                    opcion === "vacaciones-y-ausencias" ||
                    opcion === "configuracion.tipoAbsentismo" ||
                    opcion === "ausencias.solicitudes" ||
                    opcion === "vacaciones.control-vacacional" ||
                    opcion === "vacaciones-y-ausencias.ausencias.solicitudes.crear-solicitud" ||
                    opcion === "vacaciones-y-ausencias.ausencias.solicitudes.exportar-solicitudes"
            );
            setOpcionesSeleccionadasVacaciones(opcionesListadasVacaciones);

            setIndeterminateMantenimiento(
                !!opcionesListadasMantenimiento.length &&
                    opcionesListadasMantenimiento.length < listaOpcionesMantenimientoVisibles.length
            );
            // setIndeterminateNotificacion(
            //   !!opcionesListadasNotificaciones.length && opcionesListadasNotificaciones.length < opcionesNotificaciones.length
            // );
            setIndeterminateContrato(
                !!opcionesListadasContrato.length && opcionesListadasContrato.length < opcionesContrato.length
            );
            setIndeterminateLegajos(
                !!opcionesListadasLegajos.length && opcionesListadasLegajos.length < opcionesLegajos.length
            );
            setIndeterminateconfiguracion(
                !!opcionesListadasConfiguracion.length &&
                    opcionesListadasConfiguracion.length < opcionesConfiguracion.length
            );
            setIndeterminateSolicitudPersonal(
                !!opcionesListadasReclutamientoSeleccion.length &&
                    opcionesListadasReclutamientoSeleccion.length < opcionesReclutamientoSeleccion.length
            );
            setIndeterminateVacaciones(
                !!opcionesListadasVacaciones.length && opcionesListadasVacaciones.length < opcionesVacaciones.length
            );

            setCheckAllMantenimiento(
                opcionesListadasMantenimiento.length === listaOpcionesMantenimientoVisibles.length
            );

            if (
                getUsuarioAutenticado().subdominio.includes("antalis-dev") === false &&
                getUsuarioAutenticado().subdominio.includes("demo-dev") === false
            ) {
                setIndeterminateNotificacion(
                    !!opcionesListadasNotificaciones.length &&
                        opcionesListadasNotificaciones.length < opcionesNotificaciones.length
                );
                setCheckAllNotificacion(opcionesListadasNotificaciones.length === opcionesNotificaciones.length);
            } else {
                setIndeterminateNotificacion(
                    !!opcionesListadasNotificaciones.length &&
                        opcionesListadasNotificaciones.length < opcionesNotificacionesAlternativo.length
                );
                setCheckAllNotificacion(
                    opcionesListadasNotificaciones.length === opcionesNotificacionesAlternativo.length
                );
            }
            //setCheckAllNotificacion(opcionesListadasNotificaciones.length === opcionesNotificaciones.length);
            setCheckAllContrato(opcionesListadasContrato.length === opcionesContrato.length);
            setCheckAllLegajos(opcionesListadasLegajos.length === opcionesLegajos.length);
            setCheckAllConfiguracion(opcionesListadasConfiguracion.length === opcionesConfiguracion.length);
            setCheckAllSolicitudPersonal(
                opcionesListadasReclutamientoSeleccion.length === opcionesReclutamientoSeleccion.length
            );
            setCheckAllVacaciones(opcionesListadasVacaciones.length === opcionesVacaciones.length);

            setAccionesSeleccionadas(
                produce((draft) => {
                    ACCIONES.forEach((accion) => {
                        const accionesRol = rol.opciones.filter((opcion) => accion.datos.includes(opcion));
                        if (accionesRol === undefined) {
                            draft.push({ opcionPadre: accion.opcionPadre, datos: [] });
                        } else {
                            draft.push({ opcionPadre: accion.opcionPadre, datos: accionesRol });
                        }
                    });
                })
            );
        }
        cargarDatos();
    }, []);

    async function handleOnFinish(rol) {
        try {
            setLoading(true);
            var opciones_ = [
                ...opcionesSeleccionadasMantenimiento.slice(),
                ...opcionesSeleccionadasNotificaciones.slice(),
                ...opcionesSeleccionadasContratos.slice(),
                ...opcionesSeleccionadasLegajos.slice(),
                ...opcionesSeleccionadasConfiguracion.slice(),
                ...opcionesSeleccionadasSolicitudPersonal.slice(),
                ...opcionesSeleccionadasVacaciones.slice(),
            ];

            accionesSeleccionadas.forEach((accionSeleccionada) => {
                opciones_ = [...opciones_, ...accionSeleccionada.datos];
            });

            rol.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
            rol.opciones = opciones_;
            rol.aprobador = estadoAprobador;
            await RolService.actualizar(rol);
            notification.success({
                message: "Operación exitosa",
                description: "Se actualizó el rol correctamente.",
            });
            setLoading(false);
            history.push("/rol");
        } catch (error) {
            console.error(error);
            notification.error({
                message: "No se puede actualizar el Rol",
                description: error.response.data.mensaje,
            });
            setLoading(false);
        }
    }

    const opcionesNotificaciones = [
        "notificacion",
        "notificacion.crear",
        "notificacion.seguimiento.formularios",
        //"notificacion.comunicados",
        "notificacion.comunicados.crear",
        "notificacion.evaluacion",
    ];

    const opcionesNotificacionesAlternativo = [
        "notificacion",
        "notificacion.crear",
        //"notificacion.seguimiento",
        "notificacion.seguimiento.formularios",
        //"notificacion.comunicados",
        "notificacion.comunicados.crear",
        "notificacion.evaluacion",
        "notificacion.firma-documento-generico",
        "notificacion.firma-documento-generico.crear",
        "notificacion.firma-documento-generico.listar",
        "notificacion.firma-documento-generico.bandeja-aprobador",
    ];

    // if(porCompletar){
    //   opcionesNotificacionesAlternativo.push("notificacion.completar")
    //   opcionesNotificaciones.push("notificacion.completar")
    // }

    const opcionesContrato = [
        "contrato",
        "contrato.crear",
        "contrato.seguimiento",
        "contrato.registrarPlantilla",
        "explomin.contrato.seguimiento.administracionpersonal",
        "explomin.contrato.seguimiento.rys",
    ];
    const opcionesLegajos = [
        "legajos",
        "legajos.consultas",
        "legajo.v2.consultas",
        "notificacion.seguimiento.v2",
        "consulta.documento",
        "legajos.observacionesColaborador",
        "legajos.consultaOnboarding",
        "legajos.seguimientoOnboarding",
        "legajos.reportes",
        // "ausencias.solicitudes",
        // "vacaciones.control-vacacional",
        "legajos.seguimientoEvaluaciones",
        "consulta.seguimientoContrato",
    ];

    const opcionesConfiguracion = [
        "configuracion",
        "configuracion.formularios",
        "configuracion.misCertificados",
        "configuracion.MiFirmaHolografa",
        "configuracion.certificadosDigitales",
        "configuracion.tipoDocumentos",
        "configuracion.carpetasLegajos",
        "configuracion.verficarDatosColaborador",
        "configuracion.plantillaEnvioDocumentos",
        "configuracion.onBoardingIndividual",
        // "configuracion.tipoAbsentismo",
    ];
    const opcionesReclutamientoSeleccion = [
        "reclutamiento-y-seleccion",
        "reclutamiento-y-seleccion.postulantes",
        "reclutamiento-y-seleccion.convocatoria",
        "reclutamiento-y-seleccion.plantillas",
        "reclutamiento-y-seleccion.procesos",
        //"solicitudPersonal.perfil",
        //"reclutamiento-y-seleccion.seguimientoSolicitudPersonal",
        "reclutamiento-y-seleccion.solicitud-personal.perfiles",
        "reclutamiento-y-seleccion.solicitud-personal.solicitudes",
        "reclutamiento-y-seleccion.solicitud-personal.entrevistas",
        "reclutamiento-y-seleccion.solicitud-personal.reportes",
    ];

    const opcionesVacaciones = [
        "vacaciones-y-ausencias",
        "configuracion.tipoAbsentismo",
        "ausencias.solicitudes",
        "vacaciones.control-vacacional",
        "vacaciones-y-ausencias.ausencias.solicitudes.crear-solicitud",
        "vacaciones-y-ausencias.ausencias.solicitudes.exportar-solicitudes",
    ];

    function handleOnChangeOpciones(targetKeys, direction, moveKeys) {
        setOpcionesSeleccionadas(targetKeys);
    }

    function handleOnClickCancelar() {
        history.push("/rol");
    }
    function onChangeAprobador(checked) {
        setEstadoAprobador(checked);
    }
    function onCheckTodosMantenimiento(e) {
        setOpcionesSeleccionadasMantenimiento(e.target.checked ? opcionesMantenimientoVisibles : []);
        setIndeterminateMantenimiento(false);
        setCheckAllMantenimiento(e.target.checked);
    }
    function onChangeOpcionMantenimiento(lista) {
        if (lista.length > 0) {
            lista.push("mantenimiento");
            setOpcionesSeleccionadasMantenimiento(lista);
        } else {
            setOpcionesSeleccionadasMantenimiento(lista);
        }
        setIndeterminateMantenimiento(!!lista.length && lista.length < opcionesMantenimientoVisibles.length);
        setCheckAllMantenimiento(lista.length === opcionesMantenimientoVisibles.length);
    }

    function onCheckTodosNotificacion(e) {
        setOpcionesSeleccionadasNotificaciones(e.target.checked ? opcionesNotificacion : []);
        setIndeterminateNotificacion(false);
        setCheckAllNotificacion(e.target.checked);
    }

    function onChangeOpcionNotificacion(lista) {
        if (lista.length > 0) {
            lista.push("notificacion");
            setOpcionesSeleccionadasNotificaciones(lista);
        } else {
            setOpcionesSeleccionadasNotificaciones(lista);
        }

        setIndeterminateNotificacion(!!lista.length && lista.length < opcionesNotificacion.length);
        setCheckAllNotificacion(lista.length === opcionesNotificacion.length);
    }

    function onCheckTodosContrato(e) {
        setOpcionesSeleccionadasContratos(e.target.checked ? opcionesContrato : []);
        setIndeterminateContrato(false);
        setCheckAllContrato(e.target.checked);
    }
    function onChangeOpcionContrato(lista) {
        if (lista.length > 0) {
            lista.push("contrato");
            setOpcionesSeleccionadasContratos(lista);
        } else {
            setOpcionesSeleccionadasContratos(lista);
        }
        setIndeterminateContrato(!!lista.length && lista.length < opcionesContrato.length);
        setCheckAllContrato(lista.length === opcionesContrato.length);
    }
    function onCheckTodosLegajos(e) {
        setOpcionesSeleccionadasLegajos(e.target.checked ? opcionesLegajos : []);
        setIndeterminateLegajos(false);
        setCheckAllLegajos(e.target.checked);
    }
    function onChangeOpcionLegajo(lista) {
        if (lista.length > 0) {
            lista.push("legajos");
            setOpcionesSeleccionadasLegajos(lista);
        } else {
            setOpcionesSeleccionadasLegajos(lista);
        }
        setIndeterminateLegajos(!!lista.length && lista.length < opcionesLegajos.length);
        setCheckAllLegajos(lista.length === opcionesLegajos.length);
    }
    function onCheckTodosConfiguracion(e) {
        setOpcionesSeleccionadasConfiguracion(e.target.checked ? opcionesConfiguracion : []);
        setIndeterminateconfiguracion(false);
        setCheckAllConfiguracion(e.target.checked);
    }
    function onChangeOpcionConfiguracion(lista) {
        if (lista.length > 0) {
            lista.push("configuracion");
            setOpcionesSeleccionadasConfiguracion(lista);
        } else {
            setOpcionesSeleccionadasConfiguracion(lista);
        }
        setIndeterminateconfiguracion(!!lista.length && lista.length < opcionesConfiguracion.length);
        setCheckAllConfiguracion(lista.length === opcionesConfiguracion.length);
    }

    const onCheckTodosSolicitudPersonal = (e) => {
        setOpcionesSeleccionadasSolicitudPersonal(e.target.checked ? opcionesReclutamientoSeleccion : []);
        setIndeterminateSolicitudPersonal(false);
        setCheckAllSolicitudPersonal(e.target.checked);
    };

    const onChangeOpcionSolicitudPersonal = (lista) => {
        if (lista.length > 0) {
            lista.push("reclutamiento-y-seleccion");
            setOpcionesSeleccionadasSolicitudPersonal(lista);
        } else {
            setOpcionesSeleccionadasSolicitudPersonal(lista);
        }
        setIndeterminateSolicitudPersonal(!!lista.length && lista.length < opcionesReclutamientoSeleccion.length);
        setCheckAllSolicitudPersonal(lista.length === opcionesReclutamientoSeleccion.length);
    };

    const onCheckTodosVacaciones = (e) => {
        setOpcionesSeleccionadasVacaciones(e.target.checked ? opcionesVacaciones : []);
        setIndeterminateVacaciones(false);
        setCheckAllVacaciones(e.target.checked);
    };

    const onChangeOpcionVacaciones = (lista) => {
        if (lista.length > 0) {
            lista.push("vacaciones-y-ausencias");
            setOpcionesSeleccionadasVacaciones(lista);
        } else {
            setOpcionesSeleccionadasVacaciones(lista);
        }
        setIndeterminateVacaciones(!!lista.length && lista.length < opcionesVacaciones.length);
        setCheckAllVacaciones(lista.length === opcionesVacaciones.length);
    };

    const onChangeAccionesSeleccionadas = (opcionesSeleccionadas, opcionPadre) => {
        setAccionesSeleccionadas(
            produce((draft) => {
                const todasLasAcciones = ACCIONES.find((accion) => accion.opcionPadre === opcionPadre).datos;
                const accion = draft.find((opcion) => opcion.opcionPadre === opcionPadre);
                if (accion) {
                    accion.datos = opcionesSeleccionadas;
                    // accion.indeterminate =
                    //   !!opcionesSeleccionadas.length && opcionesSeleccionadas.length < todasLasAcciones.length;
                } else {
                    draft.push({
                        opcionPadre: opcionPadre,
                        datos: opcionesSeleccionadas,
                        // indeterminate: !!opcionesSeleccionadas.length && opcionesSeleccionadas.length < todasLasAcciones.length,
                    });
                }
            })
        );
    };

    const onChangeSeleccionarSubMenu = (submenu) => {
        console.log("submenu : " + submenu);
        setAccionesSeleccionadas(
            produce((draft) => {
                if (submenu.target.checked === false) {
                    const accionSeleccionada = draft.find((accion) => accion.opcionPadre === submenu.target.value);
                    accionSeleccionada.datos = [];
                } else {
                    const todasLasAcciones = ACCIONES.find(
                        (accion) => accion.opcionPadre === submenu.target.value
                    ).datos;
                    const accionSeleccionada = draft.find((accion) => accion.opcionPadre === submenu.target.value);
                    accionSeleccionada.datos = todasLasAcciones;
                }
            })
        );
    };

    return (
        <ContainerCrear
            titulo="Editar Rol"
            descripcion="En este formulario podrás editar la información del Rol."
            colSpan={12}
        >
            <Form form={formularioEditar} layout="vertical" size="large" onFinish={handleOnFinish}>
                <Form.Item name="id" label="id" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Nombre"
                            name="nombre"
                            rules={[
                                {
                                    required: true,
                                    message: "Ingrese el nombre del rol",
                                },
                            ]}
                        >
                            <Input placeholder="Escribe el nombre del rol" autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Aprobador" name="aprobador">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                onChange={onChangeAprobador}
                                checked={estadoAprobador}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Opciones" key="1">
                        <Row>
                            <Checkbox
                                indeterminate={indeterminateMantenimiento}
                                onChange={onCheckTodosMantenimiento}
                                checked={checkAllMantenimiento}
                                style={{ color: "#1678c1" }}
                            >
                                MANTENIMIENTO
                            </Checkbox>
                        </Row>

                        <Row>
                            <Col span={1}></Col>
                            <Col span={23}>
                                <Checkbox.Group
                                    value={opcionesSeleccionadasMantenimiento}
                                    onChange={onChangeOpcionMantenimiento}
                                >
                                    <Row>
                                        {campoSede !== undefined && campoSede.visible === true && (
                                            <Col span={24}>
                                                <Checkbox value="mantenimiento.sede">Centro de costo</Checkbox>
                                            </Col>
                                        )}
                                        {campoCargo !== undefined && campoCargo.visible === true && (
                                            <Col span={24}>
                                                <Checkbox value="mantenimiento.cargo">Cargo</Checkbox>
                                            </Col>
                                        )}
                                        <Col span={24}>
                                            <Checkbox value="mantenimiento.colaborador">Colaborador</Checkbox>
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={23}>
                                            <Checkbox
                                                value={
                                                    OPCIONES.PAGINA_MANTENIMIENTO_COLABORADOR
                                                        .TXT_EDITAR_VER_SUELDO
                                                }
                                            >
                                                Editar ver sueldo
                                            </Checkbox>
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={23}>
                                            <Checkbox
                                                value={
                                                    OPCIONES.PAGINA_MANTENIMIENTO_COLABORADOR
                                                        .TXT_REPORTE_VER_SUELDO
                                                }
                                            >
                                                Reporte ver sueldo
                                            </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="mantenimiento.usuario">Usuario</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="mantenimiento.rol">Rol</Checkbox>
                                        </Col>
                                        {campoSeccion !== undefined && campoSeccion.visible === true && (
                                            <Col span={24}>
                                                <Checkbox value="mantenimiento.seccion">Sección</Checkbox>
                                            </Col>
                                        )}
                                        {campoSubsede !== undefined && campoSubsede.visible === true && (
                                            <Col span={24}>
                                                <Checkbox value="mantenimiento.subsede">Subcentro de costo</Checkbox>
                                            </Col>
                                        )}
                                        {campoPerfil !== undefined && campoPerfil.visible === true && (
                                            <Col span={24}>
                                                <Checkbox value="mantenimiento.perfil">Perfil</Checkbox>
                                            </Col>
                                        )}
                                        {campoTipoPlanilla !== undefined && campoTipoPlanilla.visible === true && (
                                            <Col span={24}>
                                                <Checkbox value="mantenimiento.tipoPlanilla">Tipo Planilla</Checkbox>
                                            </Col>
                                        )}
                                    </Row>
                                </Checkbox.Group>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Checkbox
                                indeterminate={indeterminateNotificacion}
                                onChange={onCheckTodosNotificacion}
                                checked={checkAllNotificacion}
                                style={{ color: "#1678c1" }}
                            >
                                NOTIFICACIONES
                            </Checkbox>
                        </Row>
                        <Row>
                            <Col span={1}></Col>
                            <Col span={23}>
                                <Checkbox.Group
                                    value={opcionesSeleccionadasNotificaciones}
                                    onChange={onChangeOpcionNotificacion}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="notificacion.crear">
                                                Notificar Documentos Laborales
                                            </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="notificacion.seguimiento.formularios">
                                                Notificar Formularios
                                            </Checkbox>
                                        </Col>

                                        <Col span={24}>
                                            <Checkbox value="notificacion.comunicados.crear">
                                                Notificar comunicados
                                            </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="notificacion.evaluacion">Notificar Evaluación</Checkbox>
                                        </Col>
                                        {porCompletar && (
                                            <Col span={24}>
                                                <Checkbox value="notificacion.completar">
                                                    Notificación por Completar
                                                </Checkbox>
                                            </Col>
                                        )}
                                        {(getUsuarioAutenticado().subdominio.includes("ingenioti") ||
                                            getUsuarioAutenticado().subdominio.includes("antalis") ||
                                            getUsuarioAutenticado().subdominio.includes("demo")) && (
                                            <React.Fragment>
                                                <Col span={24}>
                                                    <Checkbox value="notificacion.firma-documento-generico">
                                                        Firma Documento Genérico
                                                    </Checkbox>
                                                </Col>
                                                <Col span={24}>
                                                    <Checkbox value="notificacion.firma-documento-generico.crear">
                                                        Crear Documentos Genéricos
                                                    </Checkbox>
                                                </Col>
                                                <Col span={24}>
                                                    <Checkbox value="notificacion.firma-documento-generico.listar">
                                                        Listar Documentos Genéricos
                                                    </Checkbox>
                                                </Col>
                                                <Col span={24}>
                                                    <Checkbox value="notificacion.firma-documento-generico.bandeja-aprobador">
                                                        Mis Documentos
                                                    </Checkbox>
                                                </Col>
                                            </React.Fragment>
                                        )}
                                    </Row>
                                </Checkbox.Group>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Checkbox
                                indeterminate={indeterminateContrato}
                                onChange={onCheckTodosContrato}
                                checked={checkAllContrato}
                                style={{ color: "#1678c1" }}
                            >
                                CONTRATO
                            </Checkbox>
                        </Row>
                        <Row>
                            <Col span={1}></Col>
                            <Col span={23}>
                                <Checkbox.Group
                                    value={opcionesSeleccionadasContratos}
                                    onChange={onChangeOpcionContrato}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="contrato.crear">Crear contrato</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="contrato.seguimiento">Seguimiento contrato</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="contrato.registrarPlantilla">Registrar Plantilla</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="explomin.contrato.seguimiento.administracionpersonal">
                                                Bandeja de Aprobación Administración Personal
                                            </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="explomin.contrato.seguimiento.rys">
                                                Bandeja de Aprobación Reclutamiento y Selección
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Checkbox
                                indeterminate={indeterminateLegajos}
                                onChange={onCheckTodosLegajos}
                                checked={checkAllLegajos}
                                style={{ color: "#1678c1" }}
                            >
                                CONSULTAR
                            </Checkbox>
                        </Row>
                        <Row>
                            <Col span={1}></Col>
                            <Col span={23}>
                                <Checkbox.Group value={opcionesSeleccionadasLegajos} onChange={onChangeOpcionLegajo}>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="legajos.consultas">Consultar Legajos</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="notificacion.seguimiento.v2">Notificaciones</Checkbox>
                                        </Col>
                                    </Row>
                                    <div style={{ marginLeft: "20px" }}>
                                        <Checkbox.Group
                                            value={
                                                accionesSeleccionadas?.find(
                                                    (accion) => accion.opcionPadre === "notificacion.seguimiento.v2"
                                                )?.datos
                                            }
                                            onChange={(opciones) =>
                                                onChangeAccionesSeleccionadas(opciones, "notificacion.seguimiento.v2")
                                            }
                                        >
                                            <Row>
                                                <Col span={24}>
                                                    <Checkbox
                                                        value={
                                                            OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES
                                                                .BOTON_VER_DETALLE
                                                        }
                                                        onChange={onChangeSeleccionarSubMenu}
                                                    >
                                                        Ver Detalle (Página)
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                            <div style={{ marginLeft: "20px" }}>
                                                <Checkbox.Group
                                                    value={
                                                        accionesSeleccionadas?.find(
                                                            (accion) =>
                                                                accion.opcionPadre ===
                                                                OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES
                                                                    .BOTON_VER_DETALLE
                                                        )?.datos
                                                    }
                                                    onChange={(opciones) =>
                                                        onChangeAccionesSeleccionadas(
                                                            opciones,
                                                            OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES
                                                                .BOTON_VER_DETALLE
                                                        )
                                                    }
                                                >
                                                    <Row>
                                                        <Col span={24}>
                                                            <Checkbox
                                                                value={
                                                                    OPCIONES.PAGINA_DETALLE_NOTIFICACION
                                                                        .BOTON_VER_DOCUMENTO
                                                                }
                                                            >
                                                                Ver Documento (Botón)
                                                            </Checkbox>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Checkbox
                                                                value={
                                                                    OPCIONES.PAGINA_DETALLE_NOTIFICACION
                                                                        .BOTON_REENVIAR_DOCUMENTO
                                                                }
                                                            >
                                                                Reenviar Documento (Botón)
                                                            </Checkbox>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Checkbox
                                                                value={
                                                                    OPCIONES.PAGINA_DETALLE_NOTIFICACION
                                                                        .BOTON_ACTUALIZAR_CORREO
                                                                }
                                                            >
                                                                Actualizar Correo (Botón)
                                                            </Checkbox>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Checkbox
                                                                value={
                                                                    OPCIONES.PAGINA_DETALLE_NOTIFICACION
                                                                        .BOTON_HISTORIAL_NOTIFICACION
                                                                }
                                                            >
                                                                Historial Notificación (Botón)
                                                            </Checkbox>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Checkbox
                                                                value={
                                                                    OPCIONES.PAGINA_DETALLE_NOTIFICACION
                                                                        .BOTON_INHABILITAR_DOCUMENTO
                                                                }
                                                            >
                                                                Inhabilitar Documento (Botón)
                                                            </Checkbox>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Checkbox
                                                                value={
                                                                    OPCIONES.PAGINA_DETALLE_NOTIFICACION
                                                                        .BOTON_HABILITAR_DOCUMENTO
                                                                }
                                                            >
                                                                Habilitar Documento (Botón)
                                                            </Checkbox>
                                                        </Col>
                                                    </Row>
                                                </Checkbox.Group>
                                            </div>
                                            <Row>
                                                <Col span={24}>
                                                    <Checkbox
                                                        value={
                                                            OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES
                                                                .BOTON_GENERAR_REPORTE_EXCEL
                                                        }
                                                    >
                                                        Generar Reporte Excel (Botón)
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Checkbox
                                                        value={
                                                            OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES
                                                                .BOTON_GENERAR_REPORTE_PDF
                                                        }
                                                    >
                                                        Generar Reporte PDF (Botón)
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Checkbox
                                                        value={
                                                            OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES
                                                                .BOTON_ASIGNAR_CARPETA
                                                        }
                                                    >
                                                        Asignar Carpeta (Botón)
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Checkbox
                                                        value={
                                                            OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES.BOTON_ELIMINAR
                                                        }
                                                    >
                                                        Eliminar Notificación (Botón)
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="consulta.documento" onChange={onChangeSeleccionarSubMenu}>
                                                Documentos
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div style={{ marginLeft: "20px" }}>
                                            <Checkbox.Group
                                                value={
                                                    accionesSeleccionadas?.find(
                                                        (accion) => accion.opcionPadre === "consulta.documento"
                                                    )?.datos
                                                }
                                                onChange={(opciones) =>
                                                    onChangeAccionesSeleccionadas(opciones, "consulta.documento")
                                                }
                                            >
                                                <Row>
                                                    <Col span={24}>
                                                        <Checkbox
                                                            value={
                                                                OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS
                                                                    .BOTON_VER_DOCUMENTO
                                                            }
                                                        >
                                                            Botón Ver Documento
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Checkbox
                                                            value={
                                                                OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS
                                                                    .BOTON_REENVIAR_DOCUMENTO
                                                            }
                                                        >
                                                            Botón Reenviar Documento
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Checkbox
                                                            value={
                                                                OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS
                                                                    .BOTON_ACTUALIZAR_CORREO
                                                            }
                                                        >
                                                            Botón Actualizar Correo
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Checkbox
                                                            value={
                                                                OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS
                                                                    .BOTON_HISTORIAL_NOTIFICACION
                                                            }
                                                        >
                                                            Botón Ver Historial Notificación
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Checkbox
                                                            value={
                                                                OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS
                                                                    .BOTON_INHABILITAR_DOCUMENTO
                                                            }
                                                        >
                                                            Botón Inhabilitar Documento
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Checkbox
                                                            value={
                                                                OPCIONES.PAGINA_CONSULTAR_POR_DOCUMENTOS
                                                                    .BOTON_HABILITAR_DOCUMENTO
                                                            }
                                                        >
                                                            Botón Habilitar Documento
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                            </Checkbox.Group>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="legajo.v2.consultas" onChange={onChangeSeleccionarSubMenu}>
                                                Legajo del Colaborador
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div style={{ marginLeft: "20px" }}>
                                            <Checkbox.Group
                                                value={
                                                    accionesSeleccionadas?.find(
                                                        (accion) => accion.opcionPadre === "legajo.v2.consultas"
                                                    )?.datos
                                                }
                                                onChange={(opciones) =>
                                                    onChangeAccionesSeleccionadas(opciones, "legajo.v2.consultas")
                                                }
                                            >
                                                <Row>
                                                    <Col span={24}>
                                                        <Checkbox
                                                            value={
                                                                OPCIONES.PAGINA_CONSULTAR_POR_CARPETAS
                                                                    .BOTON_VER_DOCUMENTO
                                                            }
                                                        >
                                                            Botón Ver Documento
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                            </Checkbox.Group>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="legajos.observacionesColaborador">
                                                Observaciones del Colaborador
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="legajos.consultaOnboarding">
                                                Bandeja de aprobacion
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="legajos.seguimientoOnboarding">
                                                Seguimiento Onboarding
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="legajos.reportes">Reportes</Checkbox>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                    <Col span={24}>
                      <Checkbox value="ausencias.solicitudes">Seguimiento Solicitudes</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Checkbox value="vacaciones.control-vacacional">Control de vacaciones</Checkbox>
                    </Col>
                  </Row> */}
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="legajos.seguimientoEvaluaciones">
                                                Seguimiento Evaluaciones
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="consulta.seguimientoContrato">
                                                Seguimiento Contratos
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Checkbox
                                indeterminate={indeterminateConfiguracion}
                                onChange={onCheckTodosConfiguracion}
                                checked={checkAllConfiguracion}
                                style={{ color: "#1678c1" }}
                            >
                                CONFIGURACION
                            </Checkbox>
                        </Row>
                        <Row>
                            <Col span={1}></Col>
                            <Col span={23}>
                                <Checkbox.Group
                                    value={opcionesSeleccionadasConfiguracion}
                                    onChange={onChangeOpcionConfiguracion}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="configuracion.formularios">Crear Formularios</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="configuracion.misCertificados">Mis certificados</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="configuracion.MiFirmaHolografa">
                                                Mis firmas hológrafas
                                            </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="configuracion.certificadosDigitales">
                                                Certificados Digitales
                                            </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="configuracion.tipoDocumentos">
                                                Crear Tipo Documento
                                            </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="configuracion.carpetasLegajos">
                                                Crear Carpetas Legajos
                                            </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="configuracion.verficarDatosColaborador">
                                                Verificar Datos Colaborador
                                            </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="configuracion.plantillaEnvioDocumentos">
                                                Plantilla Envio Documentos
                                            </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="configuracion.onBoardingIndividual">
                                                OnBoarding Individual
                                            </Checkbox>
                                        </Col>
                                        {/* <Col span={24}>
                      <Checkbox value="configuracion.tipoAbsentismo">Tipo Absentismo</Checkbox>
                    </Col> */}
                                    </Row>
                                </Checkbox.Group>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Checkbox
                                indeterminate={indeterminateSolicitudPersonal}
                                onChange={onCheckTodosSolicitudPersonal}
                                checked={checkAllSolicitudPersonal}
                                style={{ color: "#1678c1" }}
                            >
                                RECLUTAMIENTO Y SELECCIÓN
                            </Checkbox>
                        </Row>
                        <Row>
                            <Col span={1}></Col>
                            <Col span={23}>
                                <Checkbox.Group
                                    value={opcionesSeleccionadasSolicitudPersonal}
                                    onChange={onChangeOpcionSolicitudPersonal}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="reclutamiento-y-seleccion.solicitud-personal.perfiles">
                                                Crear Perfil
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="reclutamiento-y-seleccion.solicitud-personal.solicitudes">
                                                Seguimiento Solicitudes
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="reclutamiento-y-seleccion.solicitud-personal.entrevistas">
                                                Seguimiento Entrevistas
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="reclutamiento-y-seleccion.solicitud-personal.reportes">
                                                Reportes
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                    <Col span={24}>
                      <Checkbox value="reclutamiento-y-seleccion.postulantes">Crear Postulantes</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Checkbox value="reclutamiento-y-seleccion.plantillas">Crear Plantilla</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Checkbox value="reclutamiento-y-seleccion.convocatoria">Crear Convocatoria</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Checkbox value="reclutamiento-y-seleccion.procesos">Crear Procesos</Checkbox>
                    </Col>
                  </Row> */}
                                </Checkbox.Group>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Checkbox
                                indeterminate={indeterminateVacaciones}
                                onChange={onCheckTodosVacaciones}
                                checked={checkAllVacaciones}
                                style={{ color: "#1678c1" }}
                            >
                                VACACIONES
                            </Checkbox>
                        </Row>
                        <Row>
                            <Col span={1}></Col>
                            <Col span={23}>
                                <Checkbox.Group
                                    value={opcionesSeleccionadasVacaciones}
                                    onChange={onChangeOpcionVacaciones}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="configuracion.tipoAbsentismo">Tipo Absentismo</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="ausencias.solicitudes">Seguimiento Solicitudes</Checkbox>
                                        </Col>                                        
                                        <Col span={1}>
                                        </Col>
                                        <Col span={23}>
                                            <Checkbox
                                                value={
                                                    OPCIONES.PAGINA_VACACIONES_SEGUIMIENTO_SOLICITUDES.BOTON_CREAR_SOLICITUD
                                                }
                                            >
                                                Botón Crear Solicitud
                                            </Checkbox>
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={23}>
                                            <Checkbox
                                                value={
                                                    OPCIONES.PAGINA_VACACIONES_SEGUIMIENTO_SOLICITUDES.BOTON_EXPORTAR_SOLICITUDES
                                                }
                                            >
                                                Botón Exportar Solicitudes
                                            </Checkbox>
                                        </Col>
                                    </Row>                                    
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value="vacaciones.control-vacacional">
                                                Control de vacaciones
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
                <br />
                <Row justify="center">
                    <Space>
                        <Col>
                            <Form.Item style={{ textAlign: "center" }}>
                                <Button type="default" onClick={handleOnClickCancelar}>
                                    Cancelar
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item style={{ textAlign: "center" }}>
                                <Button type="primary" icon={<CheckOutlined />} loading={loading} htmlType="submit">
                                    Actualizar Rol
                                </Button>
                            </Form.Item>
                        </Col>
                    </Space>
                </Row>
            </Form>
        </ContainerCrear>
    );
}
